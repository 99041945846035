import React from 'react';
import { Modal, ModalFooter, ModalHeader, ScrollingModalContent } from '../../general/Modal';
import { Button, InvertedButton } from '../../general/Button';
import { DatepickerField, MultiFieldLine, TextField } from '../../FormFields';
import { validateExpunctionCaseFields } from '../../../validators';
import { Formik } from 'formik';
import { useCurrentApplication } from '../../../hooks/useCurrentApplication';
import { getFieldsChanged } from '../../../utils/general';
import {
  expunctionCaseFieldsModalInfoSelector,
  expunctionCaseFieldsModalSelector
} from '../../../selectors/modals';
import { setExpunctionCaseFieldsModal } from '../../../actions/modals';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateCaseInfo } from '../../../hooks/simpleRequests';
import { CASE_FIELD_PROPERTIES } from '../../../constants/cases';
import { ValidateOnMount } from '../../general/ValidateOnMount';
import { friendlyNamesSelector } from '../../../selectors/entities';
import { ErrorsBanner } from './ErrorsBanner';
import { SharedFieldsNote } from './SharedFieldsNote';
import { AgencyFields, removeOtherInputs, useAgencyInitialValues } from '../AgencyFields';

// Returns initial values object from given case object
export const getExpunctionCaseInitialValues = (caseObj = {}, agencyInitialValues) => ({
  arrest_date: caseObj.arrest_date || '',
  offense_date: caseObj.offense_date || '',
  current_offense_name: caseObj.current_offense_name || '',
  crt: caseObj.crt || '',
  off_rpt_num: caseObj.off_rpt_num || '',
  casID: caseObj.casID || '',
  associatedBondCauseNumber: caseObj.associatedBondCauseNumber || '',
  dismissal_date: caseObj.dismissal_date || '',
  def_stnum: caseObj.def_stnum || '',
  def_stnam: caseObj.def_stnam || '',
  def_cty: caseObj.def_cty || '',
  def_st: caseObj.def_st || '',
  def_zip: caseObj.def_zip || '',
  ...agencyInitialValues
});

// Form for expunction petition fields (subsset of full case fields), to be displayed in DocusignFieldsModal
export const ExpunctionCaseFieldsModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(expunctionCaseFieldsModalSelector);
  const { caseId } = useSelector(expunctionCaseFieldsModalInfoSelector);
  const application = useCurrentApplication();
  const caseObj = (application.hcdcCases || []).find(caseObj => caseObj._id === caseId) || {};
  const friendlyNamesObj = useSelector(friendlyNamesSelector);
  const friendlyNames = friendlyNamesObj.editableCaseFields;
  const agencyInitialValues = useAgencyInitialValues(caseObj);

  const onClose = () => {
    dispatch(setExpunctionCaseFieldsModal(false, null));
  };

  const [updateCase, updatingCase] = useUpdateCaseInfo(onClose);

  if (!showModal) {
    return null;
  }

  const initialValues = getExpunctionCaseInitialValues(caseObj, agencyInitialValues);

  const submit = values => {
    // Get previous and new values to compare (removing 'other' inputs for agency names)
    let newValues = removeOtherInputs(values);
    let prevValues = removeOtherInputs(initialValues);

    // override "arresting_agency" with "other" input if needed
    if (values.arresting_agency === 'other') {
      newValues.arresting_agency = values.arrestingAgencyOtherInput;
    }
    // override "forensicAgencyName" with "other" input if needed
    if (values.forensicAgencyName === 'other') {
      newValues.forensicAgencyName = values.forensicAgencyOtherInput;
    }
    // get fields changed with standardized dates
    const fieldsChanged = getFieldsChanged(newValues, prevValues, CASE_FIELD_PROPERTIES);
    // Only send a query if at least one field has changed
    if (Object.keys(fieldsChanged).length > 0) {
      updateCase({ fieldsChanged, appId: application._id, caseId: caseObj._id });
    } else {
      onClose();
    }
  };

  return (
    <Modal width={650} onClose={onClose}>
      <Formik
        validate={validateExpunctionCaseFields(initialValues)}
        initialValues={initialValues}
        onSubmit={submit}
      >
        {({ handleSubmit, errors }) => {
          const getError = name => errors[name];
          const numErrors = Object.keys(errors).length;

          return (
            <>
              <ModalHeader>Edit case fields</ModalHeader>
              <ErrorsBanner numErrors={numErrors} />
              <ScrollingModalContent height={350}>
                <SharedFieldsNote />
                <MultiFieldLine style={{ margin: '15px 0px 20px 0px' }}>
                  <DatepickerField
                    label={`${friendlyNames.arrest_date}*`}
                    name="arrest_date"
                    error={getError('arrest_date')}
                  />
                  <DatepickerField
                    label={`${friendlyNames.offense_date}*`}
                    name="offense_date"
                    error={getError('offense_date')}
                  />
                  <TextField
                    label={`${friendlyNames.current_offense_name}*`}
                    name="current_offense_name"
                    error={getError('current_offense_name')}
                  />
                  <TextField label={`${friendlyNames.crt}*`} name="crt" error={getError('crt')} />
                  <TextField
                    label={`${friendlyNames.off_rpt_num}*`}
                    name="off_rpt_num"
                    error={getError('off_rpt_num')}
                  />
                  <TextField
                    label={`${friendlyNames.casID}*`}
                    name="casID"
                    error={getError('casID')}
                  />
                  <TextField
                    label={`${friendlyNames.associatedBondCauseNumber}*`}
                    name="associatedBondCauseNumber"
                    error={getError('associatedBondCauseNumber')}
                    note="If no associated bond forfeiture case exists, enter 'N/A'"
                  />
                  <DatepickerField
                    label={`${friendlyNames.dismissal_date}*`}
                    name="dismissal_date"
                    error={getError('dismissal_date')}
                  />
                  <TextField
                    label={`${friendlyNames.def_stnum}*`}
                    name="def_stnum"
                    error={getError('def_stnum')}
                  />
                  <TextField
                    label={`${friendlyNames.def_stnam}*`}
                    name="def_stnam"
                    error={getError('def_stnam')}
                  />
                  <TextField
                    label={`${friendlyNames.def_cty}*`}
                    name="def_cty"
                    error={getError('def_cty')}
                  />
                  <TextField
                    label={`${friendlyNames.def_st}*`}
                    name="def_st"
                    error={getError('def_st')}
                  />
                  <TextField
                    label={`${friendlyNames.def_zip}*`}
                    name="def_zip"
                    error={getError('def_zip')}
                  />
                  <AgencyFields immediatelyShowErrors={true} showRequired={true} />
                </MultiFieldLine>
              </ScrollingModalContent>
              <ValidateOnMount />
              <ModalFooter>
                <InvertedButton style={{ minWidth: '100px' }} onClick={onClose}>
                  Back
                </InvertedButton>
                <Button
                  style={{ minWidth: '100px' }}
                  disabled={numErrors > 0}
                  onClick={handleSubmit}
                  isLoading={updatingCase}
                >
                  Save case changes
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
