import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTaskModal } from '../../actions/modals';
import { taskModalInfoSelector, taskModalSelector } from '../../selectors/modals';
import { Modal, ModalContent, ModalFooter } from '../general/Modal';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import {
  useUpdateAppProgress,
  useUpdateExpunctionProgress,
  useUpdateNondisclosureProgress
} from '../../hooks/simpleRequests';
import { Button, InvertedButton } from '../general/Button';
import { UserDisplay } from '../UserDisplay';
import {
  GREY_DARKEST,
  GREY_LIGHT,
  GREY_MEDIUM_DARK,
  HIGHLIGHT,
  SHADOW_LIGHT
} from '../../constants/cssVars';
import styled from 'styled-components';
import { useTaskWithContext } from '../../hooks/useTaskWithContext';
import { friendlyNamesSelector } from '../../selectors/entities';
import { isExpunctionTaskId } from '../../constants/progressTracking';

const TaskDescription = styled.div`
  // border-top: ${props => (props.complete ? 'none' : `1px solid ${GREY_LIGHT}`)};
  // margin: 20px 0px 40px 0px;
  color: ${props => (props.complete ? GREY_MEDIUM_DARK : GREY_DARKEST)};
  p {
    margin: 15px 0px;
  }
`;

const Header = styled.div`
  > div:first-child {
    display: flex;
    align-items: center;
    > h3 {
      margin: 0px;
      margin-right: 8px;
    }
  }
  > div:last-child {
    color: ${GREY_MEDIUM_DARK};
  }
`;

const Note = styled.div`
  background-color: ${HIGHLIGHT};
  border-radius: 6px;
  padding: 10px 15px;
  box-shadow: ${SHADOW_LIGHT};
  margin-bottom: 10px;
  > h4 {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  > p {
    margin-top: 10px;
  }
`;

const NoteFor0728 = ({ isReview }) => (
  <Note>
    <h4>{isReview ? 'Review' : 'Manually generate'} 411.0728 Petition & Order</h4>
    <p>
      Since the petition and order for 411.0728 are very detailed and case-specific, this site does
      not provide automatic form generation for these forms.
    </p>
    {isReview ? (
      <p>
        Please review and update the petition uploaded to the "Documents" section in the previous
        step.
      </p>
    ) : (
      <p>
        Please create a custom form and upload it to the system under the "Documents" section when
        complete.
      </p>
    )}
  </Note>
);

// Modal to show when a task is clicked on.
// Allows user to view and mark a task as complete or incomplete.
export const TaskModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(taskModalSelector);
  const { caseId, taskId } = useSelector(taskModalInfoSelector);
  const application = useCurrentApplication();
  const { taskObj, taskContextStr } = useTaskWithContext(taskId, caseId);
  const friendlyNames = useSelector(friendlyNamesSelector);
  const caseObj = application.hcdcCases.find(caseObject => caseObject._id === caseId) || {};

  const onClose = () => {
    dispatch(setTaskModal(false, null, null));
  };

  const [updateAppProgress, updateAppProgressPending] = useUpdateAppProgress(onClose);
  const [updateExpunctionProgress, updateExpunctionProgressPending] = useUpdateExpunctionProgress(
    onClose
  );
  const [
    updateNondisclosureProgress,
    updateNondisclosureProgressPending
  ] = useUpdateNondisclosureProgress(onClose);

  if (!showModal) {
    return null;
  }

  let currentCompleteStatus = null;
  let handleComplete = null;
  let submitting = false;

  // Expunction and general app progress
  if (caseId == null) {
    // On complete, we want to update the application to flip the completed status of the task
    currentCompleteStatus = application[taskId];
    const statusChanged = {
      [taskId]: !currentCompleteStatus
    };
    let updateFunction = updateAppProgress;
    if (isExpunctionTaskId(taskObj.id)) {
      updateFunction = updateExpunctionProgress;
      // If unchecking an expunction step on a closed-expunction app, set closed to false
      if (application.expunctionClosed && currentCompleteStatus) {
        statusChanged['expunctionClosed'] = false;
      }
    }
    handleComplete = () =>
      updateFunction({
        statusChanged,
        appId: application._id
      });
    submitting = isExpunctionTaskId(taskObj.id)
      ? updateExpunctionProgressPending
      : updateAppProgressPending;
  } else {
    // If it's a nondisclosure task, update the case's task property instead
    currentCompleteStatus = caseObj[taskId];
    const statusChanged = {
      [taskId]: !currentCompleteStatus
    };
    // If unchecking an nondisclosure step on a closed case, set closed to false
    if (caseObj.nondisclosureClosed && currentCompleteStatus) {
      statusChanged['nondisclosureClosed'] = false;
    }
    handleComplete = () =>
      updateNondisclosureProgress({
        statusChanged,
        appId: application._id,
        caseId: caseId
      });
    submitting = updateNondisclosureProgressPending;
  }

  // For 411.0728 , display an extra note about manual generation
  const isReview = taskId === 'nondisclosureReviewedAndSignedOffPetition';
  const is0728Task =
    caseObj.ndo_form === '411.0728' &&
    (taskId === 'nondisclosureGeneratedAndReviewedPetition' || isReview);
  const description0728 = isReview
    ? 'The nondisclosure petition for this case has been reviewed and signed.'
    : 'The nondisclosure petition for this case has been manually generated and uploaded.';
  const description = is0728Task ? description0728 : taskObj.description;

  return (
    <Modal onClose={onClose} style={{ overflow: 'visible' }}>
      <Header>
        <div>
          <h3>{friendlyNames.progressFields[taskObj.id]}</h3>
          <UserDisplay userObj={taskObj.assignee} />
        </div>
        <div>{taskContextStr}</div>
      </Header>
      <ModalContent style={{ margin: '30px 0px 40px 0px' }}>
        {is0728Task && (
          <NoteFor0728 isReview={taskId === 'nondisclosureReviewedAndSignedOffPetition'} />
        )}
        <TaskDescription complete={currentCompleteStatus}>
          <p>
            <i>
              {currentCompleteStatus
                ? 'This task has been marked as complete.'
                : 'Mark this task complete when ...'}
            </i>
          </p>
          {description}
        </TaskDescription>
      </ModalContent>
      <ModalFooter>
        <InvertedButton onClick={onClose}>Cancel</InvertedButton>
        <Button isLoading={submitting} onClick={handleComplete}>
          Mark {currentCompleteStatus ? 'Incomplete' : 'Complete'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
