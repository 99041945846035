import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenApplicationAssignmentModal } from '../actions/modals';
import { Modal, ModalFooter, ModalHeader } from './general/Modal';
import { openApplicationAssignmentModalSelector } from '../selectors/modals';
import { currentUserSelector } from '../selectors/entities';
import { Button, InvertedButton } from './general/Button';
import { Formik } from 'formik';
import { OptionsList, SelectField } from './FormFields';
import { useAssignToOpenApplication } from '../hooks/simpleRequests';
import { useAssigneeOptions } from '../hooks/useAssigneeOptions';
import { validatePairedAttorney } from '../validators';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

// Allow paralegal user to assign self to an application and select a paired attorney
export const OpenApplicationAssignmentModal = () => {
  const history = useHistory();
  const currentUser = useSelector(currentUserSelector);
  const dispatch = useDispatch();
  const showModal = useSelector(openApplicationAssignmentModalSelector);
  const { attorneyOptions } = useAssigneeOptions();

  const onClose = () => {
    dispatch(setOpenApplicationAssignmentModal(false));
  };

  const [assignToApplication, assignToApplicationPending] = useAssignToOpenApplication(body => {
    onClose();
    if (body.noAvailableApps) {
      toast.info('There are no open applications ready for paralegal assignees at this time');
    } else {
      // Redirect to the newly assigned application
      history.push(`/application/${body.app._id}`);
      toast.success('Assigned self to application');
    }
  });

  if (!showModal) {
    return null;
  }

  const submit = values => {
    assignToApplication({
      assigneeAttorneyId: values.pairedAttorney,
      assigneeParalegalId: currentUser._id || currentUser.id
    });
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader>Assign me to a new application</ModalHeader>
      <p>Please select the attorney to assign for final review and signoff of this application.</p>
      <Formik
        initialValues={{ pairedAttorney: currentUser.pairedAttorney }}
        enableReinitialize={true}
        onSubmit={submit}
        validate={validatePairedAttorney}
      >
        {({ handleSubmit, errors, touched, submitCount }) => {
          const getError = name => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <>
              <SelectField
                label="Reviewing attorney*"
                name="pairedAttorney"
                short={true}
                error={getError('pairedAttorney')}
              >
                <OptionsList options={attorneyOptions} />
              </SelectField>
              <ModalFooter>
                <InvertedButton onClick={onClose}>Cancel</InvertedButton>
                <Button isLoading={assignToApplicationPending} onClick={handleSubmit} type="button">
                  Submit
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
