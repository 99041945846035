import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { GREY_DARKEST, GREY_MEDIUM, GREY_MEDIUM_DARK } from '../constants/cssVars';
import { usersSelector } from '../selectors/entities';
import { Tooltip } from './general/Tooltip';

const CircleButton = styled.button`
  background-color: ${props => (props.isActive ? props.backgroundColor : GREY_MEDIUM)};
  background-image: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: none;
  border-radius: 30px;
  height: ${props => props.diameter || 34}px;
  width: ${props => props.diameter || 34}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.fontSize || '1em'};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  cursor: ${props => (props.isActive && !props.hideHover ? 'pointer' : 'auto !important')};
  color: ${GREY_DARKEST};
  opacity: ${props => (props.isActive ? 1 : 0.85)};
  &:hover {
    background-image: ${props =>
      props.isActive && !props.hideHover
        ? 'radial-gradient(rgba(255,255,255,0), rgba(255,255,255,0.2))'
        : 'none'};
  }
`;

const FullNameWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  button {
    margin-left: 8px;
  }
`;

const TooltipContent = styled.div`
  > div {
    display: block;
    color: ${GREY_MEDIUM_DARK};
  }
`;

// Displays a clickable circle with the user's initials or displays the user's full name as a clickable link
// userObj: user object
// displayAsText: optional boolean for whether to show user's full name next instead of the initials circle
// onClick: optional function to call when the circle is clicked. If not provided, link to user's profile (unless deactivated).
// extraTooltipContent: optional additional content to show in user's tooltip
// hideTooltip: optional boolean to prevent showing tooltip with name on hover
// hideHover: optional boolean to remove hover/clickable state from circle
export const UserDisplay = ({
  userObj,
  displayAsText,
  onClick,
  extraTooltipContent,
  id,
  style,
  hideTooltip,
  hideHover
}) => {
  const history = useHistory();
  const users = useSelector(usersSelector);

  if (!userObj) {
    return null;
  }

  const firstInitial = userObj.firstName[0].toUpperCase();
  const lastInitial = userObj.lastName[0].toUpperCase();

  const goToUsersProfile = () => history.push(`/users/${userObj._id}`);
  const isActive =
    userObj.active && users.some(user => user._id === userObj._id || user._id === userObj.id);

  // if user is deactivated, don't allow navigation to user's profile
  const handleClick = onClick || (isActive ? goToUsersProfile : () => null);

  const circleWithInitials = (
    <CircleButton
      onClick={handleClick}
      backgroundColor={userObj.color}
      isActive={isActive}
      hideHover={hideHover}
      id={id}
      style={style}
    >
      {firstInitial}
      {lastInitial}
    </CircleButton>
  );

  const fullName = `${userObj.firstName} ${userObj.lastName}`;
  const nameDisplay = isActive ? fullName : `${fullName} (deactivated)`;

  // Display name with a small circle next to it
  if (displayAsText) {
    return (
      <FullNameWrapper>
        {nameDisplay}
        <CircleButton
          onClick={handleClick}
          backgroundColor={userObj.color}
          isActive={isActive}
          hideHover={hideHover}
          id={id}
          diameter={24}
          fontSize="0.8em"
        >
          {firstInitial}
          {lastInitial}
        </CircleButton>
      </FullNameWrapper>
    );
  }

  // Skip tooltip wrapper if "hideTooltip" is true
  if (hideTooltip) {
    return circleWithInitials;
  }

  // Otherwise, show just the initials circle with a name & custom message tooltip
  return (
    <Tooltip
      delay={'0.2s'}
      content={
        <TooltipContent>
          {nameDisplay}
          {extraTooltipContent && <div>{extraTooltipContent}</div>}
        </TooltipContent>
      }
    >
      {circleWithInitials}
    </Tooltip>
  );
};
