import { createSelector } from 'reselect';

export const modalsSelector = state => state.modals;
export const clientModalSelector = state => state.modals.showClientModal;
export const caseModalSelector = state => state.modals.showCaseModal;
export const spnLookupCaseModalSelector = state => state.modals.showSpnLookupCaseModal;
export const newApplicationModalSelector = state => state.modals.showNewApplicationModal;
export const openApplicationAssignmentModalSelector = state =>
  state.modals.showOpenApplicationAssignmentModal;
export const addNoteModalSelector = state => state.modals.showAddNoteModal;
export const editNoteModalSelector = state => state.modals.showEditNoteModal;
export const deleteNoteModalSelector = state => state.modals.showDeleteNoteModal;

export const caseModalInfoSelector = state => state.modals.caseModalInfo || {};
export const addCaseModalSelector = state => state.modals.showAddCaseModal;
export const noteModalInfoSelector = state => state.modals.noteModalInfo.currentNote;
export const taskModalSelector = state => state.modals.showTaskModal;
export const taskModalInfoSelector = state => state.modals.taskModalInfo || {};
export const newUserModalSelector = state => state.modals.showNewUserModal;
export const editAssigneesModalSelector = state => state.modals.showEditAssigneesModal;
export const confirmationModalSelector = state => state.modals.showConfirmationModal;
export const confirmationModalInfoSelector = state => state.modals.confirmationModalInfo || {};
export const documentUploadModalSelector = state => state.modals.showDocumentUploadModal;
export const CSVUploadModalSelector = state => state.modals.showCSVUploadModal;
export const activityLogModalSelector = state => state.modals.showActivityLogModal;
export const closeModalSelector = state => state.modals.showCloseModal;
export const closeModalInfoSelector = state => state.modals.closeModalInfo || {};
export const docusignFieldsModalSelector = state => state.modals.showDocusignFieldsModal;
export const docusignFieldsModalInfoSelector = state => state.modals.docusignFieldsModalInfo || {};
export const expunctionCaseFieldsModalSelector = state =>
  state.modals.showExpunctionCaseFieldsModal;
export const expunctionCaseFieldsModalInfoSelector = state =>
  state.modals.expunctionCaseFieldsModalInfo || {};
export const deleteAppModalSelector = state => state.modals.showDeleteAppModal;
export const tableFiltersModalSelector = state => state.modals.showTableFiltersModal;
export const tableFiltersModalInfoSelector = state => state.modals.tableFiltersModalInfo || {};
export const reportsFiltersModalSelector = state => state.modals.showReportsFiltersModal;
export const reportsFiltersModalInfoSelector = state => state.modals.reportsFiltersModalInfo || {};

export const editUserModalSelector = state => state.modals.showEditUserModal;
export const editUserModalInfoSelector = state => state.modals.editUserModalInfo || {};

export const anyModalIsActiveSelector = createSelector([modalsSelector], modals =>
  Object.values(modals).some(value => value === true)
);
