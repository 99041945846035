import styled from 'styled-components';
import {
  GREY_DARK,
  GREY_MEDIUM_LIGHT,
  SHADOW_MEDIUM,
  PRIMARY,
  PRIMARY_DARK,
  GREY_LIGHT,
  GREY_LIGHTER,
  GREY_DARKEST,
  GREY_MEDIUM_DARK,
  GREY_MEDIUM
} from '../../../constants/cssVars';

// Shared styled table components

// Wrapper for the table's title & any elements to display alongside it
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  h1,
  h2,
  h3 {
    margin-bottom: 0px;
  }
`;

// Wrapper table's search bar & any elements to display alongside it
export const SearchAndInteractivesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  margin-top: 10px;
  > div:first-child {
    display: inline-flex;
    align-items: center;
  }
  h1,
  h2,
  h3 {
    margin-bottom: 0px;
  }
`;

// Wraps the table & footer in a lightly shadowed box
export const TableWrapper = styled.div`
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  border-radius: 8px;
  box-shadow: ${SHADOW_MEDIUM};
  overflow: auto;
`;

export const StyledTable = styled.table`
  background-color: #fff;
  border: none;
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
`;

export const TableHeader = styled.th`
  text-align: left;
  color: ${GREY_DARK};
  border-bottom: 1px solid ${GREY_MEDIUM_LIGHT};
  padding: 15px 5px;
  min-width: 120px;
`;

export const HeaderButton = styled.button`
  padding: 0px;
  margin: 0px;
  border: none;
  color: ${GREY_DARK};
  background-color: transparent;
  font-weight: 700;
  font-size: 1.1rem;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${GREY_LIGHT};
  > th:first-child,
  > td:first-child {
    padding-left: 20px;
  }
  > th:last-child,
  > td:last-child {
    padding-right: 20px;
  }
`;

export const TableData = styled.td`
  max-height: 100px;
  max-width: 250px;
  overflow: auto;
  font-size: 1em;
  border: none;
  padding: 15px 5px;
  color: ${GREY_DARKEST};
  a {
    font-size: 1em;
    font-weight: 500;
    color: ${PRIMARY};
    &:hover {
      color: ${PRIMARY_DARK};
    }
  }
`;

export const TableFooter = styled.div`
  background-color: ${GREY_LIGHTER};
  color: ${GREY_DARK};
  border-radius: 0px 8px 8px 8px;
  padding: 15px 20px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PaginationButton = styled.button`
  border: 1px solid transparent;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  min-width: 28px;
  margin: 0 3px;
  :hover {
    background: ${GREY_MEDIUM_LIGHT};
  }
  &.active {
    border: 1px solid ${PRIMARY};
    font-weight: 700;
    color: ${PRIMARY};
    :hover {
      background: inherit;
    }
  }
  &:disabled {
    color: ${GREY_MEDIUM};
    cursor: not-allowed;
    :hover {
      background: inherit;
    }
  }
`;

export const PageSpacer = styled.div`
  min-width: 22px;
  display: inline-block;
  user-select: none;
`;

// Chevron image displayed when a column is actively being used to sort
export const SortingChevron = styled.img`
  width: 12px;
  position: relative;
  top: -2px;
`;

export const NotFoundMessage = styled.div`
  padding: 20px;
  display: flex;
  min-height: 250px;
  justify-content: center;
  align-items: center;
  font-style: italic;
`;
