import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, LinkLikeButton } from '../general/Button';
import { BG_LIGHTER, SHADOW_MEDIUM, PRIMARY, GREY_LIGHT } from '../../constants/cssVars.js';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { TextField } from '../FormFields';
import { useLoginQuery } from '../../hooks/useLogin';
import { validateForgotPassword, validateLogin } from '../../validators';
import { EnterKeyPressListener } from '../general/EnterKeyPressListener';
import { useForgotPasswordQuery } from '../../hooks/simpleRequests';
import { Title } from '../Title';

const Wrapper = styled.div`
  width: 100%;
`;

export const MainContent = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${GREY_LIGHT};
`;

export const Tile = styled.div`
  padding: 30px 20px;
  background-color: ${BG_LIGHTER};
  box-shadow: ${SHADOW_MEDIUM};
  border: 1px solid ${PRIMARY};
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: 350px;
`;

export const IntroText = styled.h1`
  color: ${PRIMARY};
  font-weight: 600;
  padding-bottom: 20px;
  margin: 0px;
  font-size: 30px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
`;

const LoginContent = ({ setShowForgotPassword }) => {
  const history = useHistory();

  const [submit, submitting] = useLoginQuery(() => {
    history.push('/');
  });

  return (
    <Formik
      validate={validateLogin}
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={submit}
    >
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        const getError = name => (touched[name] || submitCount >= 1) && errors[name];
        return (
          <>
            <IntroText>Login</IntroText>
            <TextField
              label="Email"
              error={getError('email')}
              style={{ margin: '5px 0px ' }}
              type="email"
              name="email"
            />
            <TextField
              label="Password"
              error={getError('password')}
              style={{ margin: '5px 0px ' }}
              type="password"
              name="password"
            />
            <ButtonWrapper>
              <Button onClick={handleSubmit} isLoading={submitting}>
                Log in
              </Button>
            </ButtonWrapper>
            <EnterKeyPressListener onEnter={() => handleSubmit(values)} />
            <LinkLikeButton
              style={{ fontSize: '16px' }}
              onClick={() => {
                setShowForgotPassword(true);
              }}
            >
              Forgot password
            </LinkLikeButton>
          </>
        );
      }}
    </Formik>
  );
};

const ForgotPasswordContent = ({ setShowForgotPassword }) => {
  const [resetSubmit, resetSubmitting] = useForgotPasswordQuery();
  return (
    <Formik validate={validateForgotPassword} initialValues={{ email: '' }} onSubmit={resetSubmit}>
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        const getError = name => (touched[name] || submitCount >= 1) && errors[name];
        return (
          <>
            <IntroText>Forgot Password</IntroText>
            <p style={{ margin: '0px 0px 10px 0px', padding: '0px', fontSize: '17px' }}>
              Please enter your account email below. We'll send you an email with instructions on
              how to reset your password.
            </p>
            <TextField
              label="Email"
              error={getError('email')}
              style={{ margin: '5px 0px ' }}
              type="email"
              name="email"
            />
            <ButtonWrapper>
              <Button onClick={handleSubmit} isLoading={resetSubmitting}>
                Send Reset Instructions
              </Button>
            </ButtonWrapper>
            <LinkLikeButton
              style={{ fontSize: '16px' }}
              onClick={() => setShowForgotPassword(false)}
            >
              Back to login
            </LinkLikeButton>
          </>
        );
      }}
    </Formik>
  );
};

const CornerTitle = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
`;

export const Login = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  return (
    <Wrapper>
      <CornerTitle>
        <Title />
      </CornerTitle>
      <MainContent>
        <Tile>
          {showForgotPassword ? (
            <ForgotPasswordContent setShowForgotPassword={setShowForgotPassword} />
          ) : (
            <LoginContent setShowForgotPassword={setShowForgotPassword} />
          )}
        </Tile>
      </MainContent>
    </Wrapper>
  );
};
