import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalFooter, ModalHeader, ScrollingModalContent } from '../general/Modal';
import { MultiFieldLine } from '../FormFields';
import { InvertedButton, Button } from '../general/Button';
import {
  reportsFiltersModalSelector,
  reportsFiltersModalInfoSelector
} from '../../selectors/modals';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GREY_LIGHTER, GREY_MEDIUM_DARK, GREY_MEDIUM_LIGHT } from '../../constants/cssVars';
import { setReportsFiltersInfo, setReportsFiltersModal } from '../../actions/modals';

const CheckboxWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  ${({ wrapperStyle }) => wrapperStyle};
  margin-right: 15px;
  > input {
    margin-right: 8px;
  }
`;

const Wrapper = styled.div`
  h3 {
    font-size: 1.1rem;
    margin: 0px;
    border-top: 1px solid lightgrey;
    padding-top: 10px;
    margin-top: 10px;
  }
  h3:first-child {
    border-top: none;
    padding-top: 0px;
    margin-top: 0px;
  }
`;

const Label = styled.label`
  color: ${GREY_MEDIUM_DARK};
`;

const Section = styled.div`
  padding: 10px;
  border-radius: 6px;
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  background-color: ${GREY_LIGHTER};
  margin-bottom: 10px;
`;

const FilterFieldsAndButtons = ({ onClose }) => {
  const filters = useSelector(reportsFiltersModalInfoSelector);
  const dispatch = useDispatch();

  // Object to track inputted values
  const [values, setValues] = useState({
    ...filters
  });

  const updateDate = (filterName, newDate) => {
    setValues({ ...values, [filterName]: new Date(newDate).getTime() });
  };

  const customStartDate = values.customStart ? new Date(values.customStart) : null;
  const customEndDate = values.customEnd ? new Date(values.customEnd) : null;

  const handleAllDatesCheckbox = () => {
    const newValues = {
      ...values,
      allDates: !values.allDates
    };
    // clear custom start & end if toggling into all dates mode
    if (!values.allDates) {
      newValues['customStart'] = undefined;
      newValues['customEnd'] = undefined;
    }
    setValues(newValues);
  };

  const handleSave = () => {
    dispatch(setReportsFiltersInfo(values));
    onClose();
  };

  return (
    <>
      <ScrollingModalContent style={{ padding: '0px', backgroundColor: '#fff' }}>
        <Section>
          <CheckboxWrapper>
            <input type="checkbox" onChange={handleAllDatesCheckbox} checked={values.allDates} />
            Pull applications created on any date
          </CheckboxWrapper>
          {
            <div style={{ marginTop: '10px' }}>
              <MultiFieldLine>
                <Label>
                  Created start date
                  <DatePicker
                    disabled={values.allDates}
                    popperProps={{ strategy: 'fixed' }}
                    popperPlacement={'top-start'}
                    selected={customStartDate}
                    onChange={newValue => updateDate('customStart', newValue)}
                  />
                </Label>
                <Label>
                  Created end date
                  <DatePicker
                    disabled={values.allDates}
                    popperProps={{ strategy: 'fixed' }}
                    popperPlacement={'top-start'}
                    selected={customEndDate}
                    onChange={newValue => updateDate('customEnd', newValue)}
                  />
                </Label>
              </MultiFieldLine>
            </div>
          }
        </Section>
      </ScrollingModalContent>

      <ModalFooter>
        <InvertedButton onClick={onClose} style={{ minWidth: '120px' }}>
          Close
        </InvertedButton>
        <Button
          disabled={(values.customEnd == null || values.customStart == null) && !values.allDates}
          onClick={handleSave}
          style={{ minWidth: '120px' }}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export const ReportsFiltersModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(reportsFiltersModalSelector);

  const onClose = () => {
    dispatch(setReportsFiltersModal(false));
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader>Set reports filtering dates</ModalHeader>
      <Wrapper>
        <FilterFieldsAndButtons onClose={onClose} />
      </Wrapper>
    </Modal>
  );
};
