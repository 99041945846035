import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector, usersSelector } from '../../selectors/entities';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { UserDisplay } from '../UserDisplay';
import { getUserTypeString } from '../../utils/general';
import { SimpleListTable } from '../general/SimpleListTable';
import { InvertedSmallButton, LinkLikeButton, SmallButton } from '../general/Button';
import { useDeactivateUser, useSetPairedAttorney } from '../../hooks/simpleRequests';
import { setConfirmationModal } from '../../actions/modals';
import { HeaderWithEdit } from '../general/HeaderWithEdit';
import { Formik } from 'formik';
import { useAssigneeOptions } from '../../hooks/useAssigneeOptions';
import { OptionsList, SelectField } from '../FormFields';
import { validatePairedAttorney } from '../../validators';
import pencilIcon from '../../assets/icons/edit.svg';
import { setEditUserModal } from '../../actions/modals';
import { ERROR } from '../../constants/cssVars';
import { UserOverviewTable } from '../reports/UserOverviewTable';

const Header = styled.div`
  display: flex;
  align-items: center;
  > h2 {
    margin: 0px;
    margin-right: 10px;
  }
`;

const datesAreEqual = (dateStrA, dateStrB) => {
  const dateA = new Date(dateStrA);
  const dateB = new Date(dateStrB);
  return dateA.getTime() === dateB.getTime();
};

// Display paired attorney and allow editing (only for paralegals)
const PairedAttorneySection = ({ allowEdits }) => {
  const { userId } = useParams();
  const [editMode, setEditMode] = useState(false);
  const { attorneyOptions } = useAssigneeOptions();
  const allUsers = useSelector(usersSelector);
  const selectedUser = allUsers.find(user => user._id === userId);
  const pairedAttorneyUser = allUsers.find(userObj => userObj._id === selectedUser.pairedAttorney);
  const [setPairedAttorney] = useSetPairedAttorney(() => setEditMode(false));

  // only show this section if the assigned user is a paralegal
  if (!selectedUser.paralegal) {
    return null;
  }

  const submit = values => {
    setPairedAttorney({
      pairedAttorney: values.pairedAttorney,
      userId: userId
    });
  };

  return (
    <>
      {allowEdits ? (
        <HeaderWithEdit
          onEditClick={() => setEditMode(!editMode)}
          hideEdit={editMode}
          style={{ marginBottom: '10px', fontSize: '1.1em' }}
        >
          Paired Attorney
        </HeaderWithEdit>
      ) : (
        <h3>Paired Attorney</h3>
      )}
      {editMode ? (
        <Formik
          validate={validatePairedAttorney}
          initialValues={{ pairedAttorney: selectedUser.pairedAttorney || '' }}
          onSubmit={submit}
        >
          {({ handleSubmit, errors, touched, submitCount }) => {
            const getError = name => (touched[name] || submitCount >= 1) && errors[name];
            return (
              <>
                <p>
                  This attorney will be used as the default reviewing attorney suggestion when a
                  paralegal is assigned to an application.
                </p>
                <SelectField
                  label="Paired Attorney*"
                  name="pairedAttorney"
                  short={true}
                  error={getError('pairedAttorney')}
                >
                  <OptionsList options={attorneyOptions} />
                </SelectField>
                <InvertedSmallButton type="button" onClick={() => setEditMode(false)}>
                  Cancel
                </InvertedSmallButton>
                <SmallButton type="button" onClick={handleSubmit}>
                  Save
                </SmallButton>
              </>
            );
          }}
        </Formik>
      ) : (
        <>
          {pairedAttorneyUser ? (
            <UserDisplay displayAsText={true} userObj={pairedAttorneyUser} />
          ) : (
            <p style={{ marginTop: '0px' }}>No paired attorney set</p>
          )}
        </>
      )}
    </>
  );
};

// Displays basic information about a user
export const UserProfilePage = () => {
  const { userId } = useParams();
  const currentUser = useSelector(currentUserSelector);
  const history = useHistory();
  const users = useSelector(usersSelector);
  const selectedUser = users.find(user => user._id === userId);
  const dispatch = useDispatch();
  const [deactivateUser] = useDeactivateUser(() => history.push('/users'));

  if (!selectedUser) {
    return <h2>404: Could not find a matching user</h2>;
  }

  const { firstName, lastName, email, dateCreated, dateLastLogin, active } = selectedUser;

  // Allow profile editing if the selected user is the current user, or if the current user is an admin
  const currentUserIsSelectedUser = userId === currentUser._id;
  const allowEdits = currentUserIsSelectedUser || currentUser.admin;

  // if the created date is EXACTLY the same as the login date, they haven't actually logged in
  const notLoggedInYet = datesAreEqual(dateCreated, dateLastLogin);

  return (
    <>
      <Header>
        <h2>
          {firstName} {lastName}
        </h2>
        <UserDisplay userObj={selectedUser} hideTooltip={true} hideHover={true} />
        {allowEdits && (
          <LinkLikeButton
            onClick={() => dispatch(setEditUserModal(true, selectedUser))}
            style={{ marginLeft: '15px' }}
          >
            Edit <img src={pencilIcon} alt="pencil icon" />
          </LinkLikeButton>
        )}
      </Header>
      <p style={{ marginTop: '0px', marginBottom: '10px' }}>{email}</p>

      {!active && (
        <p>
          <b>This user account has been deactivated.</b>
        </p>
      )}

      <SimpleListTable
        headers={['Role:', 'Invited on:', 'Last login:']}
        values={[
          getUserTypeString(selectedUser),
          moment(dateCreated).format('MMMM Do, yyyy'),
          notLoggedInYet ? 'N/A' : moment(dateLastLogin).format('MMMM Do, yyyy')
        ]}
      />

      {currentUser.admin && (
        <div style={{ margin: '20px 0px' }}>
          <SmallButton
            onClick={() =>
              dispatch(
                setConfirmationModal(
                  true,
                  'Deactivate user',
                  'Deactivate this user? A deactivated will no longer be able to log into this system and will be removed from your user list.',
                  () => deactivateUser({ userId: selectedUser._id })
                )
              )
            }
            style={{ backgroundColor: ERROR }}
          >
            Deactivate User
          </SmallButton>
        </div>
      )}

      <PairedAttorneySection allowEdits={allowEdits} />
      <UserOverviewTable userId={userId} />
    </>
  );
};
