import React from 'react';
import styled from 'styled-components';
import { ButtonLoader } from '../Loaders';
import {
  PRIMARY,
  BG_PRIMARY,
  PRIMARY_DARK,
  MAX_DESKTOP_WIDTH,
  BG_LIGHTER,
  SHADOW_LIGHT,
  GREY_MEDIUM_DARK,
  GREY_MEDIUM
} from '../../constants/cssVars';

export const ButtonContent = styled.button`
  background-color: ${({ secondary, color }) => (secondary ? 'none' : color || PRIMARY)};
  color: ${({ fontColor, secondary, isLoading, color }) =>
    fontColor ? fontColor : secondary || isLoading ? color || PRIMARY : '#fff'};
  font-weight: 500;
  font-size: ${({ prominent }) => (prominent ? '1.2' : '1.1')}em;
  box-shadow: ${({ secondary }) => (secondary ? 'none' : '0 2px 8px rgba(0,0,0,.11)')};
  padding: 10px 25px;
  min-width: ${({ wide }) => (wide ? '100%' : '250px')};
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: ${({ secondary, color }) => (secondary ? `1px solid ${color || PRIMARY}` : 'none')};
  opacity: ${({ disabled, isLoading }) => (disabled || isLoading ? 0.5 : 1)};
  transition: all 0.2s ease;
  &:focus {
    box-shadow: 0 0 2px 4px rgb(93, 196, 255);
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    ${({ fillOnMobile }) => (fillOnMobile ? 'width: min(100%, 400px);' : '')};
  }
  > img {
    padding-left: 0.15em;
    width: 1em;
    height: 1em;
  }
`;

const SmallButtonContent = styled(ButtonContent)`
  padding: 5px 20px;
  min-width: 0px;
  background-color: ${({ color }) => color || PRIMARY};
  &:hover {
    background-color: ${({ color }) => color || PRIMARY_DARK};
  }
`;

export const InvertedButtonContent = styled.button`
  color: ${({ active }) => (active ? BG_PRIMARY : PRIMARY)};
  font-size: 1.2em;
  font-weight: 600;
  padding: 6px 12px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
  background-color: ${({ active }) => (active ? PRIMARY_DARK : BG_LIGHTER)};
  border: 2px solid ${PRIMARY};
  transition: all 0.2s ease;
  box-shadow: ${SHADOW_LIGHT};
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    ${({ fillOnMobile }) => (fillOnMobile ? 'width: min(100%, 400px);' : '')};
  }
  &:focus {
    box-shadow: 0 0 2px 4px rgb(93, 196, 255);
  }
`;

export const InvertedSmallButton = styled(InvertedButtonContent)`
  padding: 3px 20px;
  font-size: 1.1em;
  min-width: 0px;
  box-sizing: border-box;
`;

export const ButtonWrapper = styled.div`
  position: relative;
  display: ${({ wide }) => (wide ? 'block' : 'inline-block')};
  cursor: ${({ disabled, isLoading }) => (disabled || isLoading ? 'default' : 'pointer')};
`;

export const InvertedButton = ({ isLoading, disabled, onClick, wrapperStyle, ...props }) => {
  const handleClick = () => {
    onClick();
  };
  return (
    <ButtonWrapper
      style={wrapperStyle}
      onClick={isLoading || disabled ? null : handleClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {isLoading && <ButtonLoader />}
      <InvertedButtonContent {...props} disabled={disabled} isLoading={isLoading} />
    </ButtonWrapper>
  );
};

export const Button = ({
  isLoading,
  disabled,
  onClick,
  wide,
  wrapperStyle,
  prominent,
  ...props
}) => {
  const handleClick = () => {
    if (!isLoading && !disabled && onClick) {
      onClick();
    }
  };
  return (
    <ButtonWrapper
      style={wrapperStyle}
      wide={wide}
      onClick={handleClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {isLoading && <ButtonLoader />}
      <ButtonContent
        {...props}
        wide={wide}
        disabled={disabled}
        prominent={prominent}
        isLoading={isLoading}
      />
    </ButtonWrapper>
  );
};

export const SmallButton = ({
  isLoading,
  disabled,
  onClick,
  wide,
  wrapperStyle,
  prominent,
  ...props
}) => {
  const handleClick = () => {
    if (!isLoading && !disabled && onClick) {
      onClick();
    }
  };
  return (
    <ButtonWrapper
      style={wrapperStyle}
      wide={wide}
      onClick={handleClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {isLoading && <ButtonLoader />}
      <SmallButtonContent
        {...props}
        wide={wide}
        disabled={disabled}
        prominent={prominent}
        isLoading={isLoading}
      />
    </ButtonWrapper>
  );
};

const ButtonWithLinkStyle = styled.button`
  background-color: transparent;
  color: ${({ disabled }) => (disabled ? GREY_MEDIUM : PRIMARY)};
  text-decoration: underline;
  border: none;
  font-weight: 600;
  padding: 0px;
  margin: 0px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  > img {
    padding-left: 0.15em;
    width: 1em;
    height: 1em;
  }
`;

const LoadingText = styled.p`
  color: ${GREY_MEDIUM_DARK};
  font-weight: 600;
  font-style: italic;
  margin: 0px;
  margin-top: 3px;
`;

export const LinkLikeButton = ({ onClick, style, children, isLoading, loadingText, disabled }) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  if (isLoading) {
    return <LoadingText>{loadingText || 'Loading ...'}</LoadingText>;
  }
  return (
    <ButtonWithLinkStyle
      type="button"
      style={style}
      onClick={handleClick}
      disabled={disabled ? 1 : 0}
    >
      {children}
    </ButtonWithLinkStyle>
  );
};
