import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAsyncDebounce } from 'react-table';
import searchIcon from '../../../assets/icons/search.svg';

export const SearchWrapper = styled.div`
  position: relative;
  width: 250px;
  margin-right: 20px;
  > img {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 20px;
    height: 20px;
  }
  > input {
    padding-left: 35px !important;
  }
`;

// Reference https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/filtering?file=/src/App.js
export const GlobalFilter = ({
  searchPlaceholder,
  globalFilter,
  setGlobalFilter,
  onSearch,
  searchResetCount
}) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
    onSearch && onSearch(value);
  }, 100);

  // Reset search when searchResetCount increments (in other words, don't reset on the initial render, but any time searchResetCount changes)
  useEffect(() => {
    if (searchResetCount > 0) {
      setValue('');
      onChange('');
    }
  }, [searchResetCount]);

  return (
    <SearchWrapper>
      <img src={searchIcon} alt="Search magnifying glass" />
      <input
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={searchPlaceholder || `Search ...`}
      />
    </SearchWrapper>
  );
};
