import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { setEditAssigneesModal, setTaskModal } from '../../actions/modals';
import {
  GREY_LIGHT,
  GREY_MEDIUM_DARK,
  HIGHLIGHT,
  MAIN_CONTENT_WIDTH,
  PRIMARY
} from '../../constants/cssVars';
import { useProgressListObjs } from '../../hooks/useProgressListObjs';
import { friendlyNamesSelector } from '../../selectors/entities';
import { LinkLikeButton } from '../general/Button';
import { ChevronButton } from '../general/ChevronButton';
import { UserDisplay } from '../UserDisplay';

const StickyFooter = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 15px 0px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${GREY_LIGHT};
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.1);
  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: ${props => (props.expanded ? 'auto' : '58px')};
    overflow: hidden;
    width: ${MAIN_CONTENT_WIDTH}px;
    > button {
      min-width: 150px;
      display: flex;
      justify-content: flex-end;
    }
    @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
      width: 100%;
    }
  }
`;

// A little lift to make sure the user can still see the whole page when the footer is present
const StickyFooterOffset = styled.div`
  width: 100%;
  height: 100px;
`;

const TinyHeader = styled.h3`
  font-size: 0.9em;
  margin: 0px;
  color: ${GREY_MEDIUM_DARK};
  text-transform: uppercase;
`;

const StepRowsWrapper = styled.div`
  display: block;
  max-width: ${MAIN_CONTENT_WIDTH - 180}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    max-width: 80%;
  }
  overflow: hidden;
  position: relative;
  >div: first-child {
    width: 2000px;
  }
`;

const FadeToWhite = styled.div`
  position: absolute;
  height: 100%;
  width: 50px;
  right: 0px;
  top: 0px;
  z-index: 1;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
`;

const StepRow = styled.div`
  display: ${props => (props.expanded ? 'flex' : 'inline-flex')};
  align-items: center;
  margin-bottom: 5px;
  > button {
    margin-left: 10px;
  }
`;

const StepHeader = styled.div`
  font-size: 1.05em;
  font-weight: 700;
  margin: 0px;
  display: inline-flex;
  align-items: center;
  opacity: 1;
  &:hover {
    opacity: 0.9;
  }
`;

const StyledHashLink = styled(HashLink)`
  margin-left: 6px;
  font-weight: 600;
  color: ${PRIMARY};
`;

const NoAssigneesNote = styled.div`
  background-color: ${HIGHLIGHT};
  border-radius: 6px;
  padding: 5px 10px;
  display: inline-flex;
  font-style: italic;
  > button {
    font-style: italic;
  }
`;

// Sticky footer that shows the current task / action items
// Can expand to show more detail
export const ProgressStickyFooter = () => {
  const dispatch = useDispatch();
  const progressListObjs = useProgressListObjs();
  const [expanded, setExpanded] = useState(false);
  const activeProgressListObjs = progressListObjs.filter(
    progressListObj => !progressListObj.listIsComplete
  );
  const friendlyNames = useSelector(friendlyNamesSelector);

  // If there are no active lists, don't show the footer
  if (activeProgressListObjs.length === 0) {
    return null;
  }

  return (
    <>
      <StickyFooter expanded={expanded}>
        <div>
          <div>
            <TinyHeader>Current task{activeProgressListObjs.length > 1 ? 's' : ''}</TinyHeader>
            <StepRowsWrapper>
              <div>
                {activeProgressListObjs.map((progressListObj, idx) => {
                  const currentTask = progressListObj.currentTask;
                  const showTaskModal = () =>
                    dispatch(setTaskModal(true, currentTask.id, currentTask.caseId));
                  let interactiveContent = null;
                  if (currentTask.assignee) {
                    // if there's an assignee, show assignee display & task completion links
                    interactiveContent = (
                      <>
                        <StepHeader>
                          <div style={{ marginRight: '8px' }}>
                            {friendlyNames.progressFields[currentTask.id]}
                          </div>
                          <UserDisplay userObj={currentTask.assignee} />
                        </StepHeader>
                        <LinkLikeButton onClick={showTaskModal}>complete task</LinkLikeButton>,
                        <StyledHashLink to={`#${progressListObj.displayName}`}>
                          view list
                        </StyledHashLink>
                      </>
                    );
                  } else {
                    // otherwise, show CTA to add an assignee
                    interactiveContent = (
                      <>
                        <StepHeader>
                          <div style={{ marginRight: '8px' }}>
                            {friendlyNames.progressFields[currentTask.id]}
                          </div>
                        </StepHeader>
                        <NoAssigneesNote>
                          <span style={{ paddingRight: '6px' }}>
                            This application is missing assignees.
                          </span>
                          <LinkLikeButton onClick={() => dispatch(setEditAssigneesModal(true))}>
                            Add assignees
                          </LinkLikeButton>
                        </NoAssigneesNote>
                      </>
                    );
                  }
                  return (
                    <StepRow
                      key={`task_${progressListObj.displayName}_${currentTask.friendlyName}`}
                      expanded={expanded}
                    >
                      {/* When not expanded, show a comma-separated list of tasks that fades out if overflowing */}
                      {idx !== 0 && !expanded && <div style={{ marginRight: '10px' }}>, </div>}
                      {interactiveContent}
                    </StepRow>
                  );
                })}
              </div>
              {/* Fade overflow when in collapsed view */}
              {!expanded && <FadeToWhite />}
            </StepRowsWrapper>
          </div>
          {/* 
          Should only show the expand button if there's more than one item 
          (would probably be best to do a length calculation, but I think this works for now) 
        */}
          {activeProgressListObjs.length > 1 && (
            <ChevronButton
              expanded={expanded}
              flipDirection={true}
              setExpanded={setExpanded}
              supportingText={expanded ? 'Collapse' : 'Expand'}
            />
          )}
        </div>
      </StickyFooter>
      <StickyFooterOffset />
    </>
  );
};
