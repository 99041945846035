import React from 'react';
import styled from 'styled-components';
import { LinkLikeButton } from '../general/Button';
import pencilIcon from '../../assets/icons/edit.svg';

const HeaderWrapper = styled.h3`
  display: flex;
  align-items: center;
  > button {
    font-size: 1rem;
    padding-left: 0.5em;
  }
`;

// Displays child header text with an edit button next to it.
export const HeaderWithEdit = ({ children, onEditClick, hideEdit, style }) => {
  return (
    <HeaderWrapper style={style}>
      {children}
      {!hideEdit && (
        <LinkLikeButton onClick={onEditClick}>
          Edit <img src={pencilIcon} alt="pencil icon" />
        </LinkLikeButton>
      )}
    </HeaderWrapper>
  );
};
