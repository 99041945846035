import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalFooter, ModalHeader, ScrollingModalContent } from './general/Modal';
import { FieldWrapper, MultiFieldLine, OptionsList } from './FormFields';
import { InvertedButton, Button, LinkLikeButton } from './general/Button';
import { setTableFiltersModal } from '../actions/modals';
import { tableFiltersModalInfoSelector, tableFiltersModalSelector } from '../selectors/modals';
import { tableFiltersSelector } from '../selectors/general';
import { bulkSetTableFilters } from '../actions/general';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GREY_DARK, GREY_LIGHTER, GREY_MEDIUM_DARK, GREY_MEDIUM_LIGHT } from '../constants/cssVars';
import { usersSelector } from '../selectors/entities';
import { convertUserObjToOption } from '../hooks/useAssigneeOptions';

const CheckboxWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  ${({ wrapperStyle }) => wrapperStyle};
  margin-right: 15px;
  > input {
    margin-right: 8px;
  }
`;

const Wrapper = styled.div`
  h3 {
    font-size: 1.1rem;
    margin: 0px;
    border-top: 1px solid lightgrey;
    padding-top: 10px;
    margin-top: 10px;
  }
  h3:first-child {
    border-top: none;
    padding-top: 0px;
    margin-top: 0px;
  }
`;

const Label = styled.label`
  color: ${GREY_MEDIUM_DARK};
`;

const Section = styled.div`
  padding: 10px;
  border-radius: 6px;
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  background-color: ${GREY_LIGHTER};
  margin-bottom: 10px;
  .note {
    margin-top: 5px;
    color: ${GREY_DARK};
    font-style: italic;
  }
`;

const NO_ASSIGNEES_VALUE = 'no_assignees';

const FilterFieldsAndButtons = ({ onClose, tableId }) => {
  // Assignee filter should populate with list of users and "No assignees" option
  const users = useSelector(usersSelector);
  const assigneeFilterOptions = [
    { label: 'No assignees', value: NO_ASSIGNEES_VALUE },
    ...users.map(convertUserObjToOption)
  ];

  const filters = useSelector(state => tableFiltersSelector(state, tableId));
  const dispatch = useDispatch();

  // Object to track inputted values
  const [values, setValues] = useState({
    ...filters
  });

  const updateValue = (filterName, value) => setValues({ ...values, [filterName]: value });
  const customStartDate = values.customStart ? new Date(values.customStart) : null;
  const customEndDate = values.customEnd ? new Date(values.customEnd) : null;

  const handleAssigneeUpdate = e => {
    if (e.target.value === NO_ASSIGNEES_VALUE) {
      setValues({ ...values, assigneeId: null, noAssignees: true });
    } else {
      setValues({ ...values, assigneeId: e.target.value, noAssignees: false });
    }
  };

  const handleAllDatesCheckbox = () => {
    const newValues = {
      ...values,
      allDates: !values.allDates
    };
    // clear custom start & end if toggling into all dates mode
    if (!values.allDates) {
      newValues['customStart'] = undefined;
      newValues['customEnd'] = undefined;
    }
    setValues(newValues);
  };

  const handleSave = () => {
    dispatch(
      bulkSetTableFilters(tableId, {
        ...values,
        pageNum: 1 // reset pagination
      })
    );
    onClose();
  };

  // Clear out any inputted data from fields
  const handleClear = () =>
    setValues({
      allDates: true,
      customStart: undefined,
      customEnd: undefined,
      includeClosed: false,
      showOnlyInactive: false,
      spn: '',
      firstName: '',
      lastName: '',
      middleName: '',
      suffix: '',
      assigneeId: '',
      noAssignees: false
    });

  return (
    <>
      <ScrollingModalContent style={{ padding: '0px', backgroundColor: '#fff' }}>
        {/* SPN search */}
        <Section>
          <FieldWrapper label="Client SPN" style={{ width: '250px' }}>
            <input value={values.spn || ''} onChange={e => updateValue('spn', e.target.value)} />
          </FieldWrapper>
        </Section>

        {/* Name search */}
        <Section>
          <MultiFieldLine>
            <FieldWrapper label="First name">
              <input
                value={values.firstName || ''}
                onChange={e => updateValue('firstName', e.target.value)}
              />
            </FieldWrapper>
            <FieldWrapper label="Middle name">
              <input
                value={values.middleName || ''}
                onChange={e => updateValue('middleName', e.target.value)}
              />
            </FieldWrapper>
            <FieldWrapper label="Last name">
              <input
                value={values.lastName || ''}
                onChange={e => updateValue('lastName', e.target.value)}
              />
            </FieldWrapper>
            <FieldWrapper label="Suffix">
              <select
                value={values.suffix || ''}
                onChange={e => updateValue('suffix', e.target.value)}
              >
                <OptionsList
                  options={[
                    { label: 'Jr', value: 'Jr' },
                    { label: 'Sr', value: 'Sr' },
                    { label: 'II', value: 'II' },
                    { label: 'III', value: 'III' },
                    { label: 'IV', value: 'IV' }
                  ]}
                />
              </select>
            </FieldWrapper>
          </MultiFieldLine>
        </Section>

        {/* Assignee selection */}
        <Section>
          <FieldWrapper label="Assignee" style={{ width: '250px' }}>
            <select
              value={values.noAssignees ? NO_ASSIGNEES_VALUE : values.assigneeId || ''}
              onChange={handleAssigneeUpdate}
            >
              <OptionsList options={assigneeFilterOptions} />
            </select>
          </FieldWrapper>
        </Section>

        {/* Status filtering */}
        <Section>
          <CheckboxWrapper>
            <input
              type="checkbox"
              onChange={() => updateValue('includeClosed', !values.includeClosed)}
              checked={values.includeClosed}
            />
            Include closed applications
          </CheckboxWrapper>
        </Section>

        {/* Inactive applications */}
        <Section>
          <CheckboxWrapper>
            <input
              type="checkbox"
              onChange={() => updateValue('showOnlyInactive', !values.showOnlyInactive)}
              checked={values.showOnlyInactive}
            />
            Show only inactive applications
          </CheckboxWrapper>
          <div className="note">
            Inactive applications are unclosed applications that have not had any activity in 30
            days
          </div>
        </Section>

        {/* Date filtering */}
        <Section>
          <CheckboxWrapper>
            <input type="checkbox" onChange={handleAllDatesCheckbox} checked={values.allDates} />
            Display applications created on any date
          </CheckboxWrapper>
          {
            <div style={{ marginTop: '10px' }}>
              <MultiFieldLine>
                <Label>
                  Created start date
                  <DatePicker
                    disabled={values.allDates}
                    popperProps={{ strategy: 'fixed' }}
                    popperPlacement={'top-start'}
                    selected={customStartDate}
                    onChange={newValue => updateValue('customStart', newValue)}
                  />
                </Label>
                <Label>
                  Created end date
                  <DatePicker
                    disabled={values.allDates}
                    popperProps={{ strategy: 'fixed' }}
                    popperPlacement={'top-start'}
                    selected={customEndDate}
                    onChange={newValue => updateValue('customEnd', newValue)}
                  />
                </Label>
              </MultiFieldLine>
            </div>
          }
        </Section>
      </ScrollingModalContent>

      <ModalFooter>
        <LinkLikeButton
          onClick={handleClear}
          style={{ marginRight: 'auto', alignSelf: 'flex-start', marginTop: '10px' }}
        >
          Clear filters
        </LinkLikeButton>
        <InvertedButton onClick={onClose} style={{ minWidth: '120px' }}>
          Close
        </InvertedButton>
        <Button onClick={handleSave} style={{ minWidth: '120px' }}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export const TableFiltersModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(tableFiltersModalSelector);
  const { tableId } = useSelector(tableFiltersModalInfoSelector);

  const onClose = () => {
    dispatch(setTableFiltersModal(false, null));
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader>Search & filter</ModalHeader>
      <Wrapper>
        <FilterFieldsAndButtons tableId={tableId} onClose={onClose} />
      </Wrapper>
    </Modal>
  );
};
