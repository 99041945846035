import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { getInactiveAppsQuery } from '../../actions/queries';
import { DotLoader } from '../Loaders';
import { reportsSelector } from '../../selectors/entities';
import { reportsFiltersModalInfoSelector } from '../../selectors/modals';
import { TableHeader, ReportsText } from './Reports';
import { useHistory } from 'react-router-dom';
import { LinkLikeButton } from '../general/Button';
import { setTableFilters } from '../../actions/general';
import { ALL_APPLICATIONS_TABLE_ID } from '../../constants/general';

export const InactiveApplicationsSection = () => {
  const reportsFilters = useSelector(reportsFiltersModalInfoSelector);
  const [{ isFinished }] = useRequest(getInactiveAppsQuery(reportsFilters));

  if (!isFinished) {
    return <DotLoader />;
  }
  return <TableContent />;
};

const TableContent = () => {
  const reports = useSelector(reportsSelector);
  const plural = reports.inactiveApplicationsCount !== 1;
  const history = useHistory();
  const dispatch = useDispatch();

  const viewInactiveClick = () => {
    // Set all apps table filter to show only inactive apps
    dispatch(setTableFilters(ALL_APPLICATIONS_TABLE_ID, 'showOnlyInactive', true));
    // Then, navigate to the all applications page
    history.push('/all-apps');
  };

  return (
    <>
      <TableHeader>Inactive applications</TableHeader>
      <ReportsText>
        There are currently <strong>{reports.inactiveApplicationsCount}</strong> unclosed
        application{plural ? 's' : ''} that have not been worked on in the last 30 days.
        <LinkLikeButton onClick={viewInactiveClick}>View inactive apps</LinkLikeButton>
      </ReportsText>
    </>
  );
};
