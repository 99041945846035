import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../general/table/Table';
import { useRequest } from 'redux-query-react';
import { getCaseStatusQuery } from '../../actions/queries';
import { DotLoader } from '../Loaders';
import { reportsSelector } from '../../selectors/entities';
import { reportsFiltersModalInfoSelector } from '../../selectors/modals';
import { SmallTableWrapper, TableHeader, ReportsNote } from './Reports';

export const CaseStatusTable = () => {
  const reportsFilters = useSelector(reportsFiltersModalInfoSelector);
  const [{ isFinished }] = useRequest(getCaseStatusQuery(reportsFilters));

  if (!isFinished) {
    return <DotLoader />;
  }

  return <TableContent />;
};

const TableContent = () => {
  const reports = useSelector(reportsSelector);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Number of cases',
        accessor: 'count',
        rightAlignData: true
      }
    ],
    []
  );

  const data = React.useMemo(() => [...reports.caseStatus], [reports.caseStatus]);

  return (
    <>
      <TableHeader>Active cases by status</TableHeader>
      <ReportsNote>
        Status of expunction and nondisclosure cases for applications that are past the legal
        services agreement and not yet closed.
      </ReportsNote>
      <SmallTableWrapper>
        <Table data={data} columns={columns} hideSearch={true} />
      </SmallTableWrapper>
    </>
  );
};
