import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setCSVUploadModal } from '../../actions/modals';
import { CSVUploadModalSelector } from '../../selectors/modals';
import { Modal, ModalContent, ModalFooter, ModalHeader } from '../general/Modal';
import { Button } from '../general/Button';
import { useUploadCSV } from '../../hooks/useUploadCSV';
import { GREY_LIGHTER } from '../../constants/cssVars';

const CustomFileInput = styled.label`
  display: inline-block;
  padding: 0px;
  margin: 10px 0px;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  width: 100%;
`;

const CheckboxWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  > input {
    margin-right: 10px;
  }
  border-radius: 6px;
  background-color: ${GREY_LIGHTER};
  padding: 10px;
  border-radius: 6px;
`;

// CSV Upload Content
const UploadContent = ({ onUpload }) => {
  const [docFile, setDocFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [exportOnly, setExportOnly] = useState(false);

  const [submit, submitting] = useUploadCSV(() => {
    setDocFile(null);
    onUpload();
  });

  const onFileChange = e => {
    const targetFile = e.target.files[0];

    if (targetFile && targetFile.size > 1000000000) {
      setFileError('Please choose a smaller file (under 1GB)');
    } else if (targetFile) {
      // Remove errors and add targetFile to state
      setFileError(null);
      setDocFile(targetFile);
    }
  };

  return (
    <>
      <ModalHeader>Upload CSV</ModalHeader>
      <ModalContent>
        <p>
          To bulk-create applications or bulk-export case eligibility, please upload a CSV with the
          following fields:
        </p>
        <ul>
          <li>
            <strong>spn</strong> (required)
          </li>
          <li>
            <strong>
              firstName, middleName, lastName, suffix, dob, phone, email, streetAddress,
              streetAddress2, city, state, zipcode, ssnFour, idNumber
            </strong>{' '}
            (optional)
          </li>
        </ul>
        <p>
          Your CSV must include a header row using the field naming conventions listed above.
          Although some fields are optional, we recommend filling out as much as possible.
        </p>
        <CustomFileInput>
          <input type="file" onChange={onFileChange} />
        </CustomFileInput>
        {fileError && <div style={{ color: '#AD2424', marginBottom: '5px' }}>{fileError}</div>}
      </ModalContent>
      <CheckboxWrapper>
        <input type="checkbox" onChange={() => setExportOnly(!exportOnly)} checked={exportOnly} />
        <span>
          Generate eligbility export <strong>without</strong> creating applications
        </span>
      </CheckboxWrapper>
      <ModalFooter>
        <Button
          disabled={fileError != null}
          style={{ minWidth: '100px' }}
          onClick={() => submit(docFile, exportOnly)}
          isLoading={submitting}
        >
          Upload CSV
        </Button>
      </ModalFooter>
    </>
  );
};

// Modal to show when "Upload CSV" is clicked on.
// Allows user to select a csv file of SPNS & client info and upload it
export const CSVUploadModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(CSVUploadModalSelector);

  const onClose = () => {
    dispatch(setCSVUploadModal(false, null));
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <UploadContent onUpload={onClose} />
    </Modal>
  );
};
