// Progress list names
export const AGREEMENT_AND_WAIVER_LIST_NAME = 'Agreement & Fee Waiver Tasks';
export const EXPUNCTION_LIST_NAME = 'Expunction Tasks';
export const NONDISCLOSURE_LIST_NAME = 'Nondisclosure Tasks';

// Task lists for preliminary tasks, expunction tasks, & nondiclosure case tasks

const AGREEMENT_AND_WAIVER_TASKS = [
  {
    id: 'sentLegalServicesAndFeeWaiver',
    assigneeType: 'paralegal',
    description: (
      <div>
        <p>The legal services agreement and fee waiver have been sent to the client.</p>
      </div>
    )
  },
  {
    id: 'uploadedLegalServicesAndFeeWaiver',
    assigneeType: 'paralegal',
    description: (
      <div>
        <p>The legal services agreement and fee waiver have been received from the client.</p>
      </div>
    )
  }
];

const EXPUNCTION_TASKS = [
  {
    id: 'expunctionGeneratedAndReviewedPetition',
    assigneeType: 'paralegal',
    description: (
      <div>
        <p>The expunction petition has been generated and reviewed.</p>
      </div>
    )
  },
  {
    id: 'expunctionReviewedAndSignedOffPetition',
    assigneeType: 'attorney',
    description: (
      <div>
        <p>
          An attorney has completed a final review and has signed-off on the expunction peition.
        </p>
      </div>
    )
  },
  {
    id: 'expunctionFiledDocuments',
    assigneeType: 'paralegal',
    description: (
      <div>
        <p>The expunction petition has been successfully filed.</p>
      </div>
    )
  },
  {
    id: 'expunctionClosed',
    assigneeType: 'paralegal',
    description: (
      <div>
        <p>The court order has been uploaded. Work on these expunction cases is complete.</p>
      </div>
    )
  }
];

const NONDISCLOSURE_TASKS = [
  {
    id: 'nondisclosureReceivedExpunctionResult',
    assigneeType: 'paralegal',
    description: (
      <div>
        <p>
          Expunction results may affect a judge's decision for nondisclosure. This task will be
          autocompleted when the expunction task list is complete.
        </p>
        <p>
          If you choose to move forward with nondisclosure petitions before expunction results, you
          may mark this task as complete.
        </p>
      </div>
    )
  },
  {
    id: 'nondisclosureGeneratedAndReviewedPetition',
    assigneeType: 'paralegal',
    description: (
      <div>
        <p>The nondisclosure petition for this case has been generated and reviewed.</p>
      </div>
    )
  },
  {
    id: 'nondisclosureReviewedAndSignedOffPetition',
    assigneeType: 'attorney',
    description: (
      <div>
        <p>An attorney has completed a final review and signoff on the petition.</p>
      </div>
    )
  },
  {
    id: 'nondisclosureFiledDocuments',
    assigneeType: 'paralegal',
    description: (
      <div>
        <p>The petition has been successfully filed.</p>
      </div>
    )
  },
  {
    id: 'nondisclosureClosed',
    assigneeType: 'paralegal',
    description: (
      <div>
        <p>
          The court order has been uploaded if available. If there was no court order, please mark
          this task as complete.
        </p>
      </div>
    )
  }
];

export const isExpunctionTaskId = id => EXPUNCTION_TASKS.some(taskObj => taskObj.id === id);

// Map task list name to list of task objects
export const TASK_LIST_BY_NAME = {
  [AGREEMENT_AND_WAIVER_LIST_NAME]: AGREEMENT_AND_WAIVER_TASKS,
  [EXPUNCTION_LIST_NAME]: EXPUNCTION_TASKS,
  [NONDISCLOSURE_LIST_NAME]: NONDISCLOSURE_TASKS
};
