import {
  SET_ACTIVE_DROPDOWN,
  SET_TABLE_FILTERS,
  BULK_SET_TABLE_FILTERS,
  RESET_TABLE_FILTERS,
  MARK_AS_REFRESHED
} from '../actions/general';
import {
  ALL_APPLICATIONS_TABLE_ID,
  OTHER_ASSIGNED_APPLICATIONS_TABLE_ID,
  YOUR_READY_APPLICATIONS_TABLE_ID
} from '../constants/general';

const initialTableState = {
  allDates: true,
  includeClosed: false,
  showOnlyInactive: false,
  pageNum: 1,
  pageLimit: 10,
  sortBy: 'createdAt', // can set to a column id
  sortOrder: -1, // 1 or -1
  refresh: false // trigger a refresh in application tables on their next render
};

const defaultState = {
  activeDropdown: null,
  tableFilters: {
    [ALL_APPLICATIONS_TABLE_ID]: { ...initialTableState, pageLimit: 25 },
    [YOUR_READY_APPLICATIONS_TABLE_ID]: { ...initialTableState, pageLimit: 5 },
    [OTHER_ASSIGNED_APPLICATIONS_TABLE_ID]: { ...initialTableState, pageLimit: 5 }
  }
};

const generalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVE_DROPDOWN:
      return {
        ...state,
        activeDropdown: action.payload
      };
    case MARK_AS_REFRESHED:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          [action.payload.tableId]: {
            ...(state.tableFilters[action.payload.tableId] || {}),
            refresh: false
          }
        }
      };
    case SET_TABLE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          [action.payload.tableId]: {
            ...(state.tableFilters[action.payload.tableId] || {}),
            [action.payload.filterId]: action.payload.filterValue,
            refresh: true
          }
        }
      };
    case BULK_SET_TABLE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          [action.payload.tableId]: {
            ...(state.tableFilters[action.payload.tableId] || {}),
            ...action.payload.filterValues, // overwrite current filters with new filter values
            refresh: true
          }
        }
      };
    case RESET_TABLE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          [action.payload.tableId]: {
            ...(defaultState.tableFilters[action.payload.tableId] || {}),
            refresh: true
          }
        }
      };
    default:
      return state;
  }
};

export default generalReducer;
