import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { clearStore } from '../actions/general';
import { logoutQuery } from '../actions/queries';
import { mutateAsync } from 'redux-query';
import { SESSION_EXP_STORAGE_KEY } from '../constants/general';

// Returns function to clear state and redirect to the homepage with an error message
// For use when an unauthorized status is found
export const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return destination => {
    // first, logout
    dispatch(mutateAsync(logoutQuery())).then(() => {
      // then, clear store
      dispatch(clearStore());
      localStorage.removeItem(SESSION_EXP_STORAGE_KEY);
      history.push(destination || `/login`);
    });
  };
};
