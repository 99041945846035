import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setEditAssigneesModal } from '../../../actions/modals';
import { HIGHLIGHT, SHADOW_LIGHT } from '../../../constants/cssVars';
import { useCurrentApplication } from '../../../hooks/useCurrentApplication';
import { LinkLikeButton } from '../../general/Button';

const ErrorBannerWrapper = styled.div`
  background-color: ${HIGHLIGHT};
  border-radius: 6px;
  padding: 10px 15px;
  box-shadow: ${SHADOW_LIGHT};
  margin-bottom: 10px;
`;

export const getErrorText = (numErrors, suffix) => {
  return `There ${numErrors === 1 ? 'is' : 'are'} ${numErrors} field${
    numErrors === 1 ? '' : 's'
  } that need${numErrors === 1 ? 's' : ''} to be filled out or adjusted ${suffix || ''}`;
};

export const ErrorsBanner = ({ numErrors }) => {
  // don't show the banner if there are no errors
  if (numErrors < 1) {
    return null;
  }
  return <ErrorBannerWrapper>{getErrorText(numErrors)}</ErrorBannerWrapper>;
};

export const MissingAttorneyBanner = () => {
  const application = useCurrentApplication();
  const dispatch = useDispatch();
  // No need to show if there is an attorney assigned!
  if (application.assigneeAttorney && application.assigneeParalegal) {
    return null;
  }
  return (
    <ErrorBannerWrapper>
      Please{' '}
      <LinkLikeButton
        onClick={() => dispatch(setEditAssigneesModal(true))}
        style={{ display: 'inline-block' }}
      >
        add an assigned attorney and paralegal
      </LinkLikeButton>{' '}
      before continuing.
    </ErrorBannerWrapper>
  );
};
