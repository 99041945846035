import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

// For most formik forms, you want to wait until the user has touched a field
// or tried to submit before running validation / showing errors.
// However, for some cases you want to show errors immediately! Add this into forms to do so.
export const ValidateOnMount = () => {
  const { validateForm } = useFormikContext();
  useEffect(() => {
    validateForm();
  }, []);
  return null;
};
