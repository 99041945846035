export const SET_CLIENT_MODAL = 'SET_CLIENT_MODAL';
export const SET_CASE_MODAL = 'SET_CASE_MODAL';
export const SET_ADD_CASE_MODAL = 'SET_ADD_CASE_MODAL';
export const SET_SPN_LOOKUP_CASE_MODAL = 'SET_SPN_LOOKUP_CASE_MODAL';
export const SET_NEW_APPLICATION_MODAL = 'SET_NEW_APPLICATION_MODAL';
export const SET_OPEN_APPLICATION_ASSIGNMENT_MODAL = 'SET_OPEN_APPLICATION_ASSIGNMENT_MODAL';
export const SET_ADD_NOTE_MODAL = 'SET_ADD_NOTE_MODAL';
export const SET_EDIT_NOTE_MODAL = 'SET_EDIT_NOTE_MODAL';
export const SET_DELETE_NOTE_MODAL = 'SET_DELETE_NOTE_MODAL';
export const SET_TASK_MODAL = 'SET_TASK_MODAL';
export const SET_NEW_USER_MODAL = 'SET_NEW_USER_MODAL';
export const SET_EDIT_ASSIGNEES_MODAL = 'SET_EDIT_ASSIGNEES_MODAL';
export const SET_CONFIRMATION_MODAL = 'SET_CONFIRMATION_MODAL';
export const SET_DOCUMENT_UPLOAD_MODAL = 'SET_DOCUMENT_UPLOAD_MODAL';
export const SET_CSV_UPLOAD_MODAL = 'SET_CSV_UPLOAD_MODAL';
export const SET_ACTIVITY_LOG_MODAL = 'SET_ACTIVITY_LOG_MODAL';
export const SET_CLOSE_MODAL = 'SET_CLOSE_MODAL';
export const SET_DOCUSIGN_FIELDS_MODAL = 'SET_DOCUSIGN_FIELDS_MODAL';
export const SET_EXPUNCTION_CASE_FIELDS_MODAL = 'SET_EXPUNCTION_CASE_FIELDS_MODAL';
export const SET_DELETE_APP_MODAL = 'SET_DELETE_APP_MODAL';
export const SET_TABLE_FILTERS_MODAL = 'SET_TABLE_FILTERS_MODAL';
export const SET_EDIT_USER_MODAL = 'SET_EDIT_USER_MODAL';
export const SET_REPORTS_FILTERS_MODAL = 'SET_REPORTS_FILTERS_MODAL';
export const SET_REPORTS_FILTERS_INFO = 'SET_REPORTS_FILTERS';

export const setClientModal = showModal => {
  return { type: SET_CLIENT_MODAL, payload: showModal };
};

export const setCaseModal = (showModal, caseId) => {
  return { type: SET_CASE_MODAL, payload: { showModal, caseId } };
};

export const setAddCaseModal = showModal => {
  return { type: SET_ADD_CASE_MODAL, payload: { showModal } };
};

export const setSpnLookupCaseModal = (showModal, caseId) => {
  return { type: SET_SPN_LOOKUP_CASE_MODAL, payload: { showModal, caseId } };
};

export const setNewApplicationModal = showModal => {
  return { type: SET_NEW_APPLICATION_MODAL, payload: showModal };
};

export const setOpenApplicationAssignmentModal = showModal => {
  return { type: SET_OPEN_APPLICATION_ASSIGNMENT_MODAL, payload: showModal };
};

export const setAddNoteModal = showModal => {
  return { type: SET_ADD_NOTE_MODAL, payload: showModal };
};

export const setEditNoteModal = (showModal, currentNote) => {
  return { type: SET_EDIT_NOTE_MODAL, payload: { showModal, currentNote } };
};

export const setDeleteNoteModal = (showModal, currentNote) => {
  return { type: SET_DELETE_NOTE_MODAL, payload: { showModal, currentNote } };
};

export const setTaskModal = (showModal, taskId, caseId) => {
  return { type: SET_TASK_MODAL, payload: { showModal, taskId, caseId } };
};

export const setNewUserModal = showModal => {
  return { type: SET_NEW_USER_MODAL, payload: showModal };
};

export const setEditAssigneesModal = showModal => {
  return { type: SET_EDIT_ASSIGNEES_MODAL, payload: showModal };
};

export const setConfirmationModal = (showModal, modalTitle, modalBody, onConfirm) => {
  return {
    type: SET_CONFIRMATION_MODAL,
    payload: {
      showModal,
      modalTitle,
      modalBody,
      onConfirm
    }
  };
};

export const setDocumentUploadModal = showModal => {
  return { type: SET_DOCUMENT_UPLOAD_MODAL, payload: showModal };
};

export const setCSVUploadModal = showModal => {
  return { type: SET_CSV_UPLOAD_MODAL, payload: showModal };
};

export const setActivityLogModal = showModal => {
  return { type: SET_ACTIVITY_LOG_MODAL, payload: showModal };
};

export const setCloseModal = (showModal, caseId, isExpunction) => {
  return { type: SET_CLOSE_MODAL, payload: { showModal, caseId, isExpunction } };
};

export const setDocusignFieldsModal = (showModal, taskId, caseId) => {
  return { type: SET_DOCUSIGN_FIELDS_MODAL, payload: { showModal, taskId, caseId } };
};

export const setExpunctionCaseFieldsModal = (showModal, caseId) => {
  return { type: SET_EXPUNCTION_CASE_FIELDS_MODAL, payload: { showModal, caseId } };
};

export const setDeleteAppModal = showModal => {
  return { type: SET_DELETE_APP_MODAL, payload: showModal };
};

export const setTableFiltersModal = (showModal, tableId) => {
  return { type: SET_TABLE_FILTERS_MODAL, payload: { showModal, tableId } };
};

export const setEditUserModal = (showModal, selectedUser) => {
  return { type: SET_EDIT_USER_MODAL, payload: { showModal, selectedUser } };
};

export const setReportsFiltersModal = showModal => {
  return { type: SET_REPORTS_FILTERS_MODAL, payload: { showModal } };
};

// Update filters for the reports page
export const setReportsFiltersInfo = filterValues => {
  return { type: SET_REPORTS_FILTERS_INFO, payload: { filterValues } };
};
