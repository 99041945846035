const SITE_URL = process.env.REACT_APP_SITE_URL;

// Takes in a target ID of a html tag to print and returns a function to open up a print dialog for the content.
// Applies basic styling to the printable html.
export const usePrint = (targetId, pageName, style) => {
  const handlePrint = () => {
    var printContent = document.getElementById(targetId);
    var printWindow = window.open(SITE_URL, '_blank');
    printWindow.document.write(`
      <html>
      <title>${pageName} Print Preview</title>
      <style>
        body {
          font-family: 'Arial', sans-serif;
          color: #121212;
          padding: 20px;
        }
        @media print {  
          body {
            font-size: 1.2em;
          }
          table {
            font-size: 0.8em;
          }
        }
        ${style}
      </style>
      <body>
    `);
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write(`</body></html>`);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };
  return handlePrint;
};
