import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSpnLookupCaseModal } from '../../actions/modals';
import { caseModalInfoSelector, spnLookupCaseModalSelector } from '../../selectors/modals';
import { Modal, ModalHeader } from '../general/Modal';
import { CaseInformation } from '../applicationPage/CaseInformation';
import { spnLookupCasesSelector } from '../../selectors/entities';

// Modal to show when a SPN lookup case is clicked on.
// Allows user to view case, no editing.
export const SPNLookupCaseModal = () => {
  const dispatch = useDispatch();
  const spnLookupCases = useSelector(spnLookupCasesSelector);
  const showModal = useSelector(spnLookupCaseModalSelector);
  const { caseId } = useSelector(caseModalInfoSelector);
  const currentCaseObj = (spnLookupCases || []).find(caseObj => caseObj._id === caseId);

  const onClose = () => {
    dispatch(setSpnLookupCaseModal(false, null));
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <CaseInformation caseObj={currentCaseObj} />
    </Modal>
  );
};
