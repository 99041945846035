import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header } from './Header';
import { Flip, ToastContainer } from 'react-toastify';
import { Redirect, Route, Switch } from 'react-router-dom';
import { currentUserSelector } from '../selectors/entities';
import { Login } from './authPages/Login';
import { useSelector } from 'react-redux';
import { Sidebar } from './Sidebar';
import { MAX_DESKTOP_WIDTH, SIDEBAR_WIDTH } from '../constants/cssVars';
import { AllApplications } from './AllApplications';
import { ApplicationPage } from './applicationPage/ApplicationPage';
import { YourApplications } from './YourApplications';
import { SingleSPNLookup } from './spnPages/SingleSPNLookup';
import { BulkImportSPNs } from './spnPages/BulkImportSPNs';
import { ViewUsersPage } from './userPages/ViewUsersPage';
import { Reports } from './reports/Reports';
import { FullWidthPage } from './general/Common';
import { useRequest } from 'redux-query-react';
import {
  allUsersQuery,
  currentUserQuery,
  friendlyNamesQuery,
  lookupEndDateQuery,
  docusignActiveDocVersionsQuery
} from '../actions/queries';
import { NewUserModal } from './userPages/NewUserModal';
import { ResetPassword, ResetPasswordRedirect } from './authPages/ResetPassword';
import { PageLoader } from './Loaders';
import { SPNLookupCaseModal } from './spnPages/SPNLookupCaseModal';
import { NewApplicationModal } from './spnPages/NewApplicationModal';
import { UserProfilePage } from './userPages/UserProfilePage';
import { ConfirmationModal } from './general/ConfirmationModal';
import { OpenApplicationAssignmentModal } from './OpenApplicationAssignmentModal';
import { CSVUploadModal } from './spnPages/CSVUploadModal';
import { PauseScrollOnOpenModal } from './general/PauseScrollOnOpenModal';
import { TableFiltersModal } from './TableFiltersModal';
import { EditUserModal } from './userPages/EditUserModal';
import { ReportsFiltersModal } from './reports/ReportsFiltersModal';

const Wrapper = styled.div`
  width: 100%;
`;

// Root of all routes, & components that need to be accessed across the application (e.g. toast)
export const MainRoutes = () => {
  const currentUser = useSelector(currentUserSelector);
  const [{ isFinished }] = useRequest(currentUserQuery());

  // Since query state is cleared on logout, we want to remember in component state that the user request has been completed
  const [userQueryIsFinished, setUserQueryIsFinished] = useState(false);
  useEffect(() => {
    if (isFinished) {
      setUserQueryIsFinished(true);
    }
  }, [isFinished]);

  // If we haven't completed the current user request yet, show a page loader until complete
  if (!userQueryIsFinished) {
    return <PageLoader />;
  }

  return (
    <Wrapper>
      <ToastContainer autoClose={3000} position="bottom-right" transition={Flip} hideProgressBar />
      <PauseScrollOnOpenModal />
      {Object.keys(currentUser).length > 0 ? <LoggedInRoutes /> : <LoggedOutRoutes />}
    </Wrapper>
  );
};

// Pages to show when the user is logged out
const LoggedOutRoutes = () => {
  return (
    <main>
      <Switch>
        <Route path="/login" exact={true} component={Login} />
        <Route path="/reset-password/:resetPasswordToken" exact={true} component={ResetPassword} />
        <Redirect to="/login" />
      </Switch>
    </main>
  );
};

// Pages to show when the user is logged in
const LoggedInRoutes = () => {
  const [{ isFinished: friendlyNamesQueryFinished }] = useRequest(friendlyNamesQuery());
  const [{ isFinished: lookupEndDateFinished }] = useRequest(lookupEndDateQuery());
  const [{ isFinished: docusignDocVersionsFinished }] = useRequest(
    docusignActiveDocVersionsQuery()
  );
  const [{ isFinished: usersQueryFinished }] = useRequest(allUsersQuery());

  // If we haven't completed the constants & users queries yet, show a page loader until complete
  if (
    !friendlyNamesQueryFinished ||
    !usersQueryFinished ||
    !docusignDocVersionsFinished ||
    !lookupEndDateFinished
  ) {
    return <PageLoader />;
  }

  return (
    <>
      <Header />
      <Switch>
        <Route path="/application/:applicationId" component={ApplicationPage} />
        <Route
          path="/reset-password/:resetPasswordToken"
          exact={true}
          component={ResetPasswordRedirect}
        />
        <Route path="/" component={SidebarRoutes} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

// Pages with a sidebar, & modals that need to be placed outside sidebar formatting
const SidebarRoutes = () => {
  const { admin } = useSelector(currentUserSelector);
  return (
    <>
      <SidebarPageModals />
      <LoggedInMain>
        <Route path="/" component={Sidebar} />
        <FullWidthPage style={{ paddingTop: '40px' }}>
          <Switch>
            <Route path="/all-apps" component={AllApplications} />
            <Route path="/your-apps" component={YourApplications} />
            <Route path="/spn-lookup" component={SingleSPNLookup} />
            <Route path="/users/:userId" component={UserProfilePage} />
            {admin && <Route path="/import-spns" component={BulkImportSPNs} />}
            {admin && <Route path="/users" component={ViewUsersPage} />}
            <Route path="/reports" component={Reports} />
            <Route path="/">
              <Redirect to={admin ? '/all-apps' : '/your-apps'} />
            </Route>
          </Switch>
        </FullWidthPage>
      </LoggedInMain>
    </>
  );
};

// Modals to show on pages with our sidebar, so the background shadow can take up the full screen width.
const SidebarPageModals = () => {
  return (
    <>
      <SPNLookupCaseModal />
      <CSVUploadModal />
      <NewApplicationModal />
      <NewUserModal />
      <ConfirmationModal />
      <OpenApplicationAssignmentModal />
      <TableFiltersModal />
      <EditUserModal />
      <ReportsFiltersModal />
    </>
  );
};

const LoggedInMain = styled.main`
  display: flex;
  flex-direction: row;
  > div {
    width: 90%;
    width: calc(100% - ${SIDEBAR_WIDTH}px);
    @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
      width: 100%;
    }
  }
`;
