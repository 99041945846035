import React from 'react';
import { Button, InvertedButton } from '../general/Button';
import { ModalFooter, ModalHeader } from '../general/Modal';
import { useSelector } from 'react-redux';
import { useDeleteCase } from '../../hooks/simpleRequests';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { caseModalInfoSelector } from '../../selectors/modals';
import { ERROR } from '../../constants/cssVars';

// Displays delete case confirmation and cancel option
export const DeleteCaseConfirmation = ({ onCancel, onDeleteFinish }) => {
  const { caseId } = useSelector(caseModalInfoSelector);
  const application = useCurrentApplication();
  const [deleteCase, deleteCasePending] = useDeleteCase(onDeleteFinish);

  const submit = () => {
    deleteCase({ caseId, appId: application._id });
  };

  return (
    <>
      <ModalHeader>Delete Case Confirmation</ModalHeader>
      <p>Are you sure you want to delete this case?</p>
      <ModalFooter>
        <InvertedButton onClick={onCancel}>Cancel</InvertedButton>
        <Button
          style={{ backgroundColor: ERROR }}
          isLoading={deleteCasePending}
          onClick={submit}
          type="button"
        >
          Yes, delete case
        </Button>
      </ModalFooter>
    </>
  );
};
