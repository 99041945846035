import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../general/table/Table';
import { useRequest } from 'redux-query-react';
import { getAppEligibilityQuery } from '../../actions/queries';
import { DotLoader } from '../Loaders';
import { reportsSelector } from '../../selectors/entities';
import { reportsFiltersModalInfoSelector } from '../../selectors/modals';
import { TableHeader } from './Reports';

export const ApplicationEligibilityTable = () => {
  const reportsFilters = useSelector(reportsFiltersModalInfoSelector);
  const [{ isFinished }] = useRequest(getAppEligibilityQuery(reportsFilters));

  if (!isFinished) {
    return <DotLoader />;
  }

  return <TableContent />;
};

const TableContent = () => {
  const reports = useSelector(reportsSelector);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Eligibility breakdown',
        accessor: 'eligibilityBreakdown'
      },
      {
        Header: 'Total apps',
        accessor: 'totalNum',
        centerData: true
      },
      {
        Header: 'Completed apps',
        accessor: 'completedNum',
        centerData: true
      },
      {
        Header: 'Closed for other reason',
        accessor: 'closedAndNotCompletedNum',
        centerData: true
      }
    ],
    []
  );

  const data = React.useMemo(() => [...(reports.appEligibility || [])], [reports.appEligibility]);

  return (
    <>
      <TableHeader>Applications by eligibility</TableHeader>
      <Table data={data} columns={columns} hideSearch={true} />
    </>
  );
};
