import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setActiveDropdown } from '../../actions/general';
import { GREY_MEDIUM, PRIMARY, SHADOW_CRISP } from '../../constants/cssVars';
import { activeDropdownSelector } from '../../selectors/general';

const DropdownWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const DropdownButton = styled.button`
  font-size: 1em;
  border: 1px solid ${GREY_MEDIUM};
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 20px;
  color: ${PRIMARY};
  cursor: pointer;
  font-weight: 400;
  font-family: 'Open Sans';
  text-transform: none;
  &:hover {
    color: ${PRIMARY};
  }
`;

const DropdownItems = styled.div`
  position: absolute;
  top: 2em;
  right: 0px;
  z-index: 10;
  background-color: #fff;
  border: 1px solid grey;
  padding: 10px;
  box-shadow: ${SHADOW_CRISP};
  border-radius: 4px;
  min-width: 300px;
  font-size: 1em;
`;

const ARROW_SIZE = 5;
const LilArrow = styled.div`
  width: 0;
  height: 0;
  border-top: ${ARROW_SIZE}px solid transparent;
  border-bottom: ${ARROW_SIZE}px solid transparent;
  border-left: ${ARROW_SIZE}px solid ${PRIMARY};
  transform: rotateZ(${({ rotated }) => (rotated ? 90 : 270)}deg);
  margin-left: 10px;
  position: relative;
  top: 2px;
`;

// Display a dropdown menu with a list of options
// buttonComponent is an optional propt that will override the default button
// buttonText is the text of the button
// children is the content of the dropdown
// dropdownId is the id of the dropdown that we can manage for dropdown state
// style is the style of the dropdown items
export const Dropdown = ({ ButtonComponent, buttonText, dropdownId, children, style }) => {
  const dispatch = useDispatch();
  const activeDropdown = useSelector(activeDropdownSelector);
  const dropdownIsActive = activeDropdown !== dropdownId;

  const handleClick = e => {
    e.stopPropagation();
    if (activeDropdown !== dropdownId) {
      dispatch(setActiveDropdown(dropdownId));
    } else {
      dispatch(setActiveDropdown(null));
    }
  };

  let buttonSection = (
    <DropdownButton id={`${dropdownId}_btn`} onClick={handleClick}>
      {buttonText} <LilArrow rotated={dropdownIsActive} />
    </DropdownButton>
  );

  if (ButtonComponent) {
    buttonSection = <ButtonComponent id={`${dropdownId}_btn`} onClick={handleClick} />;
  }

  return (
    <DropdownWrapper>
      {buttonSection}

      {activeDropdown === dropdownId && (
        <DropdownItems style={style} id={dropdownId}>
          {children}
        </DropdownItems>
      )}
    </DropdownWrapper>
  );
};
