import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setNewApplicationModal, setSpnLookupCaseModal } from '../../actions/modals';
import { GREY_MEDIUM_DARK } from '../../constants/cssVars';
import { useSingleSPNLookup } from '../../hooks/simpleRequests';
import { usePrint } from '../../hooks/usePrint';
import { spnLookupCasesSelector, spnLookupStringSelector } from '../../selectors/entities';
import { SmallButton } from '../general/Button';
import { CasesTable } from '../general/CasesTable';
import { EnterKeyPressListener } from '../general/EnterKeyPressListener';
import { PrintButton } from '../general/PrintButton';

const SearchWrapper = styled.div`
  > input {
    max-width: 300px;
    margin-right: 10px;
  }
`;

const ResultsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  > div {
    display: flex;
    flex-direction: column;
  }
  @media print {
    font-family: 'Arial', sans-serif;
  }
`;

const ResultsHeader = styled.h3`
  margin-bottom: 5px;
  margin-top: 0px;
  @media print {
    margin-bottom: 400px;
  }
`;

const NameSubHeader = styled.h4`
  margin-top: 5px;
  color: ${GREY_MEDIUM_DARK};
`;

const HiddenPrintingContainer = styled.div`
  display: none;
`;

// Page to lookup single SPN number & optionally create an application from it
export const SingleSPNLookup = () => {
  const dispatch = useDispatch();
  const spnLookupCases = useSelector(spnLookupCasesSelector);
  const spnLookupString = useSelector(spnLookupStringSelector); // SPN that represents current results, was successfully searched
  const [tableSorting, setTableSorting] = useState(null);

  const [inputSpn, setInputSpn] = useState(spnLookupString || '');
  const [lookupFunc, lookupFuncPending] = useSingleSPNLookup();
  const handleLookup = () => lookupFunc({ spn: inputSpn });
  // Get print function, adding some extra spacing for the offense column (2nd column)
  const handlePrint = usePrint(
    'printable-case-results',
    'SPN Search Results',
    'td:nth-child(3) { min-width: 200px; } td, th { padding: 10px 10px 10px 0px; }'
  );
  const currentDate = new Date();

  return (
    <div>
      <EnterKeyPressListener onEnter={handleLookup} />

      <h2>SPN Lookup</h2>

      <SearchWrapper>
        <input
          value={inputSpn}
          onChange={e => setInputSpn(e.target.value)}
          placeholder="Enter SPN number"
        />
        <SmallButton
          onClick={handleLookup}
          disabled={inputSpn === ''}
          isLoading={lookupFuncPending}
        >
          Search SPN
        </SmallButton>
      </SearchWrapper>

      {Array.isArray(spnLookupCases) && (
        <div style={{ marginTop: '30px' }}>
          <ResultsHeaderWrapper>
            <div>
              <ResultsHeader>Case Results for SPN {spnLookupString}</ResultsHeader>
              {spnLookupCases.length > 0 && (
                <NameSubHeader>{spnLookupCases[0].full_name}</NameSubHeader>
              )}
            </div>
            <div style={{ marginTop: '10px' }}>
              <PrintButton onClick={handlePrint} text="Print results" />
            </div>
          </ResultsHeaderWrapper>
          {spnLookupCases.length < 1 ? (
            <div>No matching cases found for SPN {spnLookupString} in our data.</div>
          ) : (
            <CasesTable
              setTableSorting={setTableSorting}
              searchRight={
                <SmallButton onClick={() => dispatch(setNewApplicationModal(true))}>
                  Create application for this SPN +
                </SmallButton>
              }
              cases={spnLookupCases}
              setModal={setSpnLookupCaseModal}
            />
          )}
        </div>
      )}

      {/* Hidden section used to format simplified view for printing */}
      {Array.isArray(spnLookupCases) && (
        <HiddenPrintingContainer id="printable-case-results">
          <ResultsHeaderWrapper>
            <div>
              <ResultsHeader>Case Results for SPN {spnLookupString}</ResultsHeader>
              {spnLookupCases.length > 0 && (
                <NameSubHeader>{spnLookupCases[0].full_name}</NameSubHeader>
              )}
            </div>
            <p>Searched on {currentDate.toLocaleDateString()}</p>
          </ResultsHeaderWrapper>
          <hr />
          {spnLookupCases.length < 1 ? (
            <div>No matching cases found for SPN {spnLookupString} in our data.</div>
          ) : (
            <CasesTable currentSorting={tableSorting} printView={true} cases={spnLookupCases} />
          )}
        </HiddenPrintingContainer>
      )}
    </div>
  );
};
