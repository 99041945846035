import {
  AGREEMENT_AND_WAIVER_LIST_NAME,
  EXPUNCTION_LIST_NAME,
  NONDISCLOSURE_LIST_NAME,
  TASK_LIST_BY_NAME
} from '../constants/progressTracking';

// Given an application object, return just the assignee fields
const getAssigneesFromApplication = applicationObj => {
  return {
    assigneeAttorney: applicationObj.assigneeAttorney,
    assigneeParalegal: applicationObj.assigneeParalegal
  };
};

// Given an application or case object and a list of task objects, populate each task object
// with the task's status from the application & caseId if applicable
const getPopulatedTaskList = (applicationOrCaseObj, assignees, taskObjs) => {
  return taskObjs.map(taskObj => {
    let assignee = null;
    if (taskObj.assigneeType === 'attorney') {
      assignee = assignees.assigneeAttorney;
    } else if (taskObj.assigneeType === 'paralegal') {
      assignee = assignees.assigneeParalegal;
    }

    return {
      ...taskObj,
      assignee,
      complete: applicationOrCaseObj[taskObj.id],
      casID: applicationOrCaseObj.casID, // this will be null for application objects
      caseId: applicationOrCaseObj.casID ? applicationOrCaseObj._id : null // this will be null for application objects
    };
  });
};

// Given a task list, return the next incomplete task after the furthest complete task
const getCurrentTask = taskList => {
  let furthestCompleteTaskIndex = -1;
  // run backwards through the task array to check for the last (first-occuring in this loop) complete task
  for (let i = taskList.length - 1; i >= 0; i--) {
    if (taskList[i].complete) {
      furthestCompleteTaskIndex = i;
      break;
    }
  }
  // if there were no complete tasks, set the current task to index 0
  if (furthestCompleteTaskIndex === -1) {
    return taskList[0];
  }
  // If the last task is complete, return null (indicating there are no more tasks for this list)
  if (furthestCompleteTaskIndex === taskList.length - 1) {
    return null;
  }
  // Otherwise, return the next task
  return taskList[furthestCompleteTaskIndex + 1];
};

// Return the number of complete tasks from a given list
const getNumTasksCompleted = taskList => taskList.filter(taskObj => taskObj.complete).length;

// Return a progress object, with the list name, populated task list, current task, & the number of tasks completed
const getProgressObj = (
  applicationOrCaseObj,
  assignees,
  listName,
  skipReceivedExpunctionResultTask
) => {
  let taskList = getPopulatedTaskList(applicationOrCaseObj, assignees, TASK_LIST_BY_NAME[listName]);

  // If "skipReceivedExpunctionResultTask", we want to skip including "nondisclosureReceivedExpunctionResult" from our list
  // This is used removing the expunction result step for applications that don't have expunction eligible cases
  if (skipReceivedExpunctionResultTask) {
    taskList = taskList.filter(taskObj => taskObj.id !== 'nondisclosureReceivedExpunctionResult');
  }

  // Task lists can be considered closed when the final step in the list is complete, which occurs when no current task exists for the list
  const currentTask = getCurrentTask(taskList);
  const listIsComplete = currentTask == null;

  return {
    // if the current list is a nondisclosure case task list, add the casID to the name
    displayName:
      listName === NONDISCLOSURE_LIST_NAME
        ? `${NONDISCLOSURE_LIST_NAME}, Case ${applicationOrCaseObj.casID}`
        : listName,
    listName,
    taskList,
    currentTask,
    listIsComplete,
    numCompleted: getNumTasksCompleted(taskList),
    casID: applicationOrCaseObj.casID, // Will only be populated for nondisclosure case lists
    caseId: applicationOrCaseObj.casID ? applicationOrCaseObj._id : null // this will be null for application objects
  };
};

// Given an application object, return the all relevant progress lists.
// This includes the preliminary progress list for all applications, then once the preliminary progress list complete,
// the list objects also include the expunction list & nondisclosure list(s) depending on case eligibility.
/*
Example Progress List Object:
{
  currentTask: {id: 'nondisclosureReviewedAndSignedOffPetition', assigneeType: 'attorney', description: {…}, assignee: {…}, complete: undefined, …}
  displayName: "Nondisclosure Tasks, Case 9449900010112345"
  caseId: "9449900010112345" // is null for general-application or expunction progress list objs
  listIsComplete: false
  numCompleted: 1
  taskList: [{id: 'nondisclosureGeneratedAndReviewedPetition', assigneeType: 'paralegal', description: {…}, assignee: {…}, complete: true, …}, ...]
}
*/
export const getProgressListObjsForApplication = application => {
  // Array of progress objects to show for the application
  const progressListObjs = [];

  // Assignees object
  const assignees = getAssigneesFromApplication(application);

  const eligibleCasesExist = application.hcdcCases.some(caseDoc =>
    ['expunction', 'nondisclosure'].includes(caseDoc.final_eligibility)
  );
  if (eligibleCasesExist) {
    // Add preliminary progress list object (contains task list & other display details)
    // Add the agreement & fee waiver task list!
    const agreementAndFeeWaiverObj = getProgressObj(
      application,
      assignees,
      AGREEMENT_AND_WAIVER_LIST_NAME
    );
    progressListObjs.push(agreementAndFeeWaiverObj);

    // Then, if the agreement & fee waiver task list is complete ...
    if (agreementAndFeeWaiverObj.listIsComplete) {
      // Add the expunction & nondisclosure task lists!

      // if there are expunction eligible cases, add an expunction progress list
      const expunctionEligCases = application.hcdcCases.filter(
        appObj => appObj.final_eligibility === 'expunction'
      );
      const expunctionEligCasesExist = expunctionEligCases.length > 0;
      if (expunctionEligCasesExist) {
        const expunctionProgressObj = getProgressObj(application, assignees, EXPUNCTION_LIST_NAME);
        progressListObjs.push(expunctionProgressObj);
      }

      // for every nondisclosure eligible case, generate a progress list
      const nondisclosureEligCases = application.hcdcCases.filter(
        appObj => appObj.final_eligibility === 'nondisclosure'
      );
      nondisclosureEligCases.forEach(caseObj => {
        const nondisclosureProgressObj = getProgressObj(
          caseObj,
          assignees,
          NONDISCLOSURE_LIST_NAME,
          !expunctionEligCasesExist
        );
        progressListObjs.push(nondisclosureProgressObj);
      });
    }
  }

  return progressListObjs;
};
