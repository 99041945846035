import React from 'react';
import styled from 'styled-components';
import { GREY_DARK } from '../../../constants/cssVars';

const Text = styled.p`
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 0.9em;
  color: ${GREY_DARK};
`;

// Note to let user know fields are shared in docusign field editing modals
export const SharedFieldsNote = () => {
  return (
    <Text>
      Please note that these fields are shared and used in multiple places in this application.
      Other places using these fields will be updated to match when saved.
    </Text>
  );
};
