// Nondisclosure form case properties
export const FINAL_POT_ELIG_411_0727 = 'final_pot_elig_411_0727';
export const FINAL_POT_ELIG_411_0728 = 'final_pot_elig_411_0728';
export const FINAL_POT_ELIG_411_0731 = 'final_pot_elig_411_0731';
export const FINAL_POT_ELIG_411_0736 = 'final_pot_elig_411_0736';
export const PENDING_POT_ELIG_411_0727 = 'pending_pot_elig_411_0727';
export const PENDING_POT_ELIG_411_0728 = 'pending_pot_elig_411_0728';
export const NDO_FORM = 'ndo_form';
export const DAYS_SINCE_SENTENCE_COMPLETION = 'days_since_sentence_completion';
export const DAYS_SINCE_PROBATION_COMPLETION = 'days_since_probation_completion';

// Nondisclosure Form IDs
export const FORM_ID_411_072 = '411.072';
export const FORM_ID_411_0725 = '411.0725';
export const FORM_ID_411_0726 = '411.0726'; // Needs followup questions
export const FORM_ID_411_0727 = '411.0727'; // Needs followup questions
export const FORM_ID_411_0728 = '411.0728'; // Needs followup questions
export const FORM_ID_411_073 = '411.073';
export const FORM_ID_411_0731 = '411.0731'; // Needs followup questions
export const FORM_ID_411_0735 = '411.0735';
export const FORM_ID_411_0736 = '411.0736'; // Needs followup questions

// Additional case field properties
export const CASE_FIELD_PROPERTIES = {
  final_eligibility: {
    options: ['nondisclosure', 'expunction', 'expunction after SOL', 'ineligible'],
    optionsProps: {
      hideEmptyOption: true
    }
  },
  ndo_form: {
    options: [
      FORM_ID_411_072,
      FORM_ID_411_0725,
      FORM_ID_411_0726,
      FORM_ID_411_0727,
      FORM_ID_411_0728,
      FORM_ID_411_073,
      FORM_ID_411_0731,
      FORM_ID_411_0735,
      FORM_ID_411_0736
    ],
    optionsProps: {
      emptyOptionLabel: 'None'
    }
  },
  misdemeanor_felony: {
    options: ['misdemeanor', 'felony']
  },
  same_criminal_episode_has_felony: {
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  bwi: {
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  def_dob: {
    isDate: true
  },
  fda: {
    isDate: true
  },
  conviction_date: {
    isDate: true
  },
  da_start: {
    isDate: true
  },
  da_end: {
    isDate: true
  },
  probation_start: {
    isDate: true
  },
  probation_end: {
    isDate: true
  },
  waiting_period_start: {
    isDate: true
  },
  arrest_date: {
    isDate: true
  },
  dismissal_date: {
    isDate: true
  },
  offense_date: {
    isDate: true
  },
  pled_guilty_date: {
    isDate: true
  },
  sentence_date: {
    isDate: true
  },
  offense_committed_date: {
    isDate: true
  },
  previously_filed_date: {
    isDate: true
  },
  treatment_court_completion_date: {
    isDate: true
  },
  court_deferral_date: {
    isDate: true
  },
  sol: {
    isNumeric: true
  },
  ndo_0725_is_072_ineligible_reason: {
    options: [
      {
        value: 'Petitioner has prior convictions or deferred adjudications.',
        label: 'Petitioner has prior convictions or deferred adjudications'
      },
      {
        value:
          'The offense which the  order of nondisclosure is requested falls under Texas Penal Code Chapter 20/21/22/25/42/43/46/71.',
        label: 'The offense falls under Chapter 20/21/22/25/42/43/46/71'
      },
      {
        value:
          'The Court found that it is not in the best interest of justice that Petitioner receive an automatic order of nondisclosure under 411.072.',
        label: 'Court found it not in the best interest of justice'
      },
      {
        value: 'The offense for which the order of nondisclosure is requested is a felony.',
        label: 'The offense for which the order of nondisclosure is requested is a felony'
      },
      {
        value: 'Petitioner received a discharge and dismissal prior to September 1, 2017.',
        label: 'Petitioner received a discharge and dismissal prior to September 1, 2017'
      }
    ]
  },
  deferred_adjudication: {
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  used_ignition_interlock: {
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  ndo_0728_unable_to_provide_assistance: {
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  ndo_0728_previously_filed: {
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  ndo_0735_date_or_second_anniversary: {
    options: ['date', 'second anniversary']
  },
  isDrugRelated: {
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  }
};

export const UNKNOWN_ARRESTING_AGENCY = 'UNKNOWN ARRESTING AGENCY';
