import React from 'react';
import { Link } from 'react-router-dom';
import chevronUp from '../../../assets/icons/small_chevron_up.svg';
import chevronDown from '../../../assets/icons/small_chevron_down.svg';
import { TableLoader } from '../../Loaders';
import { LinkLikeButton } from '../Button';
import {
  TitleWrapper,
  SearchAndInteractivesWrapper,
  TableWrapper,
  StyledTable,
  TableHeader,
  HeaderButton,
  TableRow,
  TableData,
  TableFooter,
  SortingChevron,
  NotFoundMessage
} from './StyledTable';
import PageNavigation from './PageNavigation';

// Table component with pagination & external filtering
// DOES NOT use react-table, we get the search results with subsequent queries to the backend! :)
export const ManualTable = ({
  // Required props
  rows,
  columns,
  // Optional props
  tableTitle, // (string) table's title, if not providing outside table, that will insert the row count into it
  tableBanner, // (element) lives underneath a table's title / above the search bar, elements should be a TableBanner component for consistency
  topLeft, // (element) element(s) to show in the top left, just above the table
  topRight, // (element) element(s) to show in the top right, just above the table
  emptyMessage, // (string) message to display when there are no results
  onRowClick, // (function) function to call when a row's first entry is clicked (will take precedence over "getLink")
  getLink, // (function) function that returns a link to visit when a row's first entry is clicked
  isLoading, // (boolean) whether or not population query is in progress
  sorting /*
    sortBy: (string) column id that's currently used for sorting
    sortOrder: 1 or -1
    setSort: (columnId) => update sort in state,
  */,
  pagination /*
    pageNum: filters.pageNum,
    setPageNum: (newPageNum) => dispatch(setTableFilters(tableId, 'pageNum', newPageNum)),
    totalPages,
    totalDocuments,
  */
}) => {
  return (
    <>
      {tableTitle && (
        <TitleWrapper>
          <h2>
            {tableTitle}{' '}
            {pagination && pagination.totalDocuments ? `(${pagination.totalDocuments})` : ''}
          </h2>
        </TitleWrapper>
      )}
      {tableBanner}
      <SearchAndInteractivesWrapper>
        {topLeft}
        {topRight}
      </SearchAndInteractivesWrapper>
      <TableWrapper>
        <div style={{ position: 'relative', width: '100%' }}>
          <StyledTable>
            <thead>
              <TableRow>
                {columns.map(({ accessor, Header, centerData, style, disableSort }) => {
                  const sortEnabled = sorting && !disableSort;
                  return (
                    <TableHeader
                      key={`h_${accessor}`}
                      style={{ textAlign: centerData ? 'center' : 'left', ...(style || {}) }}
                    >
                      <HeaderButton onClick={() => sortEnabled && sorting.setSort(accessor)}>
                        {Header}
                        {sortEnabled && (
                          <span style={{ fontSize: '0.8em', marginLeft: '5px' }}>
                            {sorting.sortBy === accessor ? (
                              sorting.sortOrder < 0 ? (
                                <SortingChevron src={chevronDown} />
                              ) : (
                                <SortingChevron src={chevronUp} />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        )}
                      </HeaderButton>
                    </TableHeader>
                  );
                })}
              </TableRow>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => {
                return (
                  <TableRow key={`${rowIndex}_r`}>
                    {columns.map(({ accessor, centerData, allowOverflow }, idx) => {
                      const value = row[accessor];
                      return (
                        <TableData
                          key={`${rowIndex}_${idx}_d`}
                          style={{
                            textAlign: centerData ? 'center' : 'left',
                            overflow: allowOverflow ? 'visible' : 'auto'
                          }}
                        >
                          {// if a "getLink" or "onRowClick" function is provided, use its result as a link or button onClick respectively
                          // for the first item in row
                          onRowClick && idx === 0 ? (
                            <LinkLikeButton onClick={() => onRowClick(row)}>{value}</LinkLikeButton>
                          ) : getLink && idx === 0 ? (
                            <Link to={getLink(row)}>{value}</Link>
                          ) : (
                            <div>{value}</div>
                          )}
                        </TableData>
                      );
                    })}
                  </TableRow>
                );
              })}
            </tbody>
          </StyledTable>
          {/* If there are no pages, return a no-results message */}
          {rows.length < 1 && (
            <NotFoundMessage>
              {isLoading ? 'Loading ...' : emptyMessage || 'No results found'}
            </NotFoundMessage>
          )}
          <TableLoader showLoader={isLoading} />
        </div>
        <TableFooter>{pagination && <PageNavigation pagination={pagination} />}</TableFooter>
      </TableWrapper>
    </>
  );
};
