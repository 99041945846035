import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setActivityLogModal } from '../../actions/modals';
import { activityLogModalSelector } from '../../selectors/modals';
import { Modal, ModalFooter, ModalHeader, ModalContent } from '../general/Modal';
import { Button, InvertedButton } from '../general/Button';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { List, AutoSizer } from 'react-virtualized';
import {
  GREY_DARK,
  GREY_DARKEST,
  GREY_LIGHT,
  GREY_MEDIUM_LIGHT,
  SHADOW_CRISP
} from '../../constants/cssVars';
import { UserDisplay } from '../UserDisplay';
import moment from 'moment';

const ListWrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: min(
    ${props => props.height || 500}px,
    calc(100vh - ${props => props.heightOffset || 300}px)
  );
  padding: 10px 15px;
  background-color: ${GREY_LIGHT};
  border-radius: 6px;
`;

const ActivityItem = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
  box-shadow: ${SHADOW_CRISP};
  > p {
    margin: 0px;
    color: ${GREY_DARK};
  }
`;

const ActionDisplay = styled.div`
  height: ${props => props.height || 50}px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: ${GREY_DARKEST};
`;

const FieldsChangedDisplay = styled.div`
  background-color: ${GREY_LIGHT};
  padding: 3px;
  border-radius: 6px;
  height: 23px;
  overflow: auto;
  white-space: nowrap;
`;

const sortByActivityDate = (a, b) => {
  const activityDateA = new Date(a.activityDate);
  const activityDateB = new Date(b.activityDate);

  let comparison = 0;
  if (activityDateA > activityDateB) {
    comparison = -1;
  } else if (activityDateA < activityDateB) {
    comparison = 1;
  }
  return comparison;
};

// Display history of activity in modal format
export const ActivityLogModal = () => {
  const application = useCurrentApplication();
  const showModal = useSelector(activityLogModalSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setActivityLogModal(false));
  };

  const sortedActivityLog = (application.activityLog || []).sort(sortByActivityDate);

  if (!showModal) {
    return null;
  }

  const rowRenderer = ({ index, style }) => {
    const logObj = sortedActivityLog[index];
    return (
      <div style={style} key={`log_item_${index}`}>
        <ActivityItem>
          <UserDisplay userObj={logObj.user} displayAsText={true} />
          <p>{moment(logObj.activityDate).format('MM-DD-YYYY, hh:mm a')}</p>
          <ActionDisplay height={logObj.fieldsChanged ? 20 : 50}>{logObj.action}</ActionDisplay>
          {logObj.fieldsChanged && (
            <FieldsChangedDisplay>Changes: {logObj.fieldsChanged}</FieldsChangedDisplay>
          )}
        </ActivityItem>
      </div>
    );
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader>Activity History</ModalHeader>
      <ListWrapper>
        <AutoSizer>
          {({ width, height }) => (
            <List
              height={height}
              rowCount={sortedActivityLog.length}
              rowHeight={135}
              rowRenderer={rowRenderer}
              width={width}
            />
          )}
        </AutoSizer>
      </ListWrapper>
      <ModalFooter>
        <InvertedButton onClick={onClose}>Close</InvertedButton>
      </ModalFooter>
    </Modal>
  );
};
