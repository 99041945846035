import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../general/table/Table';
import { useRequest } from 'redux-query-react';
import { getAppClosedReasonQuery } from '../../actions/queries';
import { DotLoader } from '../Loaders';
import { reportsSelector } from '../../selectors/entities';
import { capitalizeFirstLetter } from '../../utils/general';
import { reportsFiltersModalInfoSelector } from '../../selectors/modals';
import { TableHeader, SmallTableWrapper } from './Reports';

export const ApplicationClosedReasonTable = () => {
  const reportsFilters = useSelector(reportsFiltersModalInfoSelector);
  const [{ isFinished }] = useRequest(getAppClosedReasonQuery(reportsFilters));

  if (!isFinished) {
    return <DotLoader />;
  }
  return <TableContent />;
};

const TableContent = () => {
  const reports = useSelector(reportsSelector);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Closed reason',
        accessor: 'closedReason'
      },
      {
        Header: 'Number of applications',
        accessor: 'count',
        rightAlignData: true
      }
    ],
    []
  );

  const data = React.useMemo(
    () =>
      reports.appClosedReason.map(app => ({
        ...app,
        closedReason: capitalizeFirstLetter(app._id)
      })),
    [reports.appClosedReason]
  );

  return (
    <>
      <TableHeader>Applications by closed reason</TableHeader>
      <SmallTableWrapper>
        <Table data={data} columns={columns} hideSearch={true} />
      </SmallTableWrapper>
    </>
  );
};
