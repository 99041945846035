import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDocusignFieldsModal } from '../../../actions/modals';
import {
  docusignFieldsModalInfoSelector,
  docusignFieldsModalSelector
} from '../../../selectors/modals';
import { Modal } from '../../general/Modal';
import { LegalServicesFields } from './LegalServicesFields';
import { ExpunctionFields } from './ExpunctionFields';
import { LegalServicesPreview } from './LegalServicesPreview';
import { ExpunctionPetitionPreview } from './ExpunctionPetitionPreview';
import { NondisclosureFields } from './NondisclosureFields';
import { NondisclosurePetitionPreview } from './NondisclosurePetitionPreview';

// Displays docusign fields, then displays preview, then sends the form
export const DocusignFieldsModal = () => {
  const showModal = useSelector(docusignFieldsModalSelector);
  const { taskId } = useSelector(docusignFieldsModalInfoSelector);
  const dispatch = useDispatch();
  const [pageNum, setPageNum] = useState(0);
  const [previewUrl, setPreviewUrl] = useState(null);

  const onClose = () => {
    setPageNum(0);
    dispatch(setDocusignFieldsModal(false, null, null));
  };

  if (!showModal) {
    return null;
  }

  // Pages represent each step in the modal flow. First page is the form fields, second page is the preview modal, with
  // "onNext", "onEditFields", and "onClose" defined to handle movement
  let pages = [];
  if (taskId === 'sentLegalServicesAndFeeWaiver') {
    pages = [
      <LegalServicesFields
        onClose={onClose}
        onNext={newUrl => {
          setPreviewUrl(newUrl);
          setPageNum(1);
        }}
      />,
      <LegalServicesPreview
        onClose={onClose}
        onEditFieldsClick={() => setPageNum(0)}
        previewUrl={previewUrl}
      />
    ];
  }

  if (taskId === 'expunctionGeneratedAndReviewedPetition') {
    pages = [
      <ExpunctionFields
        onClose={onClose}
        onNext={newUrl => {
          setPreviewUrl(newUrl);
          setPageNum(1);
        }}
      />,
      <ExpunctionPetitionPreview
        onClose={onClose}
        onEditFieldsClick={() => setPageNum(0)}
        previewUrl={previewUrl}
        showSendEnvelope={false}
      />
    ];
  }

  if (taskId === 'expunctionReviewedAndSignedOffPetition') {
    pages = [
      <ExpunctionFields
        onClose={onClose}
        onNext={newUrl => {
          setPreviewUrl(newUrl);
          setPageNum(1);
        }}
        reviewFormFields={true}
      />,
      <ExpunctionPetitionPreview
        onClose={onClose}
        onEditFieldsClick={() => setPageNum(0)}
        previewUrl={previewUrl}
        showSendEnvelope={true}
      />
    ];
  }

  if (taskId === 'nondisclosureGeneratedAndReviewedPetition') {
    pages = [
      <NondisclosureFields
        onClose={onClose}
        onNext={newUrl => {
          setPreviewUrl(newUrl);
          setPageNum(1);
        }}
        reviewFormFields={true}
      />,
      <NondisclosurePetitionPreview
        onClose={onClose}
        onEditFieldsClick={() => setPageNum(0)}
        previewUrl={previewUrl}
        showSendEnvelope={false}
      />
    ];
  }

  if (taskId === 'nondisclosureReviewedAndSignedOffPetition') {
    pages = [
      <NondisclosureFields
        onClose={onClose}
        onNext={newUrl => {
          setPreviewUrl(newUrl);
          setPageNum(1);
        }}
        reviewFormFields={true}
      />,
      <NondisclosurePetitionPreview
        onClose={onClose}
        onEditFieldsClick={() => setPageNum(0)}
        previewUrl={previewUrl}
        showSendEnvelope={true}
      />
    ];
  }

  return (
    <Modal onClose={onClose} width={700}>
      {pages[pageNum]}
    </Modal>
  );
};
