import { errorsByRequiredFieldsList } from '../validators';

// case initial values unique to each nondisclosure form & order
const getUniqueCaseInitialValues072 = caseObj => ({
  current_offense_name: caseObj.current_offense_name || '',
  dismissal_date: caseObj.dismissal_date || '',
  da_start: caseObj.da_start || '',
  da_end: caseObj.da_end || ''
});

const getUniqueCaseInitialValues0725 = caseObj => ({
  current_offense_name: caseObj.current_offense_name || '',
  current_charge_level: caseObj.current_charge_level || '',
  misdemeanor_felony: caseObj.misdemeanor_felony || '',
  pled_guilty_date: caseObj.pled_guilty_date || '',
  court_deferral_date: caseObj.court_deferral_date || '',
  dismissal_date: caseObj.dismissal_date || '',
  da_start: caseObj.da_start || '',
  da_end: caseObj.da_end || '',
  sol: caseObj.sol || 0,
  ndo_0725_is_072_ineligible_reason: caseObj.ndo_0725_is_072_ineligible_reason || '',
  off_rpt_num: caseObj.off_rpt_num || ''
});

const getUniqueCaseInitialValues0726 = caseObj => ({
  bwi: typeof caseObj.bwi === 'boolean' ? caseObj.bwi : '',
  pled_guilty_date: caseObj.pled_guilty_date || '',
  court_deferral_date: caseObj.court_deferral_date || '',
  da_start: caseObj.da_start || '',
  da_end: caseObj.da_end || '',
  dismissal_date: caseObj.dismissal_date || '',
  off_rpt_num: caseObj.off_rpt_num || ''
});

const getUniqueCaseInitialValues0727 = caseObj => ({
  current_offense_name: caseObj.current_offense_name || '',
  current_charge_level: caseObj.current_charge_level || '',
  misdemeanor_felony: caseObj.misdemeanor_felony || '',
  treatment_court_completion_date: caseObj.treatment_court_completion_date || '',
  deferred_adjudication:
    typeof caseObj.deferred_adjudication === 'boolean' ? caseObj.deferred_adjudication : '',
  off_rpt_num: caseObj.off_rpt_num || ''
});

const getUniqueCaseInitialValues0728 = caseObj => ({
  current_offense_name: caseObj.current_offense_name || '',
  conviction_date: caseObj.conviction_date || '',
  offense_committed_date: caseObj.offense_committed_date || '',
  ndo_0728_unable_to_provide_assistance:
    typeof caseObj.ndo_0728_unable_to_provide_assistance === 'boolean'
      ? caseObj.ndo_0728_unable_to_provide_assistance
      : '',
  ndo_0728_previously_filed:
    typeof caseObj.ndo_0728_previously_filed === 'boolean' ? caseObj.ndo_0728_previously_filed : '',
  previously_filed_date: caseObj.previously_filed_date || ''
});

const getUniqueCaseInitialValues073 = caseObj => ({
  current_offense_name: caseObj.current_offense_name || '',
  conviction_date: caseObj.conviction_date || '',
  probation_start: caseObj.probation_start || '',
  probation_end: caseObj.probation_end || '',
  ndo_0735_date_or_second_anniversary: caseObj.ndo_0735_date_or_second_anniversary || '',
  off_rpt_num: caseObj.off_rpt_num || ''
});

const getUniqueCaseInitialValues0731 = caseObj => ({
  probation_start: caseObj.probation_start || '',
  probation_end: caseObj.probation_end || '',
  used_ignition_interlock:
    typeof caseObj.used_ignition_interlock === 'boolean' ? caseObj.used_ignition_interlock : '',
  off_rpt_num: caseObj.off_rpt_num || ''
});

const getUniqueCaseInitialValues0735 = caseObj => ({
  current_offense_name: caseObj.current_offense_name || '',
  sentence: caseObj.sentence || '',
  conviction_date: caseObj.conviction_date || '',
  dismissal_date: caseObj.dismissal_date || '',
  ndo_0735_date_or_second_anniversary: caseObj.ndo_0735_date_or_second_anniversary || '',
  off_rpt_num: caseObj.off_rpt_num || ''
});

const getUniqueCaseInitialValues0736 = caseObj => ({
  sentence_date: caseObj.sentence_date || '',
  dismissal_date: caseObj.dismissal_date || '',
  conviction_date: caseObj.conviction_date || '',
  off_rpt_num: caseObj.off_rpt_num || '',
  used_ignition_interlock:
    typeof caseObj.used_ignition_interlock === 'boolean' ? caseObj.used_ignition_interlock : ''
});

// Get form values and validation function by nondisclosure form number
export const INITIAL_VALUES_AND_VALIDATE_BY_NDO_FORM = {
  '411.0725': {
    getUniqueCaseInitialValues: getUniqueCaseInitialValues0725,
    validate: values =>
      errorsByRequiredFieldsList(Object.keys(getUniqueCaseInitialValues0725({})), values)
  },
  '411.0728': {
    getUniqueCaseInitialValues: getUniqueCaseInitialValues0728,
    validate: values =>
      errorsByRequiredFieldsList(Object.keys(getUniqueCaseInitialValues0728({})), values)
  },
  '411.073': {
    getUniqueCaseInitialValues: getUniqueCaseInitialValues073,
    validate: values =>
      errorsByRequiredFieldsList(Object.keys(getUniqueCaseInitialValues073({})), values)
  },
  '411.0735': {
    getUniqueCaseInitialValues: getUniqueCaseInitialValues0735,
    validate: values =>
      errorsByRequiredFieldsList(Object.keys(getUniqueCaseInitialValues0735({})), values)
  },
  '411.0736': {
    getUniqueCaseInitialValues: getUniqueCaseInitialValues0736,
    validate: values =>
      errorsByRequiredFieldsList(Object.keys(getUniqueCaseInitialValues0736({})), values)
  },
  '411.0731': {
    getUniqueCaseInitialValues: getUniqueCaseInitialValues0731,
    validate: values =>
      errorsByRequiredFieldsList(Object.keys(getUniqueCaseInitialValues0731({})), values)
  },
  '411.072': {
    getUniqueCaseInitialValues: getUniqueCaseInitialValues072,
    validate: values =>
      errorsByRequiredFieldsList(Object.keys(getUniqueCaseInitialValues072({})), values)
  },
  '411.0726': {
    getUniqueCaseInitialValues: getUniqueCaseInitialValues0726,
    validate: values =>
      errorsByRequiredFieldsList(Object.keys(getUniqueCaseInitialValues0726({})), values)
  },
  '411.0727': {
    getUniqueCaseInitialValues: getUniqueCaseInitialValues0727,
    validate: values =>
      errorsByRequiredFieldsList(Object.keys(getUniqueCaseInitialValues0727({})), values)
  }
};
