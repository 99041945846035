import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setClientModal } from '../../actions/modals';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { ApplicationPageSectionWrapper } from './ApplicationPage';
import { HeaderWithEdit } from '../general/HeaderWithEdit';
import { SimpleListTable } from '../general/SimpleListTable';
import { friendlyNamesSelector } from '../../selectors/entities';

// Display name and SPN of client, as well as other basic information.
export const ClientInformationSection = () => {
  const {
    firstName,
    middleName,
    lastName,
    aliases,
    suffix,
    spn,
    ssnFour,
    idNumber,
    dob,
    streetAddress,
    streetAddress2,
    city,
    state,
    zipcode,
    email,
    phone,
    isVeteran
  } = useCurrentApplication();
  const friendlyNamesObj = useSelector(friendlyNamesSelector);
  const friendlyNames = friendlyNamesObj.editableAppFields;

  const fullName = `${firstName} ${middleName || ''} ${lastName} ${suffix || ''}`;
  const dispatch = useDispatch();

  const editButtonClick = () => {
    dispatch(setClientModal(true));
  };

  let isVeteranDisplay = '';
  if (isVeteran === true) {
    isVeteranDisplay = 'Y';
  } else if (isVeteran === false) {
    isVeteranDisplay = 'N';
  }

  return (
    <ApplicationPageSectionWrapper>
      <HeaderWithEdit onEditClick={editButtonClick}>Client information</HeaderWithEdit>
      <SimpleListTable
        headers={[
          'Name', // Full name display
          friendlyNames['aliases'],
          friendlyNames['spn'],
          friendlyNames['ssnFour'],
          friendlyNames['dob'],
          friendlyNames['isVeteran'],
          friendlyNames['idNumber'],
          'Address',
          '',
          '',
          '', // Simplified address display
          friendlyNames['email'],
          friendlyNames['phone']
        ]}
        values={[
          fullName,
          aliases,
          spn,
          ssnFour,
          moment(dob)
            .utc()
            .format('MM-DD-YYYY'),
          isVeteranDisplay,
          idNumber,
          streetAddress,
          streetAddress2,
          city,
          `${state || ''} ${zipcode || ''}`,
          email,
          phone
        ]}
      />
    </ApplicationPageSectionWrapper>
  );
};
