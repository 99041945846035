import { applyMiddleware, createStore, combineReducers } from 'redux';
import { entitiesReducer, queriesReducer, queryMiddleware } from 'redux-query';
import superagentInterface from 'redux-query-interface-superagent';
import thunk from 'redux-thunk';
import modalsReducer from './modals';
import generalReducer from './general';
import { CLEAR_STORE } from '../actions/general';
export const getQueries = state => state.queries;
export const getEntities = state => state.entities;

const reducer = combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
  modals: modalsReducer,
  general: generalReducer
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

const store = createStore(
  rootReducer,
  applyMiddleware(thunk, queryMiddleware(superagentInterface, getQueries, getEntities))
);

export default store;
