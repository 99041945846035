import React from 'react';
import styled from 'styled-components';
import ellipsesIcon from '../../assets/icons/ellipses.svg';

const Button = styled.button`
  padding: 0.5em;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  opacity: 0.7;
  cursor: pointer;
  > img {
    margin: 0px;
    width: 1em;
  }
  &:hover {
    opacity: 1;
  }
`;

// "..." button, used to show more about an item via a dropdown
export const EllipsesButton = ({ id, onClick }) => {
  return (
    <Button id={id} onClick={onClick} ariaLabel="Show more options">
      <img src={ellipsesIcon} alt="" />
    </Button>
  );
};
