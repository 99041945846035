import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationPageSectionWrapper } from '../applicationPage/ApplicationPage';
import { LinkLikeButton, SmallButton } from '../general/Button';
import { setCSVUploadModal } from '../../actions/modals';
import { bulkApplicationBatchesSelector } from '../../selectors/entities';
import { ApplicationBatchDisplay } from './ApplicationBatchDisplay';
import { useRequest } from 'redux-query-react';
import { getBatchHistoryQuery } from '../../actions/queries';
import { List, AutoSizer } from 'react-virtualized';
import { GREY_MEDIUM_LIGHT } from '../../constants/cssVars';
import { NoContentBox } from '../general/NoContentBox';
import { DotLoader } from '../Loaders';

const ListWrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: calc(100vh - 300px - ${({ additionalHeightOffset }) => additionalHeightOffset || 0}px);
  padding: 10px 15px;
  background-color: ${GREY_MEDIUM_LIGHT};
  border-radius: 10px;
`;

const NoScrollListWrapper = styled.div`
  position: relative;
  padding: 10px 15px;
  background-color: ${GREY_MEDIUM_LIGHT};
  border-radius: 10px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const sortByStartDate = (a, b) => {
  const startDateA = new Date(a.processingStartDate);
  const startDateB = new Date(b.processingStartDate);
  let comparison = 0;
  if (startDateA > startDateB) {
    comparison = -1;
  } else if (startDateA < startDateB) {
    comparison = 1;
  }
  return comparison;
};

// Admin page to bulk import SPNs
export const BulkImportSPNs = () => {
  const bulkApplicationBatches = useSelector(bulkApplicationBatchesSelector);
  const [{ isFinished }] = useRequest(getBatchHistoryQuery());
  const dispatch = useDispatch();

  if (!isFinished) {
    return <DotLoader />;
  }

  const sortedApplicationBatches = bulkApplicationBatches.sort(sortByStartDate);
  const inProgressBatches = sortedApplicationBatches.filter(
    batchObj => !batchObj.applicationCreationComplete
  );
  const finishedBatches = sortedApplicationBatches.filter(
    batchObj => batchObj.applicationCreationComplete
  );

  const rowRenderer = ({ index, style }) => {
    return (
      <ApplicationBatchDisplay
        style={style}
        bulkApplicationBatch={finishedBatches[index]}
        key={`app_batch_${index}`}
      />
    );
  };

  return (
    <ApplicationPageSectionWrapper>
      <h2>Bulk Upload SPNs</h2>
      <HeaderWrapper>
        <h3>In-progress uploads</h3>
        <SmallButton onClick={() => dispatch(setCSVUploadModal(true))}>Upload CSV +</SmallButton>
      </HeaderWrapper>
      {inProgressBatches.length > 0 ? (
        <NoScrollListWrapper>
          {inProgressBatches.map((batchObj, idx) => (
            <ApplicationBatchDisplay bulkApplicationBatch={batchObj} key={`ip_app_batch_${idx}`} />
          ))}
        </NoScrollListWrapper>
      ) : (
        <NoContentBox>
          No uploads are currently in progress.
          <div>
            <LinkLikeButton
              style={{ display: 'inline-block', fontWeight: 500, fontStyle: 'italic' }}
              onClick={() => dispatch(setCSVUploadModal(true))}
            >
              Click here
            </LinkLikeButton>{' '}
            to start a new bulk upload.
          </div>
        </NoContentBox>
      )}
      <h3>Past uploads ({finishedBatches.length})</h3>
      <ListWrapper>
        <AutoSizer>
          {({ width, height }) => (
            <List
              height={height}
              rowCount={finishedBatches.length}
              rowHeight={210}
              rowRenderer={rowRenderer}
              width={width}
            />
          )}
        </AutoSizer>
      </ListWrapper>
    </ApplicationPageSectionWrapper>
  );
};
