import { createSelector } from 'reselect';

export const activeDropdownSelector = state => state.general.activeDropdown;
export const allTableFiltersSelector = state => state.general.tableFilters;

const getSecondParam = (state, param2) => param2;

// select filters object for a given table
export const tableFiltersSelector = createSelector(
  [allTableFiltersSelector, getSecondParam],
  (allFilters, tableId) => allFilters[tableId] || {}
);
