import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../general/table/Table';
import { useRequest } from 'redux-query-react';
import { getUserOverviewQuery } from '../../actions/queries';
import { DotLoader } from '../Loaders';
import { reportsSelector } from '../../selectors/entities';
import { reportsFiltersModalInfoSelector } from '../../selectors/modals';
import { SmallTableWrapper } from './Reports';
import styled from 'styled-components';
import { DateSelectorSection } from './DateSelectorSection';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 30px;
  width: 900px;
  max-width: 100%;
  h3 {
    font-size: 1.15rem;
    margin: 0px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    span {
      margin-right: 0px;
    }
  }
`;

export const UserOverviewTable = ({ userId }) => {
  const reportsFilters = useSelector(reportsFiltersModalInfoSelector);
  const [{ isFinished }] = useRequest(getUserOverviewQuery({ ...reportsFilters, userId }));

  if (!isFinished) {
    return <DotLoader />;
  }

  return <TableContent />;
};

const TableContent = () => {
  const reports = useSelector(reportsSelector);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Application status',
        accessor: 'status'
      },
      {
        Header: 'Number of applications',
        accessor: 'count',
        rightAlignData: true
      }
    ],
    []
  );

  const data = React.useMemo(() => [...reports.userOverview], [reports.userOverview]);

  return (
    <>
      <Header>
        <h3>Status of assigned applications</h3>
        <DateSelectorSection />
      </Header>
      <SmallTableWrapper style={{ width: '900px' }}>
        <Table data={data} columns={columns} hideSearch={true} />
      </SmallTableWrapper>
    </>
  );
};
