import React from 'react';
import styled from 'styled-components';

const Table = styled.table`
  border-collapse: collapse;
  th,
  td {
    padding-right: ${({ rightPadding }) => rightPadding || 15}px;
  }
  > tbody > tr > th {
    text-align: left;
    font-weight: 600;
  }
`;

// Single table component for displaying vertical headers & values
// Headers and values must be arrays of the same length
export const SimpleListTable = ({ headers, values, rightPadding }) => {
  return (
    <Table rightPadding={rightPadding}>
      <tbody>
        {headers.map((header, idx) => {
          return (
            <tr key={`simple_tr_${header}_${idx}`}>
              <th>{header}</th>
              <td>{values[idx]}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
