import moment from 'moment';

export const statusIsGood = status => status >= 200 && status < 300;
export const statusIsUnauthorized = status => status === 401;

export const stringIsEmptyOrNull = str => str == null || str === '';

export const getSlugFromName = str =>
  str
    ? str
        .toLowerCase()
        .split(' ')
        .join('_')
    : null;

export const getLocalStorageItem = key => {
  try {
    const result = localStorage.getItem(key);
    return result;
  } catch {
    return null;
  }
};

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch {
    // since we only use this as a backup to in-memory state, don't need additional action on error
  }
};

// Connects two items by "&", and 3+ items by "__, __, ... __, & __"
export const prettyEnglishList = (strList, allLowerCase) => {
  if (!strList || strList.length === 0) {
    return null;
  }
  if (strList.length === 1) {
    return strList[0];
  }
  // make a new list where all but the first item are converted to lowercase
  const newList = strList.map((item, idx) => {
    if (idx === 0 && !allLowerCase) {
      return item;
    }
    return item.toLowerCase();
  });
  const last = newList.pop();
  return newList.join(', ') + ' & ' + last;
};

export const getRandomInt = max => Math.floor(Math.random() * max);

export const makeRandomId = length => {
  var result = '';
  var characters = '0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const sortByDate = (a, b, format) => {
  var a1 = moment(a, format || 'MM-DD-YYYY').valueOf();
  var b1 = moment(b, format || 'MM-DD-YYYY').valueOf();
  if (a1 < b1) {
    return 1;
  } else if (a1 > b1) {
    return -1;
  } else {
    return 0;
  }
};

// Capitalize the first letter of a string
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Given a string of any capitalization, first make all lowercase, then capitalize the first letters of each word in the string
// E.g. "BOOP" -> "Boop", "BOOP BOP" -> "Boop Bop", "heLLO WOrlD" -> "Hello World"
export const normalizeAndCapitalizeWords = str => {
  if (!str) {
    return '';
  }
  const lowercaseStrArr = str.toLowerCase().split(' ');
  return lowercaseStrArr.map(lowercaseStr => capitalizeFirstLetter(lowercaseStr)).join(' ');
};

// Given a user's admin, paralegal, attorney booleans, return a string with the name of their role
export const getUserTypeString = userObj => {
  return userObj.admin
    ? 'Admin'
    : userObj.attorney
    ? 'Attorney'
    : userObj.paralegal
    ? 'Paralegal'
    : 'Unassigned';
};

// Given a date object, convert it to UTC.
export const getMomentUTC = dateObj => {
  const momentObj = moment(dateObj);
  const year = momentObj.get('year');
  const month = momentObj.get('month');
  const date = momentObj.get('date');
  const momentObjUTC = moment.utc({ year, month, date });
  return momentObjUTC;
};

// Given an new object and a old object with the same properties, return the fields that have been updated
// "dateProperties" will standardize date if provided & dateProperties[fieldName].isDate is true
export const getFieldsChanged = (newObj, prevObj, dateProperties) => {
  if (dateProperties) {
    return getFieldsChanged(
      getDateConvertedValues(newObj, dateProperties),
      getDateConvertedValues(prevObj, dateProperties)
    );
  }
  return Object.keys(prevObj).reduce((fieldsChanged, fieldName) => {
    // Only add to the fieldsChanged object if the new value and old value differ
    if (newObj[fieldName] !== prevObj[fieldName]) {
      fieldsChanged[fieldName] = newObj[fieldName];
    }
    return fieldsChanged;
  }, {});
};

// Convert date fields to standardized format
export const getDateConvertedValues = (values, properties) =>
  Object.keys(values).reduce((newValues, fieldName) => {
    if ((properties[fieldName] || {}).isDate && values[fieldName]) {
      newValues[fieldName] = getMomentUTC(values[fieldName]).format('YYYY-MM-DD');
    } else {
      newValues[fieldName] = values[fieldName];
    }
    return newValues;
  }, {});

// Given an array of applications, return a CSV string that can be used for export
export const getFormattedCSVString = apps => {
  const flattenedAppDocs = [];

  const formatDate = dateStr => {
    const date = new Date(dateStr);
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date
      .getDate()
      .toString()
      .padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  apps.forEach(appDoc => {
    const formattedDob = appDoc.dob ? formatDate(appDoc.dob) : '';
    const formattedMiddleName =
      appDoc.firstName && appDoc.middleName ? ` ${appDoc.middleName}` : appDoc.middleName;
    const formattedName = `${appDoc.lastName}, ${appDoc.firstName || ''}${formattedMiddleName ||
      ''}`;
    const baseValues = {
      spn: appDoc.spn,
      name: formattedName,
      dob: formattedDob
    };
    if (appDoc.hcdcCases.length > 0) {
      appDoc.hcdcCases.forEach(hcdcCase => {
        const flattenedAppDoc = {
          ...baseValues,
          final_eligibility: hcdcCase.final_eligibility,
          waiting_period_end: hcdcCase.waiting_period_end,
          ndo_form: hcdcCase.ndo_form,
          casID: hcdcCase.casID,
          fda: hcdcCase.fda,
          misdemeanor_felony: hcdcCase.misdemeanor_felony,
          current_offense_name: hcdcCase.current_offense_name,
          same_criminal_episode_has_felony: hcdcCase.same_criminal_episode_has_felony
        };
        flattenedAppDocs.push(flattenedAppDoc);
      });
    } else {
      flattenedAppDocs.push(baseValues);
    }
  });

  const fields = [
    'spn',
    'name',
    'dob',
    'final_eligibility',
    'waiting_period_end',
    'ndo_form',
    'casID',
    'fda',
    'misdemeanor_felony',
    'current_offense_name',
    'same_criminal_episode_has_felony'
  ];
  const csvHeaderString = fields.join(',');
  const csvContentString = flattenedAppDocs
    .map(appDoc => fields.map(field => (appDoc[field] ? `"${appDoc[field]}"` : '')).join(','))
    .join('\n');
  const fullCsvString = [csvHeaderString, csvContentString].join('\n');
  return fullCsvString;
};
