import { setTableFilters } from '../actions/general';
import { getMomentUTC, stringIsEmptyOrNull } from './general';

// Generate applications query string based on current filters
// "returnAllRecords" will skip pagination and allow all records to be returned (for export)
export const getFiltersQueryString = (filters, params) => {
  const { returnAllRecords } = params || {};
  // initialize query string with page number and limit (will always be provided)
  let queryString = `pageNumber=${filters.pageNum}&pageLimit=${filters.pageLimit}`;
  if (returnAllRecords) {
    // A 0 page limit will return all records
    queryString = `pageNumber=1&pageLimit=0`;
  }
  // Add date filtering
  if (filters.customStart) {
    queryString =
      queryString + `&createdAtMin=${getMomentUTC(filters.customStart).format('YYYY-MM-DD')}`;
  }
  if (filters.customEnd) {
    queryString =
      queryString + `&createdAtMax=${getMomentUTC(filters.customEnd).format('YYYY-MM-DD')}`;
  }
  // Name filtering
  if (!stringIsEmptyOrNull(filters.firstName)) {
    queryString = queryString + `&firstName=${filters.firstName}`;
  }
  if (!stringIsEmptyOrNull(filters.middleName)) {
    queryString = queryString + `&middleName=${filters.middleName}`;
  }
  if (!stringIsEmptyOrNull(filters.lastName)) {
    queryString = queryString + `&lastName=${filters.lastName}`;
  }
  if (!stringIsEmptyOrNull(filters.suffix)) {
    queryString = queryString + `&suffix=${filters.suffix}`;
  }
  // Assignee filtering
  if (!stringIsEmptyOrNull(filters.assigneeId)) {
    queryString = queryString + `&assigneeId=${filters.assigneeId}`;
  }
  if (typeof filters.noAssignees === 'boolean') {
    queryString = queryString + `&noAssignees=${filters.noAssignees}`;
  }
  // Spn filtering
  if (!stringIsEmptyOrNull(filters.spn)) {
    queryString = queryString + `&spn=${filters.spn}`;
  }
  // Closed app filtering (exclusive)
  if (typeof filters.includeClosed === 'boolean') {
    queryString = queryString + `&filterOutClosed=${!filters.includeClosed}`;
  }
  // Bulk application batch filtering
  if (filters.bulkUploadInfo && !stringIsEmptyOrNull(filters.bulkUploadInfo.applicationBatchId)) {
    queryString = queryString + `&applicationBatchId=${filters.bulkUploadInfo.applicationBatchId}`;
  }
  // Sorting
  if (filters.sortBy) {
    queryString = queryString + `&sortBy=${filters.sortBy}&sortOrder=${filters.sortOrder}`;
  }
  // Inactive filtering
  if (typeof filters.showOnlyInactive === 'boolean') {
    queryString = queryString + `&showOnlyInactive=${filters.showOnlyInactive}`;
  }
  return queryString;
};

// When on a sorting column click, set the new column to sort by or toggle the sort order,
// & reset to the first page for pagination
export const updateTableSort = (dispatch, filters, tableId, columnId) => {
  // If column is already sorted on, toggle the sort order
  dispatch(
    setTableFilters(tableId, 'sortOrder', filters.sortBy === columnId ? filters.sortOrder * -1 : 1)
  );
  dispatch(setTableFilters(tableId, 'sortBy', columnId));
  dispatch(setTableFilters(tableId, 'pageNum', 1));
};
