import {
  FORM_ID_411_0726,
  FORM_ID_411_0727,
  FORM_ID_411_0728,
  FORM_ID_411_0731,
  FORM_ID_411_0736,
  NDO_FORM,
  FINAL_POT_ELIG_411_0727,
  FINAL_POT_ELIG_411_0728,
  FINAL_POT_ELIG_411_0731,
  FINAL_POT_ELIG_411_0736
} from '../constants/cases';

// Given a case, return what forms might apply to it that require user follow-up questions.
// Returns an array of form ids, e.g. ["411.0727", "411.0736"];
export const getFormsNeededForFollowUp = currCase => {
  const formIds = [];
  // Veteran's treatment court program (needs veteran & program answers)
  if (currCase[FINAL_POT_ELIG_411_0727]) {
    formIds.push(FORM_ID_411_0727);
  }
  // Victim of human trafficking (needs numerous situational answers)
  if (currCase[FINAL_POT_ELIG_411_0728]) {
    formIds.push(FORM_ID_411_0728);
  }
  // DWI conviction with probation (need BAC & ignition interlock answers)
  if (currCase[FINAL_POT_ELIG_411_0731]) {
    formIds.push(FORM_ID_411_0731);
  }
  // DWI conviction without probation (need BAC & ignition interlock answers)
  if (currCase[FINAL_POT_ELIG_411_0736]) {
    formIds.push(FORM_ID_411_0736);
  }

  // Only follow up on unflagged DWI cases if they're marked as eligible for nondisclosure
  if (currCase.final_eligibility === 'nondisclosure') {
    // DWI with Deferred Adjudication (needs BAC & commercial permit/license answers)
    if (currCase[NDO_FORM] === FORM_ID_411_0726) {
      formIds.push(FORM_ID_411_0726);
    }
    // DWIs with Conviction (need BAC & ignition interlock answers)
    if (currCase[NDO_FORM] === FORM_ID_411_0736) {
      formIds.push(FORM_ID_411_0736);
    }
    if (currCase[NDO_FORM] === FORM_ID_411_0731) {
      formIds.push(FORM_ID_411_0731);
    }
  }
  return formIds;
};

// Given a case, return the NDO form ID we should recommend
// If any flags are set to true, return the flag form ID, otherwise return the "ndo_form" field
export const getRecommendedFormIdForCase = currCase => {
  // Check special case flags
  if (currCase[FINAL_POT_ELIG_411_0727]) {
    return FORM_ID_411_0727;
  }
  if (currCase[FINAL_POT_ELIG_411_0728]) {
    return FORM_ID_411_0728;
  }
  if (currCase[FINAL_POT_ELIG_411_0731]) {
    return FORM_ID_411_0731;
  }
  if (currCase[FINAL_POT_ELIG_411_0736]) {
    return FORM_ID_411_0736;
  }

  return currCase[NDO_FORM];
};

// Convert plain string options to be in { label, value } format
export const createSelectOptions = options =>
  options.map(option => ({ label: option, value: option }));
