import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usersSelector } from '../../selectors/entities';
import { Table } from '../general/table/Table';
import moment from 'moment';
import { SmallButton } from '../general/Button';
import { setNewUserModal } from '../../actions/modals';
import { getUserTypeString } from '../../utils/general';

// Admin page for user viewing & management
export const ViewUsersPage = () => {
  return (
    <>
      <h2>Users</h2>
      <UsersTable />
    </>
  );
};

// Table component of all users
const UsersTable = () => {
  const users = useSelector(usersSelector);
  const dispatch = useDispatch();

  // Array to be to our table as the main data
  const data = React.useMemo(
    () =>
      users.map(userObj => {
        return {
          email: userObj.username,
          fullName: `${userObj.firstName} ${userObj.lastName}`,
          role: getUserTypeString(userObj),
          dateCreated: moment(userObj.dateCreated).format('MM-DD-YYYY'),
          dateLastLogin: moment(userObj.dateLastLogin).format('MM-DD-YYYY'),
          userId: userObj._id
        };
      }),
    [users]
  );

  // Array to be passed to our table as the columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Name',
        accessor: 'fullName'
      },
      {
        Header: 'Role',
        accessor: 'role'
      },
      {
        Header: 'Date added',
        accessor: 'dateCreated'
      },
      {
        Header: 'Last login',
        accessor: 'dateLastLogin'
      }
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      searchRight={
        <SmallButton onClick={() => dispatch(setNewUserModal(true))}>Add new user +</SmallButton>
      }
      getLink={row => `/users/${row.original.userId}`}
      searchPlaceholder="Search users ..."
    />
  );
};
