// Session expiration time (for localStorage)
export const SESSION_EXP_STORAGE_KEY = 'clean_record_session_expiration_time';

export const ALL_APPLICATIONS_TABLE_ID = 'all_applications';
export const YOUR_READY_APPLICATIONS_TABLE_ID = 'your_ready_applications';
export const OTHER_ASSIGNED_APPLICATIONS_TABLE_ID = 'other_assigned_applications';

// Filter parameters
export const YOUR_READY_APPS_TABLE_PARAMS = { assignedToUser: true, readyApps: true };
export const OTHER_ASSIGNED_APPS_TABLE_PARAMS = { assignedToUser: true };

// Order for sorting eligibility in case tables (eligible cases first)
export const ELIGIBILITY_SORT_ORDER = [
  'nondisclosure',
  'expunction',
  'expunction after SOL',
  'ineligible'
];
