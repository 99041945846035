import React from 'react';
import styled from 'styled-components';
import { GREY_MEDIUM, SHADOW_CRISP } from '../../constants/cssVars';

const WIDTH = 180;
const PADDING = 5;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  transition: 0s;
  div {
    visibility: hidden;
  }
  &:hover div {
    visibility: visible;
    transition-delay: ${props => props.delay || '0.5s'};
  }
`;

const TopTooltipContent = styled.div`
  visibility: hidden;
  width: ${WIDTH}px;
  background-color: #fff;
  font-size: 1em;
  font-weight: 500;
  border: 1px solid ${GREY_MEDIUM};
  box-shadow: ${SHADOW_CRISP};
  text-align: center;
  border-radius: 6px;
  padding: ${PADDING}px;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 50%;
  margin-left: -${WIDTH / 2 + PADDING}px;
  :after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -4px;
    border-width: 4px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`;

const BottomTooltipContent = styled(TopTooltipContent)`
  right: 130%;
  bottom: 50%;
  margin-bottom: -${WIDTH / 2 + PADDING}px;
  :after {
    display: none;
  }
`;

// Displays a tooltip when someone hovers over the "children" of this component.
// Pass the code (e.g. an icon) that should be used to trigger the tooltip on hover as the children of the Tooltip.
// Pass the content that should show in the tooltip as the "content" prop.
export const Tooltip = ({ content, style, delay, children, displayBottom }) => {
  const TooltipContent = displayBottom ? BottomTooltipContent : TopTooltipContent;
  return (
    <TooltipWrapper delay={delay}>
      {children}
      <TooltipContent style={style}>{content}</TooltipContent>
    </TooltipWrapper>
  );
};
