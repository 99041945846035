import React from 'react';
import { useRequest } from 'redux-query-react';
import styled from 'styled-components';
import { List } from 'react-virtualized';
import {
  GREY_DARK,
  GREY_LIGHTER,
  GREY_MEDIUM_LIGHT,
  PRIMARY,
  SHADOW_CRISP
} from '../../constants/cssVars';
import { useInterval } from '../../hooks/useInterval';
import moment from 'moment';
import { getBatchQuery } from '../../actions/queries';
import { UserDisplay } from '../UserDisplay';
import { useCSVDownload } from '../../hooks/useCSVDownload';
import { LinkLikeButton } from '../general/Button';
import { useDispatch } from 'react-redux';
import { setTableFilters, resetTableFilters } from '../../actions/general';
import { useHistory } from 'react-router-dom';
import { ALL_APPLICATIONS_TABLE_ID } from '../../constants/general';
import { useBulkSpnCSVQuery } from '../../hooks/simpleRequests';

const ListWrapper = styled.div`
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  width: 100%;
  overflow: hidden;
`;

const Row = styled.div`
  background-color: ${({ index }) => (index % 2 === 0 ? GREY_LIGHTER : '#fff')};
  font-size: 0.8em;
  padding: 3px 10px;
`;

// Display rows of a bulk application batch in a virtualized list
export const BulkApplicationsList = ({ bulkApplicationBatch }) => {
  const rows = bulkApplicationBatch.rows;

  if (!Array.isArray(rows)) {
    return null;
  }

  const rowRenderer = ({ index, style }) => {
    const row = rows[index];
    const { spn, first_name, last_name, phone, email } = row;
    return (
      <Row style={style} index={index} key={`address_tile_${index}`}>
        {spn}, {first_name} {last_name}, {phone}, {email}
      </Row>
    );
  };

  return (
    <ListWrapper>
      <List
        height={200}
        rowCount={rows.length}
        rowHeight={30}
        rowRenderer={rowRenderer}
        width={550}
      />
    </ListWrapper>
  );
};

const BatchDisplayWrapper = styled.div`
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: ${SHADOW_CRISP};
  > div:first-child {
    display: flex;
    justify-content: space-between;
  }
  h3,
  p {
    margin: 0px;
  }
  margin-bottom: 10px;
`;

const BatchDisplayHeader = styled.div`
  display: flex;
  > h3 {
    padding-right: 8px;
  }
`;

const CSVLoadingText = styled.div`
  font-style: italic;
  font-weight: 600;
  color: ${GREY_DARK};
  padding-top: 3px;
  padding-left: 5px;
`;

const rowErrorStyle = {
  color: '#b80b0b',
  fontWeight: 600
};

const STATUS_CHECK_INTERVAL_MS = 3000;

// Download button for viewing row errors in CSV format
const DownloadErrorsCSV = ({ rowErrors }) => {
  const csvHeaderString = 'row_number,error_message';
  const csvContentString = rowErrors.map(errorObj =>
    [errorObj.rowNumber, errorObj.error].join(',')
  );
  const fullCsvString = [csvHeaderString, csvContentString].join('\n');
  const downloadCSV = useCSVDownload();
  return (
    <LinkLikeButton
      style={{ fontSize: '0.95em', display: 'inline-block', color: '#b80b0b' }}
      onClick={() => downloadCSV(fullCsvString, 'bulk_upload_errors.csv')}
    >
      (download errors CSV)
    </LinkLikeButton>
  );
};

// Displays status of an application batch, start/end time, & list of rows
export const ApplicationBatchDisplay = ({ bulkApplicationBatch, style }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const applicationCreationComplete = bulkApplicationBatch.applicationCreationComplete;
  const rowErrors = Array.isArray(bulkApplicationBatch.rowErrors)
    ? bulkApplicationBatch.rowErrors
    : [];
  const isExportOnly = bulkApplicationBatch.exportUrl != null;
  const downloadCSV = useCSVDownload();
  const [fetchBulkSpnCSV, fetchPending] = useBulkSpnCSVQuery(({ text }) => {
    downloadCSV(text, bulkApplicationBatch.exportFilename);
  });

  // only query for status if application creation is not complete
  const [{ isFinished }, refresh] = useRequest(
    applicationCreationComplete
      ? null
      : getBatchQuery({ batchId: bulkApplicationBatch._id, showToastOnCompletion: true })
  );

  // query for status on interval while application creation is not complete
  useInterval(() => {
    if (isFinished && !applicationCreationComplete) {
      refresh();
    }
  }, STATUS_CHECK_INTERVAL_MS);

  // Number of no-error rows
  const numCompletedApps = bulkApplicationBatch.rowCount - rowErrors.length;

  const handleViewInTable = () => {
    // Clear existing filters on all apps table so the bulk upload filter will search all applications (except closed, but they could check the box)
    dispatch(resetTableFilters(ALL_APPLICATIONS_TABLE_ID));
    dispatch(
      setTableFilters(ALL_APPLICATIONS_TABLE_ID, 'bulkUploadInfo', {
        uploadStartDate: bulkApplicationBatch.processingStartDate,
        applicationBatchId: bulkApplicationBatch._id,
        filename: bulkApplicationBatch.filename
      })
    );
    history.push('/');
  };

  return (
    <div style={style}>
      <BatchDisplayWrapper>
        <div>
          <BatchDisplayHeader>
            {applicationCreationComplete ? (
              <h3>
                {isExportOnly
                  ? 'Generated export-only CSV'
                  : `Added ${numCompletedApps} application${numCompletedApps === 1 ? '' : 's'}`}
              </h3>
            ) : (
              <h3>Processing upload ...</h3>
            )}
            {/* Once finished, show "view in taable" or "download export" action button, depending on whether this was an export-only upload */}
            {applicationCreationComplete && numCompletedApps > 0 && (
              <>
                {isExportOnly ? (
                  fetchPending ? (
                    <CSVLoadingText>loading ...</CSVLoadingText>
                  ) : (
                    <LinkLikeButton
                      onClick={() =>
                        fetchBulkSpnCSV({ filename: bulkApplicationBatch.exportFilename })
                      }
                      style={{ fontStyle: 'italic' }}
                    >
                      download export
                    </LinkLikeButton>
                  )
                ) : (
                  <LinkLikeButton onClick={handleViewInTable} style={{ fontStyle: 'italic' }}>
                    view in table
                  </LinkLikeButton>
                )}
              </>
            )}
          </BatchDisplayHeader>
          <p style={{ color: PRIMARY }}>
            {applicationCreationComplete ? 'Finished' : 'In progress'}
          </p>
        </div>
        <div>
          <div style={{ marginBottom: '5px', color: GREY_DARK, fontStyle: 'italic' }}>
            From "{bulkApplicationBatch.filename}"
          </div>
          <div>
            Run by <UserDisplay userObj={bulkApplicationBatch.user} displayAsText={true} />
          </div>
          <div>
            Started on{' '}
            {moment(bulkApplicationBatch.processingStartDate).format('MM-DD-YYYY, hh:mm a')}
          </div>
          <div>
            {applicationCreationComplete
              ? `Ended on ${moment(bulkApplicationBatch.processingEndDate).format(
                  'MM-DD-YYYY, hh:mm a'
                )}`
              : 'In progress ...'}
          </div>
          <div>Total rows in CSV: {bulkApplicationBatch.rowCount}</div>
          <div style={rowErrors.length > 0 ? rowErrorStyle : null}>
            Rows with errors:{' '}
            {applicationCreationComplete ? (
              rowErrors.length > 0 ? (
                <span>
                  {rowErrors.length} <DownloadErrorsCSV rowErrors={rowErrors} />
                </span>
              ) : (
                '0'
              )
            ) : (
              '0'
            )}
          </div>
        </div>
        <BulkApplicationsList bulkApplicationBatch={bulkApplicationBatch} />
      </BatchDisplayWrapper>
    </div>
  );
};
