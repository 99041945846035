import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setDocumentUploadModal } from '../../actions/modals';
import { GREY_DARK, GREY_LIGHTEST, GREY_MEDIUM_LIGHT, SHADOW_CRISP } from '../../constants/cssVars';
import { useRemoveDocument } from '../../hooks/simpleRequests';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { useDocumentDownload } from '../../hooks/useDocumentDownload';
import { usersSelector } from '../../selectors/entities';
import { LinkLikeButton, SmallButton } from '../general/Button';
import { Dropdown } from '../general/Dropdown';
import { EllipsesButton } from '../general/EllipsesButton';
import { NoContentBox } from '../general/NoContentBox';
import { UserDisplay } from '../UserDisplay';
import { ApplicationPageSectionWrapper } from './ApplicationPage';

// Formatting to display all uploads, with each row containing an upload's filename & date uploaded
const UploadsDisplayWrapper = styled.div`
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: ${SHADOW_CRISP};
  > div {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    padding-right: 10px;
    border-top: 1px solid ${GREY_MEDIUM_LIGHT};
    > div {
      display: flex;
      align-items: center;
      button {
        margin-left: 0.5em;
      }
    }
  }
  > div:first-child {
    border-top: none;
    font-weight: 700;
    color: ${GREY_DARK};
    background-color: ${GREY_LIGHTEST};
    border-radius: 8px 8px 0px 0px;
    padding-right: 3.5em;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const UploadsDisplay = () => {
  const [removeDocument] = useRemoveDocument();
  const downloadDocument = useDocumentDownload();
  const application = useCurrentApplication();
  const users = useSelector(usersSelector);
  // List of s3 urls for uploaded documents
  const uploads = application.uploads || [];

  // if there are no uploads, display a "no uploads" message
  if (uploads.length === 0) {
    return <NoContentBox>No documents have been uploaded</NoContentBox>;
  }

  // Sort the uploads by time stamp such that the most recent upload is at the top
  const sortedUploads = uploads.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));

  // Otherwise, show a table-like display of uploads
  return (
    <UploadsDisplayWrapper>
      <div>
        <div>Filename</div>
        <div>
          <div>Upload Date</div>
          <div style={{ margin: '0px 20px 0px 30px' }}>User</div>
        </div>
      </div>
      {sortedUploads.map((uploadObj, idx) => {
        return (
          <div key={`uploaded_document_${idx}`}>
            <LinkLikeButton onClick={() => downloadDocument(uploadObj.filename)}>
              {uploadObj.filename}
            </LinkLikeButton>
            <div>
              <div>{moment(uploadObj.uploadDate).format('MM-DD-YYYY, hh:mm a')}</div>
              <div style={{ margin: '0px 20px' }}>
                <UserDisplay userObj={uploadObj.user} />
              </div>
              <Dropdown
                ButtonComponent={EllipsesButton}
                dropdownId={`dropdown_${uploadObj.filename}`}
                style={{ top: '1.2em', minWidth: '200px' }}
              >
                <LinkLikeButton
                  onClick={() => {
                    removeDocument({
                      uploadId: uploadObj._id,
                      appId: application._id
                    });
                  }}
                >
                  Delete this file
                </LinkLikeButton>
              </Dropdown>
            </div>
          </div>
        );
      })}
    </UploadsDisplayWrapper>
  );
};

// Display current documents associated with this application
export const DocumentsSection = () => {
  const dispatch = useDispatch();

  return (
    <ApplicationPageSectionWrapper>
      <HeaderWrapper>
        <h3>Documents</h3>
        <SmallButton onClick={() => dispatch(setDocumentUploadModal(true))}>
          Upload document +
        </SmallButton>
      </HeaderWrapper>
      <UploadsDisplay />
    </ApplicationPageSectionWrapper>
  );
};
