import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseModal } from '../../actions/modals';
import { closeModalInfoSelector, closeModalSelector } from '../../selectors/modals';
import { Modal, ModalFooter, ModalHeader, ModalContent } from '../general/Modal';
import { Button, InvertedButton } from '../general/Button';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { FieldWrapper, ManualSelectField, OptionsList } from '../FormFields';
import {
  useCloseAppQuery,
  useUpdateExpunctionProgress,
  useUpdateNondisclosureProgress
} from '../../hooks/simpleRequests';

const Wrapper = styled.div`
  margin: 20px 0px;
  padding-bottom: 10px;
  max-width: 400px;
`;

const OTHER = 'Other';

const appClosedReasons = [
  'No eligible cases',
  'Unresponsive client',
  'Duplicate application',
  OTHER
];

const expunctionClosedReasons = [OTHER];

const nondisclosureClosedReasons = [OTHER];

// Allows user to manually close an application, nondisclosure case, or expunction-set
export const CloseModal = () => {
  const application = useCurrentApplication();
  const showModal = useSelector(closeModalSelector);
  const { caseId, isExpunction } = useSelector(closeModalInfoSelector);
  const dispatch = useDispatch();
  // form variables
  const [reason, setReason] = useState('');
  const [reasonOtherInput, setReasonOtherInput] = useState('');
  let reasonError = reason === '' ? 'Required' : null;
  let otherInputError = null;
  let reasonToSubmit = reason;
  if (reason === OTHER) {
    // if "other" is selected, make the reason to submit the other input (instead of "other")
    reasonToSubmit = reasonOtherInput;
    otherInputError = reasonOtherInput === '' ? 'Required' : null;
  }
  const appId = application._id;

  // When closing the modal, also clear the inputs
  const onCancel = () => {
    dispatch(setCloseModal(false));
    setReason('');
    setReasonOtherInput('');
  };

  // Application closing values
  let title = 'Close application';
  let text = (
    <p>
      Once an application is closed, it will no longer be shown in the default applications list.
      Closed applications may still be viewed by selecting the{' '}
      <em>"Include closed applications"</em> filter.
    </p>
  );
  let reasonOptions = appClosedReasons;
  let closeValues = { appId, closedReason: reasonToSubmit };
  let btnText = 'Close application';
  let useCloseQuery = useCloseAppQuery;

  // If a caseId is specified, use nondisclosure closing values
  if (caseId != null) {
    title = 'Close nondisclosure case';
    text = <p>Please select a reason for closing work on this case:</p>;
    reasonOptions = nondisclosureClosedReasons;
    closeValues = {
      appId,
      nondisclosureClosedReason: reasonToSubmit,
      caseId,
      statusChanged: { nondisclosureClosed: true }
    };
    btnText = 'Close case';
    useCloseQuery = useUpdateNondisclosureProgress;
  }

  // If a isExpunction is true, use expunction closing values
  if (isExpunction) {
    title = 'Close expunction section';
    text = <p>Please select a reason for closing work on expunction cases:</p>;
    reasonOptions = expunctionClosedReasons;
    closeValues = {
      appId,
      expunctionClosedReason: reasonToSubmit,
      statusChanged: { expunctionClosed: true }
    };
    btnText = 'Close expunction section';
    useCloseQuery = useUpdateExpunctionProgress;
  }

  const [handleClose, closePending] = useCloseQuery(onCancel);

  if (!showModal) {
    return null;
  }

  return (
    <Modal onClose={onCancel}>
      <ModalHeader>{title}</ModalHeader>
      <ModalContent>
        {text}
        <Wrapper>
          <ManualSelectField
            onChange={e => setReason(e.target.value)}
            value={reason}
            label={`Reason for closing*`}
            error={reasonError}
          >
            <OptionsList options={reasonOptions.map(name => ({ label: name, value: name }))} />
          </ManualSelectField>
          {reason === OTHER && (
            <FieldWrapper label="Please briefly explain closing reason*" error={otherInputError}>
              <input onChange={e => setReasonOtherInput(e.target.value)} value={reasonOtherInput} />
            </FieldWrapper>
          )}
        </Wrapper>
      </ModalContent>
      <ModalFooter>
        <InvertedButton onClick={onCancel}>Cancel</InvertedButton>
        <Button
          onClick={() => handleClose(closeValues)}
          isLoading={closePending}
          disabled={reasonError != null || otherInputError != null}
        >
          {btnText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
