export const CLEAR_STORE = 'CLEAR_STORE';
export const SET_ACTIVE_DROPDOWN = 'SET_ACTIVE_DROPDOWN';
export const SET_TABLE_FILTERS = 'SET_TABLE_FILTERS';
export const BULK_SET_TABLE_FILTERS = 'BULK_SET_TABLE_FILTERS';
export const RESET_TABLE_FILTERS = 'RESET_TABLE_FILTERS';
export const MARK_AS_REFRESHED = 'MARK_AS_REFRESHED';

export const setActiveDropdown = dropdownId => {
  return { type: SET_ACTIVE_DROPDOWN, payload: dropdownId };
};

// Tells the root reducer to completely clear the redux store, used for 401 encounters
export const clearStore = () => ({ type: CLEAR_STORE, payload: true });

// Update a specific filter for a given table to a specific value
export const setTableFilters = (tableId, filterId, filterValue) => {
  return { type: SET_TABLE_FILTERS, payload: { tableId, filterId, filterValue } };
};

// Update multiple filters at once for a given table
export const bulkSetTableFilters = (tableId, filterValues) => {
  return { type: BULK_SET_TABLE_FILTERS, payload: { tableId, filterValues } };
};

// Resets (clears) all filters for a given table
export const resetTableFilters = tableId => {
  return { type: RESET_TABLE_FILTERS, payload: { tableId } };
};

// Mark filters for table as refreshed after app refresh is complete
export const markFiltersAsRefreshed = tableId => {
  return { type: MARK_AS_REFRESHED, payload: { tableId } };
};
