import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { setAddCaseModal, setCaseModal } from '../../actions/modals';
import { GREY_DARK } from '../../constants/cssVars';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { usePrint } from '../../hooks/usePrint';
import { SmallButton } from '../general/Button';
import { CasesTable } from '../general/CasesTable';
import { PrintButton } from '../general/PrintButton';
import { ApplicationPageSectionWrapper } from './ApplicationPage';

const HiddenPrintingContainer = styled.div`
  display: none;
  @media print {
    font-family: 'Arial', sans-serif;
  }
`;

const PRINT_CONTAINER_ID = 'printable-application-cases';

// Display a table of the client's current cases with their calculated eligibility
export const CasesSection = () => {
  const application = useCurrentApplication();
  const dispatch = useDispatch();

  // Get print function, adding some extra spacing for the offense column (2nd column)
  const handlePrint = usePrint(
    PRINT_CONTAINER_ID,
    `${application.firstName} ${application.lastName} Cases`,
    'td:nth-child(3) { min-width: 200px; } td, th { padding: 10px 10px 10px 0px; }'
  );

  return (
    <ApplicationPageSectionWrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>Cases</h3>
        <PrintButton onClick={handlePrint} text="Print cases" />
      </div>
      <CasesTable
        searchRight={
          <SmallButton onClick={() => dispatch(setAddCaseModal(true))}>Add new case +</SmallButton>
        }
        cases={application.hcdcCases}
        setModal={setCaseModal}
      />
      <p style={{ fontStyle: 'italic', color: GREY_DARK, textAlign: 'center' }}>
        This application was created on {moment(application.createdAt).format('MMMM Do, YYYY')}. If
        pulling new records is necessary, please create a new application for this client. Note that
        at the time this application was created, our Harris County District Clerk data only
        contained records occurring between <strong>{application.hcdcCasesStartDate}</strong> and{' '}
        <strong>{application.hcdcCasesEndDate}</strong>. Cases outside this date range and cases
        outside of Harris County will not be initially populated & are not considered for initial
        eligibility calculations.{' '}
        {new Date(application.dob) <= new Date('1973-01-01') && (
          <>
            Based on date of birth,{' '}
            <strong>this client may have cases before January 1st, 1990</strong>.
          </>
        )}
      </p>

      {/* Hidden section used to format simplified view for printing */}
      <HiddenPrintingContainer id={PRINT_CONTAINER_ID}>
        <div>
          <h3>Cases for Application {application._id}</h3>
          <div>
            {application.firstName} {application.lastName}
          </div>
          <p>Document created on {new Date().toLocaleDateString()}</p>
        </div>
        <hr />
        <CasesTable printView={true} cases={application.hcdcCases} />
      </HiddenPrintingContainer>
    </ApplicationPageSectionWrapper>
  );
};
