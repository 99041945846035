import { createSelector } from 'reselect';
import {
  ALL_APPLICATIONS_TABLE_ID,
  OTHER_ASSIGNED_APPLICATIONS_TABLE_ID,
  YOUR_READY_APPLICATIONS_TABLE_ID
} from '../constants/general';

// This is where updated Redux state from queries go
export const sessionExpirationTimeSelector = state => state.entities.expirationTime;
export const applicationTablesSelector = state => state.entities.applicationTables || {};
export const currentApplicationSelector = state => state.entities.currentApplication;
export const currentUserSelector = state => state.entities.currentUser || {};
export const usersSelector = state => state.entities.users || [];
export const friendlyNamesSelector = state => state.entities.friendlyNames || {};
export const lookupEndDateSelector = state => state.entities.lookupEndDate;
export const docusignLegalServicesAgreementVersionSelector = state =>
  state.entities.docusignLegalServicesAgreementVersion;
export const docusignExpunctionVersionSelector = state => state.entities.docusignExpunctionVersion;
export const docusignNondisclosureVersionSelector = state =>
  state.entities.docusignNondisclosureVersion;
export const arrestingAgencyAddressesSelector = state =>
  state.entities.arrestingAgencyAddresses || {};
export const forensicAgencyAddressesSelector = state =>
  state.entities.forensicAgencyAddresses || {};
export const spnLookupCasesSelector = state => state.entities.spnLookupCases;
export const spnLookupStringSelector = state => state.entities.spnLookupString;
export const bulkApplicationBatchesSelector = state => state.entities.bulkApplicationBatches || [];
export const exportSelector = state => state.entities.export;
export const reportsSelector = state => state.entities.reports || {};

export const allApplicationsSelector = createSelector(
  [applicationTablesSelector],
  applicationTables => applicationTables[ALL_APPLICATIONS_TABLE_ID] || {}
);

export const yourReadyApplicationSelector = createSelector(
  [applicationTablesSelector],
  applicationTables => applicationTables[YOUR_READY_APPLICATIONS_TABLE_ID] || {}
);

export const otherAssignedApplicationSelector = createSelector(
  [applicationTablesSelector],
  applicationTables => applicationTables[OTHER_ASSIGNED_APPLICATIONS_TABLE_ID] || {}
);
