import React, { useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentUserSelector,
  otherAssignedApplicationSelector,
  yourReadyApplicationSelector
} from '../selectors/entities';
import { ManualTable } from './general/table/ManualTable';
import { UserDisplay } from './UserDisplay';
import { stringIsEmptyOrNull } from '../utils/general';
import { getFiltersQueryString, updateTableSort } from '../utils/apps';
import { LinkLikeButton, SmallButton } from './general/Button';
import { markFiltersAsRefreshed, resetTableFilters, setTableFilters } from '../actions/general';
import { tableFiltersSelector } from '../selectors/general';
import { setOpenApplicationAssignmentModal, setTableFiltersModal } from '../actions/modals';
import {
  YOUR_READY_APPLICATIONS_TABLE_ID,
  OTHER_ASSIGNED_APPLICATIONS_TABLE_ID,
  OTHER_ASSIGNED_APPS_TABLE_PARAMS,
  YOUR_READY_APPS_TABLE_PARAMS
} from '../constants/general';
import { useApplicationsQuery } from '../hooks/simpleRequests';
import { GREY_DARK } from '../constants/cssVars';

const MoreFiltersAndReset = styled.div`
  display: flex;
  button:first-child {
    margin-right: 10px;
  }
  span {
    padding-left: 10px;
    color: ${GREY_DARK};
    font-style: italic;
  }
`;

// shared styling for table title & subtitle
const HeaderSection = ({ title, subtitle }) => (
  <>
    <h2 style={{ marginBottom: '0px' }}>{title}</h2>
    <p
      style={{
        fontWeight: 500,
        color: GREY_DARK,
        marginTop: '5px',
        marginBottom: '15px',
        fontStyle: 'italic'
      }}
    >
      {subtitle}
    </p>
  </>
);

// Page that displays all applications in a tabular, searchable format
// Example design available at https://www.figma.com/file/W5aA3U2WToOz39i0Jcl1fW/Clean-Slate-PDO?node-id=0%3A484
export const YourApplications = () => {
  return (
    <>
      <ReadyApplicationsTable />
      <OtherAssignedApplicationsTable />
    </>
  );
};

const getRows = applications => {
  return (applications || []).map(applicationObj => {
    // Just grab the pieces of the application we need for the table
    const { assigneeAttorney, assigneeParalegal, activityLog, _id } = applicationObj;
    return {
      firstName: `${applicationObj.firstName} ${applicationObj.lastName}`, // actually whole name, but setting ID to "firstName" for sort purposes
      spn: applicationObj.spn,
      closed: applicationObj.closed,
      status: applicationObj.status,
      lastUpdated: moment(activityLog.at(-1) && activityLog.at(-1).activityDate).format(
        'MM-DD-YY h:mma'
      ),
      createdAt: moment(applicationObj.createdAt).format('MM-DD-YY'),
      assignees: (
        <div>
          <UserDisplay
            userObj={assigneeAttorney}
            extraTooltipContent="Attorney"
            style={{ marginRight: '5px' }}
          />
          <UserDisplay userObj={assigneeParalegal} extraTooltipContent="Paralegal" />
        </div>
      ),
      _id,
      applicationBatchId: applicationObj.applicationBatchId
    };
  });
};

// Array to be passed to our table as the columns
const columns = [
  {
    Header: 'Client Name',
    accessor: 'firstName'
  },
  {
    Header: 'Client SPN',
    accessor: 'spn'
  },
  {
    Header: 'Assignees',
    accessor: 'assignees',
    allowOverflow: true, // Make overflow visible, so we can show the tooltip for the user circle
    disableSort: true
  },
  {
    Header: 'Date created',
    accessor: 'createdAt'
  },
  {
    Header: 'Stage',
    accessor: 'status'
  }
];

// List of assigned applications that are ready to be worked on
const ReadyApplicationsTable = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);

  // For paralegals, pop up a modal to get paired attorney assignment before checking open applications
  let handleAssignToNewAppClick = () => dispatch(setOpenApplicationAssignmentModal(true));

  return (
    <div>
      <HeaderSection
        title="Ready applications"
        subtitle="Applications that are assigned to you and are ready for you to make progress on."
      />
      <SmallApplicationsTable
        tableId={YOUR_READY_APPLICATIONS_TABLE_ID}
        selector={yourReadyApplicationSelector}
        params={YOUR_READY_APPS_TABLE_PARAMS}
        topRight={
          currentUser.paralegal && (
            <SmallButton onClick={handleAssignToNewAppClick}>
              Assign me to a new application +
            </SmallButton>
          )
        }
      />
    </div>
  );
};

// List of other assigned applications
const OtherAssignedApplicationsTable = () => {
  return (
    <div style={{ marginTop: '10px' }}>
      <HeaderSection
        title="Other assigned applications"
        subtitle="All other applications assigned to you, with tasks currently waiting on other users."
      />
      <SmallApplicationsTable
        tableId={OTHER_ASSIGNED_APPLICATIONS_TABLE_ID}
        selector={otherAssignedApplicationSelector}
        params={OTHER_ASSIGNED_APPS_TABLE_PARAMS}
      />
    </div>
  );
};

// Shared component for actual table, since the display is very similar
const SmallApplicationsTable = ({ tableId, selector, params, topRight }) => {
  const dispatch = useDispatch();
  const [getApplications, applicationsLoading] = useApplicationsQuery(() =>
    dispatch(markFiltersAsRefreshed(tableId))
  );

  // Grab the applications we fetched from our redux state
  const { applications, totalPages, totalDocuments, appsRefresh } = useSelector(selector);
  const filters = useSelector(state => tableFiltersSelector(state, tableId));

  // Refresh the applications if "filters.refresh" or "appsRefresh" is true (set when a change in table results is expected)
  useEffect(() => {
    if (typeof appsRefresh !== 'boolean' || appsRefresh === true || filters.refresh) {
      const queryString = getFiltersQueryString(filters, params);
      getApplications({ queryString, ...params, tableId });
    }
  }, [appsRefresh, filters.refresh]);

  // Reset all filters
  const resetFilters = () => dispatch(resetTableFilters(tableId));

  // Calculate active table filters, used to display active filter count and whether "reset filters" has any effect
  const nameSearchIsActive = ['firstName', 'lastName', 'middleName', 'suffix'].some(
    key => !stringIsEmptyOrNull(filters[key])
  );
  const spnSearchIsActive = !stringIsEmptyOrNull(filters['spn']);
  const activeFilterBooleans = [
    filters.includeClosed,
    !filters.allDates,
    nameSearchIsActive,
    spnSearchIsActive,
    filters.bulkUploadInfo,
    filters.showOnlyInactive,
    filters.assigneeId,
    filters.noAssignees
  ];
  const numActiveFilters = activeFilterBooleans.filter(isActive => isActive).length;

  return (
    <ManualTable
      isLoading={applicationsLoading}
      rows={getRows(applications)}
      columns={columns}
      topRight={topRight}
      topLeft={
        <MoreFiltersAndReset>
          <SmallButton onClick={() => dispatch(setTableFiltersModal(true, tableId))}>
            Search & filter
          </SmallButton>
          <LinkLikeButton disabled={numActiveFilters === 0} onClick={resetFilters}>
            Reset filters
          </LinkLikeButton>
          {numActiveFilters > 0 && (
            <span>
              {numActiveFilters} filter{numActiveFilters === 1 ? ' is' : 's are'} currently active
            </span>
          )}
        </MoreFiltersAndReset>
      }
      pagination={{
        pageNum: filters.pageNum,
        setPageNum: newPageNum => dispatch(setTableFilters(tableId, 'pageNum', newPageNum)),
        totalPages,
        totalDocuments
      }}
      sorting={{
        sortBy: filters.sortBy,
        sortOrder: filters.sortOrder,
        setSort: columnId => updateTableSort(dispatch, filters, tableId, columnId)
      }}
      globalFilter={filters.globalFilter}
      getLink={row => `/application/${row._id}`}
    />
  );
};
