import React, { useEffect } from 'react';
import styled from 'styled-components';
import { setActivityLogModal, setCloseModal, setDeleteAppModal } from '../../actions/modals';
import { useHistory, useParams } from 'react-router-dom';
import { FullWidthPage } from '../general/Common';
import { CasesSection } from './CasesSection';
import { AssigneeSection } from './AssigneeSection';
import { ClientInformationSection } from './ClientInformationSection';
import { NotesSection } from './NotesSection';
import { DocumentsSection } from './DocumentsSection';
import { ProgressSection } from './ProgressSection';
import { ProgressStickyFooter } from './ProgressStickyFooter';
import { ClientInfoModal } from './ClientInfoModal';
import { DeleteNoteModal } from './DeleteNoteModal';
import { CaseModal } from './CaseModal';
import { LinkLikeButton } from '../general/Button';
import { DocumentUploadModal } from './DocumentUploadModal';
import { AddNoteModal } from './AddNoteModal';
import { EditNoteModal } from './EditNoteModal';
import { TaskModal } from './TaskModal';
import { EditAssigneesModal } from './EditAssigneesModal';
import { AddCaseModal } from './AddCaseModal';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityLogModal } from './ActivityLogModal';
import { DocusignFieldsModal } from './docusignForms/DocusignFieldsModal';
import { ExpunctionCaseFieldsModal } from './docusignForms/ExpunctionCaseFieldsModal';
import { ERROR, GREY_DARK, MAX_DESKTOP_WIDTH } from '../../constants/cssVars';
import { DeleteAppModal } from './DeleteAppModal';
import { useRequest } from 'redux-query-react';
import { arrestingAgencyAddressesQuery } from '../../actions/queries';
import { currentUserSelector, currentApplicationSelector } from '../../selectors/entities';
import { singleApplicationQuery } from '../../actions/queries';
import { PageLoader } from '../Loaders';
import { CloseModal } from './CloseModal';
import { useReopenAppQuery } from '../../hooks/simpleRequests';
import moment from 'moment';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  h2 {
    margin-top: 0px;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  > div:first-child {
    display: flex;
    align-items: flex-start;
    > span {
      color: ${GREY_DARK};
      font-style: italic;
    }
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    flex-direction: column;
    align-items: flex-start;
    > div {
      align-items: flex-start;
    }
  }
`;

// Application page! This page contains ...
// - Information about the applicant (name, DOB, contact info, etc.)
// - Names of the assigned attorney and paralegal
// - The applicant's case information
// - Documents related to the application
// - Notes about the application
// - Progress tracking for the application & corresponding filings
// See design: https://www.figma.com/file/W5aA3U2WToOz39i0Jcl1fW/Clean-Slate-PDO?node-id=0%3A1
export const ApplicationPage = () => {
  // Grab the applicationId from the current URL
  const { applicationId } = useParams();
  // Redux-query will try to not re-query if a fetch has been made for an application
  // However, since the fetch sets our currentApplication entity, we want to always refresh!
  const forceRefresh = true;
  const [{ isFinished }] = useRequest(singleApplicationQuery(applicationId, forceRefresh));
  const [{ isFinished: agenciesFinished }] = useRequest(arrestingAgencyAddressesQuery());
  const history = useHistory();
  const dispatch = useDispatch();
  const { admin } = useSelector(currentUserSelector);
  const application = useSelector(currentApplicationSelector);
  const [reopenApp, reopenAppPending] = useReopenAppQuery();

  // redirect to main when no application is found
  useEffect(() => {
    if (isFinished && !application) {
      history.push('/');
    }
  }, [application, isFinished]);

  if (!isFinished || !agenciesFinished) {
    return <PageLoader />;
  }

  if (!application) {
    return null;
  }

  const appClosedByCompletion = application.closedReason === 'completed';

  return (
    <>
      <ClientInfoModal />
      <CaseModal />
      <AddCaseModal />
      <DocumentUploadModal />
      <AddNoteModal />
      <EditNoteModal />
      <DeleteNoteModal />
      <TaskModal />
      <ActivityLogModal />
      <CloseModal />
      <DocusignFieldsModal />
      <ExpunctionCaseFieldsModal />
      <EditAssigneesModal />
      <DeleteAppModal />
      <FullWidthPage>
        <LinkLikeButton onClick={() => history.goBack()}>← Back</LinkLikeButton>
        <Header>
          <div>
            <h2 style={{ marginBottom: '5px' }}>Application {applicationId}</h2>
            <span>
              Application created on {moment(application.createdAt).format('MMMM Do, YYYY')}
            </span>
            {application.closed && (
              <span>
                Application {appClosedByCompletion ? 'completed' : 'closed'} on{' '}
                {moment(application.closedDate).format('YYYY-MM-DD')}{' '}
                {!appClosedByCompletion && `for reason "${application.closedReason}"`}
              </span>
            )}
          </div>
          <div>
            <LinkLikeButton onClick={() => dispatch(setActivityLogModal(true))}>
              Show activity history
            </LinkLikeButton>
            {application.closed ? (
              <LinkLikeButton
                isLoading={reopenAppPending}
                onClick={() => reopenApp({ appId: applicationId })}
              >
                Re-open application
              </LinkLikeButton>
            ) : (
              <LinkLikeButton
                style={{ color: ERROR }}
                onClick={() => dispatch(setCloseModal(true))}
              >
                Close application
              </LinkLikeButton>
            )}
            {admin && (
              <LinkLikeButton
                style={{ color: ERROR }}
                onClick={() => dispatch(setDeleteAppModal(true))}
              >
                Delete application
              </LinkLikeButton>
            )}
          </div>
        </Header>
        <AssigneeSection />
        <ClientInformationSection />
        <CasesSection />
        <DocumentsSection />
        <NotesSection />
        <ProgressSection />
        <ProgressStickyFooter />
      </FullWidthPage>
    </>
  );
};

// To be used to wrap application page sections for consistent spacing
export const ApplicationPageSectionWrapper = styled.div`
  > h3 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
`;
