import { useProgressListObjs } from './useProgressListObjs';

// Given a task id & case id, return the full task object & corresponding context.
// Context currently includes a "taskContextStr" detailing the task's position in its parent list
export const useTaskWithContext = (taskId, caseId) => {
  const progressListObjs = useProgressListObjs();

  let matchingTaskObj = null;
  let taskContextStr = null;

  // Look for a matching task in the current application's progress list objects
  progressListObjs.forEach(progressListObj => {
    const matchingTaskIndex = progressListObj.taskList.findIndex(taskObj => {
      const taskMatch = taskObj.id === taskId;
      if (caseId == null) {
        return taskMatch;
      }
      return taskMatch && caseId === taskObj.caseId;
    });
    if (matchingTaskIndex > -1) {
      matchingTaskObj = progressListObj.taskList[matchingTaskIndex];
      // Information about where the task is in the current list
      taskContextStr = `${progressListObj.displayName} - Task ${matchingTaskIndex + 1} of ${
        progressListObj.taskList.length
      }`;
    }
  });

  return {
    taskObj: matchingTaskObj,
    taskContextStr
  };
};
