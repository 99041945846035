import styled from 'styled-components';
import printIcon from '../../assets/icons/print.svg';
import { PRIMARY } from '../../constants/cssVars';

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: 600;
  color: ${PRIMARY};
  text-decoration: underline;
  cursor: pointer;
  > img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
`;

export const PrintButton = ({ onClick, text }) => {
  return (
    <StyledButton onClick={onClick}>
      <img src={printIcon} alt="" />
      {text || 'Print'}
    </StyledButton>
  );
};
