import React from 'react';
import { ApplicationPageSectionWrapper } from './ApplicationPage';
import { SmallButton } from '../general/Button';
import styled from 'styled-components';
import { GREY_MEDIUM_LIGHT, SHADOW_MEDIUM } from '../../constants/cssVars';
import { useDispatch, useSelector } from 'react-redux';
import { setEditNoteModal, setAddNoteModal, setDeleteNoteModal } from '../../actions/modals';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import moment from 'moment';
import editIcon from '../../assets/icons/edit.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import { currentUserSelector } from '../../selectors/entities';
import { NoContentBox } from '../general/NoContentBox';

const TileScroller = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-height: 500px;
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 5px 15px;
  background-color: ${GREY_MEDIUM_LIGHT};
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  border-radius: 10px;
`;

const Tile = styled.div`
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  border-radius: 8px;
  box-shadow: ${SHADOW_MEDIUM};
  padding: 20px 20px;

  margin: 10px 0px;
  white-space: pre-line;
  background-color: #fff;

  > button {
    width: 40px;
    height: 30px;
    float: right;
    background-color: transparent;
    border: none;
  }

  > button > img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    border: 1px solid ${GREY_MEDIUM_LIGHT};
  }
`;

const NotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Display a list of all notes associated with the application
// Allow user to add new notes
export const NotesSection = () => {
  // Probably will do a separate query for notes here & select
  const dispatch = useDispatch();
  const application = useCurrentApplication();
  const currentUser = useSelector(currentUserSelector);

  // Array of notes to be added after the button
  const notes = application.notes || [];

  // Sort the notes by time stamp
  const sortedNotes = notes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <ApplicationPageSectionWrapper>
      <NotesHeader>
        {sortedNotes.length === 0 ? <h3>Notes</h3> : <h3>Notes ({sortedNotes.length})</h3>}
        <SmallButton onClick={() => dispatch(setAddNoteModal(true))}>Add New Note</SmallButton>
      </NotesHeader>

      {sortedNotes.length === 0 ? (
        <NoContentBox>No notes have been added yet for this application</NoContentBox>
      ) : (
        <TileScroller>
          {sortedNotes.map(noteObj => {
            const { _id, user, createdAt, note } = noteObj;
            const timeStampDisplay = moment(createdAt).format('MM-DD-YYYY, h:mm a');
            return (
              <Tile key={_id}>
                <time>
                  {timeStampDisplay} -- {`${user.firstName} ${user.lastName}`}
                </time>
                <button
                  hidden={currentUser._id !== user._id}
                  onClick={() => dispatch(setDeleteNoteModal(true, noteObj))}
                >
                  <img src={deleteIcon} alt="Delete Note" />
                </button>
                <button
                  hidden={currentUser._id !== user._id}
                  onClick={() => dispatch(setEditNoteModal(true, noteObj))}
                >
                  <img src={editIcon} alt="Edit Note" />
                </button>
                <p>{note}</p>
              </Tile>
            );
          })}
        </TileScroller>
      )}
    </ApplicationPageSectionWrapper>
  );
};
