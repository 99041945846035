import { useCurrentApplication } from './useCurrentApplication';

// Returns array of case ids & case object ids for the current application that are eligible for expunction
export const useExpunctionCaseIds = () => {
  const application = useCurrentApplication();
  const hcdcCases = application.hcdcCases || [];

  return hcdcCases
    .filter(caseObj => caseObj.final_eligibility === 'expunction')
    .map(caseObj => ({
      casID: caseObj.casID,
      caseId: caseObj._id
    }));
};
