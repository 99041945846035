import React from 'react';
import { CASE_FIELD_PROPERTIES } from '../../constants/cases';
import { Formik } from 'formik';
import {
  SelectField,
  TextField,
  OptionsList,
  DatepickerField,
  MultiFieldLine
} from '../FormFields';
import { Button, InvertedButton } from '../general/Button';
import { ModalFooter, ModalHeader, ScrollingModalContent } from '../general/Modal';
import { useUpdateCaseInfo } from '../../hooks/simpleRequests';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { useSelector } from 'react-redux';
import { friendlyNamesSelector } from '../../selectors/entities';
import { getFieldsChanged } from '../../utils/general';
import moment from 'moment';
import { toast } from 'react-toastify';
import { createSelectOptions } from '../../utils/cases';
import { caseFieldsToSkip } from './CaseInformation';
import {
  AGENCY_FIELD_NAMES,
  AgencyFields,
  removeOtherInputs,
  useAgencyInitialValues
} from './AgencyFields';

// Form for updating an existing case
export const UpdateCaseForm = ({ caseObj, onCancel }) => {
  const application = useCurrentApplication();
  const friendlyNames = useSelector(friendlyNamesSelector);
  const caseFieldFriendlyNames = friendlyNames.editableCaseFields;
  const agencyInitialValues = useAgencyInitialValues(caseObj || {});

  // Get the function to call on submit (updateCase), the "in-progress" boolean (updatingCase)
  // & pass "onClose" to be callled once the submit function is complete
  const [updateCase, updatingCase] = useUpdateCaseInfo(onCancel);

  // Only show the fields that are in the caseFieldFriendlyNames object, & convert dates as necessary
  const baseInitialFields = Object.keys(caseFieldFriendlyNames).reduce((formData, fieldName) => {
    formData[fieldName] = caseObj[fieldName];
    if ((CASE_FIELD_PROPERTIES[fieldName] || {}).isDate && caseObj[fieldName]) {
      const newDate = moment(
        moment(caseObj[fieldName])
          .utc()
          .format('YYYY-MM-DD')
      ).utc();
      formData[fieldName] = newDate.toDate();
    }
    return formData;
  }, {});

  // create initial fields with base fields + arresting agency fields
  const initialFields = {
    ...baseInitialFields,
    ...agencyInitialValues
  };

  const submit = values => {
    // Get previous and new values to compare (removing 'other' inputs for agency names)
    let newValues = removeOtherInputs(values);
    let prevValues = removeOtherInputs(initialFields);

    // override "arresting_agency" with "other" input if needed
    if (values.arresting_agency === 'other') {
      newValues.arresting_agency = values.arrestingAgencyOtherInput;
    }
    // override "forensicAgencyName" with "other" input if needed
    if (values.forensicAgencyName === 'other') {
      newValues.forensicAgencyName = values.forensicAgencyOtherInput;
    }

    // get fields changed with standardized dates
    const fieldsChanged = getFieldsChanged(newValues, { ...prevValues }, CASE_FIELD_PROPERTIES);
    // Only send a query if at least one field has changed
    if (Object.keys(fieldsChanged).length > 0) {
      updateCase({ fieldsChanged, appId: application._id, caseId: caseObj._id });
    } else {
      toast.info('No changes have been made');
    }
  };

  return (
    <>
      <ModalHeader>Edit Case Information</ModalHeader>
      <CaseForm
        submit={submit}
        isLoading={updatingCase}
        initialFields={initialFields}
        onCancel={onCancel}
      />
    </>
  );
};

// Base form for editing case information
export const CaseForm = ({ submit, isLoading, initialFields, onCancel }) => {
  const friendlyNames = useSelector(friendlyNamesSelector);
  const caseFieldFriendlyNames = friendlyNames.editableCaseFields;
  const caseFields = Object.keys(caseFieldFriendlyNames).filter(
    fieldName => !caseFieldsToSkip.includes(fieldName)
  );

  return (
    <Formik initialValues={initialFields} onSubmit={submit}>
      {({ handleSubmit, errors, touched, submitCount, values, setFieldValue, setFieldTouched }) => {
        const getError = name => (touched[name] || submitCount >= 1) && errors[name];

        return (
          <>
            <ScrollingModalContent height={430} heightOffset={250}>
              <MultiFieldLine>
                {caseFields
                  .filter(fieldName => !AGENCY_FIELD_NAMES.includes(fieldName))
                  .map(fieldName => {
                    const { options, optionsProps, isDate, isNumeric } =
                      CASE_FIELD_PROPERTIES[fieldName] || {};
                    const friendlyName = caseFieldFriendlyNames[fieldName];
                    if (Array.isArray(options) && options.length > 0) {
                      return (
                        <SelectField
                          short={1}
                          key={`case-form-${fieldName}`}
                          label={friendlyName}
                          name={fieldName}
                          error={getError(fieldName)}
                        >
                          <OptionsList
                            {...(optionsProps || {})}
                            options={options[0].value ? options : createSelectOptions(options)}
                          />
                        </SelectField>
                      );
                    }
                    if (isDate) {
                      return (
                        <DatepickerField
                          key={`case-form-${fieldName}`}
                          short={1}
                          label={friendlyName}
                          name={fieldName}
                          error={getError(fieldName)}
                        />
                      );
                    }
                    return (
                      <TextField
                        short={1}
                        type={isNumeric ? 'number' : 'text'}
                        key={`case-form-${fieldName}`}
                        label={friendlyName}
                        name={fieldName}
                        error={getError(fieldName)}
                      />
                    );
                  })}
                <AgencyFields />
              </MultiFieldLine>
            </ScrollingModalContent>
            <ModalFooter>
              <InvertedButton onClick={onCancel}>Cancel</InvertedButton>
              <Button isLoading={isLoading} onClick={handleSubmit} type="button">
                Submit
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
