import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewUserModal } from '../../actions/modals';
import { newUserModalSelector } from '../../selectors/modals';
import { Modal, ModalFooter, ModalHeader, ModalContent } from '../general/Modal';
import { Formik } from 'formik';
import { MultiFieldLine, OptionsList, SelectField, TextField } from '../FormFields';
import { Button, InvertedButton } from '../general/Button';
import { validateNewUser } from '../../validators';
import { useAddNewUser } from '../../hooks/simpleRequests';

// Modal to add a new user
export const NewUserModal = () => {
  const showModal = useSelector(newUserModalSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setNewUserModal(false));
  };

  // Get the function to call on submit (updateClient), the "in-progress" boolean (addNewUserPending)
  // & pass "onClose" to be callled once the submit function is complete
  const [addNewUser, addNewUserPending] = useAddNewUser(onClose);

  if (!showModal) {
    return null;
  }

  const submit = values => {
    // We want to also pass a "username" property set to the user's email,
    // and change the "userType" property into separate "attorney", "paralegal", and "admin" properties
    addNewUser({
      ...values,
      username: values.email,
      attorney: values.userType === 'attorney',
      paralegal: values.userType === 'paralegal',
      admin: values.userType === 'admin'
    });
  };

  return (
    <Modal onClose={onClose}>
      <Formik
        validate={validateNewUser}
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          userType: ''
        }}
        onSubmit={submit}
      >
        {({ handleSubmit, errors, touched, values, submitCount }) => {
          const getError = name => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <>
              <ModalHeader>Add new user</ModalHeader>
              <ModalContent>
                <p>This will send an invitation to the email submitted below.</p>
                <MultiFieldLine columns="48% 48%">
                  <TextField label="Email" name="email" error={getError('email')} />
                  <TextField label="First name" name="firstName" error={getError('firstName')} />
                </MultiFieldLine>
                <MultiFieldLine columns="48% 48%">
                  <TextField label="Last name" name="lastName" error={getError('lastName')} />
                  <SelectField label="Role" name="userType" error={getError('userType')}>
                    <OptionsList
                      options={[
                        { label: 'Attorney', value: 'attorney' },
                        { label: 'Paralegal', value: 'paralegal' },
                        { label: 'Admin', value: 'admin' }
                      ]}
                    />
                  </SelectField>
                </MultiFieldLine>
              </ModalContent>
              <ModalFooter>
                <InvertedButton onClick={onClose}>Cancel</InvertedButton>
                <Button
                  style={{ minWidth: '200px' }}
                  isLoading={addNewUserPending}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
