import React, { useState } from 'react';
import styled from 'styled-components';
import {
  FINAL_POT_ELIG_411_0731,
  FINAL_POT_ELIG_411_0736,
  FORM_ID_411_0726,
  FORM_ID_411_0727,
  FORM_ID_411_0728,
  FORM_ID_411_0731,
  FORM_ID_411_0736
} from '../../constants/cases.js';
import { SHADOW_CRISP } from '../../constants/cssVars';
import { getFormsNeededForFollowUp } from '../../utils/cases';
import { LinkLikeButton } from '../general/Button.js';

const MessageWrapper = styled.div`
  border-radius: 5px;
  padding: 8px;
  background-color: #f5e6a5;
  color: #4a3d0a;
  box-shadow: ${SHADOW_CRISP};
  > p {
    font-weight: 600;
    margin: 0px;
  }
  margin-bottom: 15px;
`;

const FormFollowup = styled.div`
  > div {
    margin-top: 10px;
    > ul {
      padding: 0px;
      margin: 0px;
      padding-left: 20px;
    }
  }
`;

// For ineligible cases that have "potentially eligible" flags marked,
// display a short message outlining the special conditions that would make an applicant eligible for the flagged NDO forms.
export const SpecialConditionsMessage = ({ caseObj }) => {
  // Toggle whether or not to show entire text
  const [showFullText, setShowFullText] = useState(false);
  const { final_eligibility } = caseObj;
  const formIds = getFormsNeededForFollowUp(caseObj);

  // We only want to show this message if the case is ineligible & conditions apply
  if (final_eligibility !== 'ineligible' || formIds.length < 1) {
    return null;
  }

  return (
    <MessageWrapper>
      <p>
        May be eligible for nondisclosure if special conditions apply.
        <LinkLikeButton
          style={{ color: '#695916', fontSize: '1em' }}
          onClick={() => setShowFullText(!showFullText)}
        >
          {showFullText ? 'Hide conditions' : 'View conditions'}
        </LinkLikeButton>
      </p>
      {showFullText && (
        <FormFollowup>
          {formIds.includes(FORM_ID_411_0728) && (
            <div>
              Eligible for nondisclosure under <b>411.0728</b> if ...
              <ul>
                <li>
                  Committed this offense solely as a victim of human trafficking or compelling
                  prostitution
                </li>
                <li>
                  Did not commit this offense after submitting a petition for nondisclosure for a
                  related offense
                </li>
              </ul>
            </div>
          )}
          {formIds.includes(FORM_ID_411_0727) && (
            <div>
              Eligible for nondisclosure under <b>411.0727</b> if ...
              <ul>
                <li>
                  Applicant has completed a <b>Veterans Treatment Court program</b> for this case
                </li>
              </ul>
            </div>
          )}
          {formIds.includes(FORM_ID_411_0731) && (
            <div>
              Eligible for nondisclosure under <b>411.0731</b> if ...
              <ul>
                <li>
                  Applicant's <b>blood alcohol concentration</b> (BAC) level less than <b>0.15</b>{' '}
                  at the time of this offense
                </li>
                {caseObj[FINAL_POT_ELIG_411_0731] && (
                  <li>
                    Applicant used an ignition interlock device on their vehicle for at least 6
                    months
                  </li>
                )}
              </ul>
            </div>
          )}
          {formIds.includes(FORM_ID_411_0736) && (
            <div>
              Eligible for nondisclosure under <b>411.0736</b> if ...
              <ul>
                <li>
                  Applicant's <b>blood alcohol concentration</b> (BAC) level less than <b>0.15</b>{' '}
                  at the time of this offense
                </li>
                {caseObj[FINAL_POT_ELIG_411_0736] && (
                  <li>
                    Applicant used an ignition interlock device on their vehicle for at least 6
                    months
                  </li>
                )}
              </ul>
            </div>
          )}
          {formIds.includes(FORM_ID_411_0726) && (
            <div>
              Eligible for nondisclosure under <b>411.0726</b> if ...
              <ul>
                <li>
                  Applicant's <b>blood alcohol concentration</b> (BAC) level less than <b>0.15</b>{' '}
                  at the time of this offense
                </li>
                <li>
                  Applicant did NOT hold a <b>commercial</b> driver's license or a <b>commercial</b>{' '}
                  learner's permit at the time of this offense
                </li>
              </ul>
            </div>
          )}
        </FormFollowup>
      )}
    </MessageWrapper>
  );
};
