import React from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT, PRIMARY_DARK } from '../constants/cssVars';
import { useLogout } from '../hooks/useLogout';
import { LinkLikeButton } from './general/Button';
import { useSelector, useDispatch } from 'react-redux';
import { currentUserSelector } from '../selectors/entities';
import { Dropdown } from './general/Dropdown';
import { UserDisplay } from './UserDisplay';
import { StyledInternalLink } from './general/Common';
import { setActiveDropdown } from '../actions/general';

const HeaderWrapper = styled.div`
  height: ${HEADER_HEIGHT}px;
  color: ${PRIMARY_DARK};
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px 20px;
`;

const AccountButton = ({ id, onClick }) => {
  const currentUser = useSelector(currentUserSelector);
  return <UserDisplay userObj={currentUser} id={id} onClick={onClick} hideTooltip={true} />;
};

const accountButtonStyle = { top: '40px', right: '10px', minWidth: '150px' };

export const Header = () => {
  const currentUser = useSelector(currentUserSelector);
  const logout = useLogout();
  const dispatch = useDispatch();

  return (
    <header>
      <HeaderWrapper>
        <Dropdown
          ButtonComponent={AccountButton}
          dropdownId="headerUserDropdown"
          style={accountButtonStyle}
        >
          <div>Settings</div>
          <StyledInternalLink
            to={`/users/${currentUser._id}`}
            onClick={() => dispatch(setActiveDropdown(null))}
          >
            Profile
          </StyledInternalLink>
          <LinkLikeButton onClick={logout}>Logout</LinkLikeButton>
        </Dropdown>
      </HeaderWrapper>
    </header>
  );
};
