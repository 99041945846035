import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDeleteAppModal } from '../../actions/modals';
import { deleteAppModalSelector } from '../../selectors/modals';
import { Modal, ModalContent, ModalFooter, ModalHeader } from '../general/Modal';
import { Button, InvertedButton } from '../general/Button';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { ERROR } from '../../constants/cssVars';
import { useDeleteApplicationQuery } from '../../hooks/simpleRequests';
import { useHistory } from 'react-router-dom';

// Have user confirm that they want to delete an application
export const DeleteAppModal = () => {
  const application = useCurrentApplication();
  const showModal = useSelector(deleteAppModalSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const onClose = () => {
    dispatch(setDeleteAppModal(false));
  };

  const [submit, submitting] = useDeleteApplicationQuery(() => {
    // when the query finishes successfully, redirect & close the modal
    history.push('/');
    onClose();
  });
  const handleDeleteClick = () => {
    submit({ appId: application._id });
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader>Delete application confirmation</ModalHeader>
      <ModalContent style={{ marginBottom: '20px' }}>
        Are you sure you want to delete this application?
      </ModalContent>
      <ModalFooter>
        <InvertedButton onClick={onClose}>Cancel</InvertedButton>
        <Button
          style={{ backgroundColor: ERROR }}
          onClick={handleDeleteClick}
          isLoading={submitting}
        >
          Yes, delete this application
        </Button>
      </ModalFooter>
    </Modal>
  );
};
