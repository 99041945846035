import React from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../general/Modal';
import { Button, InvertedButton } from '../../general/Button';
import { useSendLegalServicesEnvelopeQuery } from '../../../hooks/simpleRequests';
import { useCurrentApplication } from '../../../hooks/useCurrentApplication';
import styled from 'styled-components';
import { GREY_MEDIUM_DARK } from '../../../constants/cssVars';

const FooterNote = styled.p`
  text-align: right;
  margin: 0px;
  color: ${GREY_MEDIUM_DARK};
  font-size: 0.9em;
  font-style: italic;
`;

export const LegalServicesPreview = ({ onClose, onEditFieldsClick, previewUrl }) => {
  const [sendEnvelope, sendingEnvelope] = useSendLegalServicesEnvelopeQuery(onClose);
  const application = useCurrentApplication();
  const envelopeId = application.legalAgreementEnvelopeId;
  return (
    <>
      <ModalHeader>Preview legal services agreement</ModalHeader>
      <ModalContent>
        <p style={{ marginTop: '0px', fontSize: '0.95em' }}>
          This is what the legal services agreement will look like to the client who receives it.
        </p>
        <iframe width="100%" height="420" src={previewUrl} title="docusign preview" />
      </ModalContent>
      <FooterNote>
        Form will be sent to <strong>{application.email}</strong> &{' '}
        <strong>{application.assigneeAttorney.email}</strong> for signing.
      </FooterNote>
      <FooterNote>
        A copy of the completed document will be sent to{' '}
        <strong>{application.assigneeParalegal.email}</strong>.
      </FooterNote>
      <ModalFooter style={{ marginTop: '10px' }}>
        <InvertedButton style={{ minWidth: '100px' }} onClick={onClose}>
          Cancel
        </InvertedButton>
        <InvertedButton
          style={{ minWidth: '100px', marginRight: '10px' }}
          onClick={onEditFieldsClick}
        >
          Edit fields
        </InvertedButton>
        <Button
          style={{ minWidth: '100px' }}
          onClick={() => sendEnvelope({ envelopeId, appId: application._id })}
          isLoading={sendingEnvelope}
        >
          Send form
        </Button>
      </ModalFooter>{' '}
    </>
  );
};
