import React, { useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { allApplicationsSelector } from '../selectors/entities';
import { ManualTable } from './general/table/ManualTable';
import { UserDisplay } from './UserDisplay';
import { getFormattedCSVString, stringIsEmptyOrNull } from '../utils/general';
import { getFiltersQueryString, updateTableSort } from '../utils/apps';
import { DotLoader } from './Loaders';
import { LinkLikeButton, SmallButton } from './general/Button';
import { useCSVDownload } from '../hooks/useCSVDownload';
import { markFiltersAsRefreshed, resetTableFilters, setTableFilters } from '../actions/general';
import { tableFiltersSelector } from '../selectors/general';
import { setTableFiltersModal } from '../actions/modals';
import { TableBanner } from './general/table/TableBanner';
import { ALL_APPLICATIONS_TABLE_ID } from '../constants/general';
import { useApplicationsQuery, useApplicationsExportQuery } from '../hooks/simpleRequests';
import { ExportButton } from './general/table/ExportButton';
import { GREY_DARK } from '../constants/cssVars';

const MoreFiltersAndReset = styled.div`
  display: flex;
  button:first-child {
    margin-right: 10px;
  }
  span {
    padding-left: 10px;
    color: ${GREY_DARK};
    font-style: italic;
  }
`;

// Page that displays all applications in a tabular, searchable format
// Example design available at https://www.figma.com/file/W5aA3U2WToOz39i0Jcl1fW/Clean-Slate-PDO?node-id=0%3A484
export const AllApplications = () => {
  const downloadCSV = useCSVDownload();
  const dispatch = useDispatch();

  const [getApplications, applicationsLoading] = useApplicationsQuery(() =>
    dispatch(markFiltersAsRefreshed(ALL_APPLICATIONS_TABLE_ID))
  );
  const [getApplicationsExport, exportLoading] = useApplicationsExportQuery(resp => {
    // when we get the application export response, generate a CSV from it
    const appsToExport = resp.applications;
    downloadCSV(getFormattedCSVString(appsToExport), 'application_export.csv');
  });
  const isFinished = true;
  // Grab the applications we fetched from our redux state
  const { applications, totalPages, totalDocuments, appsRefresh } = useSelector(
    allApplicationsSelector
  );
  const filters = useSelector(state => tableFiltersSelector(state, ALL_APPLICATIONS_TABLE_ID));

  // Refresh the applications if "filters.refresh" or "appsRefresh" is true (set when a change in table results is expected)
  useEffect(() => {
    if (typeof appsRefresh !== 'boolean' || appsRefresh === true || filters.refresh) {
      const queryString = getFiltersQueryString(filters);
      getApplications({ queryString, tableId: ALL_APPLICATIONS_TABLE_ID });
    }
  }, [appsRefresh, filters.refresh]);

  const handleExportClick = () => {
    // generate all-records query string
    const queryString = getFiltersQueryString(filters, { returnAllRecords: true });
    getApplicationsExport(queryString);
  };

  // Array to be to our table as the main data
  const rows = (applications || []).map(applicationObj => {
    // Just grab the pieces of the application we need for the table
    const { assigneeAttorney, assigneeParalegal, hcdcCases, activityLog, _id } = applicationObj;
    return {
      firstName: `${applicationObj.firstName} ${applicationObj.lastName}`, // actually whole name, but setting ID to "firstName" for sort purposes
      spn: applicationObj.spn,
      closed: applicationObj.closed,
      expunctionCount: hcdcCases.filter(caseObj => caseObj.final_eligibility === 'expunction')
        .length,
      nondisclosureCount: hcdcCases.filter(caseObj => caseObj.final_eligibility === 'nondisclosure')
        .length,
      // since "expunction after SOL" is also an eligibililty type, determining number ineligible cases by not "expunction" and not "nondisclosure" (instead of just equaling "ineligible")
      ineligibleCount: hcdcCases.filter(
        caseObj =>
          caseObj.final_eligibility !== 'expunction' &&
          caseObj.final_eligibility !== 'nondisclosure'
      ).length,
      status: applicationObj.status,
      lastUpdated: moment(activityLog.at(-1) && activityLog.at(-1).activityDate).format(
        'MM-DD-YY h:mma'
      ),
      createdAt: moment(applicationObj.createdAt).format('MM-DD-YY'),
      assignees: (
        <div>
          <UserDisplay
            userObj={assigneeAttorney}
            extraTooltipContent="Attorney"
            style={{ marginRight: '5px' }}
          />
          <UserDisplay userObj={assigneeParalegal} extraTooltipContent="Paralegal" />
        </div>
      ),
      _id,
      applicationBatchId: applicationObj.applicationBatchId
    };
  });

  // Array to be passed to our table as the columns
  const columns = [
    {
      Header: 'Client Name',
      accessor: 'firstName'
    },
    {
      Header: 'Client SPN',
      accessor: 'spn',
      style: { minWidth: '100px' }
    },
    {
      Header: 'Assignees',
      accessor: 'assignees',
      allowOverflow: true, // Make overflow visible, so we can show the tooltip for the user circle
      disableSort: true,
      style: { minWidth: '100px' }
    },
    {
      Header: 'Date created',
      accessor: 'createdAt'
    },
    {
      Header: 'Stage',
      accessor: 'status'
    },
    {
      Header: '# Expunc.',
      accessor: 'expunctionCount',
      centerData: true, // Since the table data (just a number) is going to be small compared to the header, center the text instead of left-align
      style: { minWidth: '80px' }
    },
    {
      Header: '# Nondisc.',
      accessor: 'nondisclosureCount',
      centerData: true, // Since the table data (just a number) is going to be small compared to the header, center the text instead of left-align
      style: { minWidth: '80px' }
    },
    {
      Header: '# Inelig.',
      accessor: 'ineligibleCount',
      centerData: true, // Since the table data (just a number) is going to be small compared to the header, center the text instead of left-align
      style: { minWidth: '80px' }
    }
  ];

  if (!isFinished) {
    return <DotLoader />;
  }

  // Reset all filters and trigger a search bar clearance with setSearchResetCount
  const resetFilters = () => {
    dispatch(resetTableFilters(ALL_APPLICATIONS_TABLE_ID));
  };

  let bulkUploadTableBanner = null;
  const bulkUploadInfo = filters.bulkUploadInfo;
  if (bulkUploadInfo) {
    bulkUploadTableBanner = (
      <TableBanner
        content={
          <>
            Showing applications from <strong>{bulkUploadInfo.filename}</strong> upload on{' '}
            <strong>{moment(bulkUploadInfo.uploadStartDate).format('MM-DD-YYYY, h:mma')}</strong>
          </>
        }
        onCancel={() =>
          dispatch(setTableFilters(ALL_APPLICATIONS_TABLE_ID, 'bulkUploadInfo', null))
        }
      />
    );
  }

  // Calculate active table filters, used to display active filter count and whether "reset filters" has any effect
  const nameSearchIsActive = ['firstName', 'lastName', 'middleName', 'suffix'].some(
    key => !stringIsEmptyOrNull(filters[key])
  );
  const spnSearchIsActive = !stringIsEmptyOrNull(filters['spn']);
  const activeFilterBooleans = [
    filters.includeClosed,
    !filters.allDates,
    nameSearchIsActive,
    spnSearchIsActive,
    filters.bulkUploadInfo,
    filters.showOnlyInactive,
    filters.assigneeId,
    filters.noAssignees
  ];
  const numActiveFilters = activeFilterBooleans.filter(isActive => isActive).length;

  return (
    <div>
      <ManualTable
        tableTitle="Search applications"
        isLoading={applicationsLoading}
        tableBanner={bulkUploadTableBanner}
        rows={rows}
        columns={columns}
        topLeft={
          <MoreFiltersAndReset>
            <SmallButton
              onClick={() => dispatch(setTableFiltersModal(true, ALL_APPLICATIONS_TABLE_ID))}
            >
              Search & filter
            </SmallButton>
            <LinkLikeButton disabled={numActiveFilters === 0} onClick={resetFilters}>
              Reset filters
            </LinkLikeButton>
            {numActiveFilters > 0 && (
              <span>
                {numActiveFilters} filter{numActiveFilters === 1 ? ' is' : 's are'} currently active
              </span>
            )}
          </MoreFiltersAndReset>
        }
        topRight={
          <ExportButton
            getExport={handleExportClick}
            isLoading={exportLoading}
            text="Export current selection"
            tooltip="Exports apps/cases using applied filters & search queries"
          />
        }
        pagination={{
          pageNum: filters.pageNum,
          setPageNum: newPageNum =>
            dispatch(setTableFilters(ALL_APPLICATIONS_TABLE_ID, 'pageNum', newPageNum)),
          totalPages,
          totalDocuments
        }}
        sorting={{
          sortBy: filters.sortBy,
          sortOrder: filters.sortOrder,
          setSort: columnId =>
            updateTableSort(dispatch, filters, ALL_APPLICATIONS_TABLE_ID, columnId)
        }}
        globalFilter={filters.globalFilter}
        getLink={row => `/application/${row._id}`}
      />
    </div>
  );
};
