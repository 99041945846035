import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setClientModal } from '../../actions/modals';
import { clientModalSelector } from '../../selectors/modals';
import { Modal, ModalFooter, ModalHeader, ScrollingModalContent } from '../general/Modal';
import { Formik } from 'formik';
import {
  DatepickerField,
  MultiFieldLine,
  OptionsList,
  SelectField,
  TextField
} from '../FormFields';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { Button, InvertedButton } from '../general/Button';
import { validateClientEditInfo } from '../../validators';
import { useUpdateClientInfo } from '../../hooks/simpleRequests';
import { getFieldsChanged } from '../../utils/general';
import { toast } from 'react-toastify';
import { friendlyNamesSelector } from '../../selectors/entities';

const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];

// Modal to edit client information
export const ClientInfoModal = () => {
  const showModal = useSelector(clientModalSelector);
  const dispatch = useDispatch();
  const application = useCurrentApplication();
  const friendlyNamesObj = useSelector(friendlyNamesSelector);
  const friendlyNames = friendlyNamesObj.editableAppFields;

  const onClose = () => {
    dispatch(setClientModal(false));
  };

  // Get the function to call on submit (updateClient), the "in-progress" boolean (updatingClient)
  // & pass "onClose" to be callled once the submit function is complete
  const [updateClient, updatingClient] = useUpdateClientInfo(onClose);

  if (!showModal) {
    return null;
  }

  const initialValues = {
    firstName: application.firstName || '',
    middleName: application.middleName || '',
    lastName: application.lastName || '',
    suffix: application.suffix || '',
    aliases: application.aliases || '',
    spn: application.spn || '',
    ssnFour: application.ssnFour || '',
    dob: application.dob
      ? moment(
          moment(application.dob)
            .utc()
            .format('YYYY-MM-DD')
        )
          .utc()
          .toDate()
      : '',
    isVeteran: application.isVeteran,
    idNumber: application.idNumber || '',
    streetAddress: application.streetAddress || '',
    streetAddress2: application.streetAddress2 || '',
    city: application.city || '',
    state: application.state || '',
    zipcode: application.zipcode || '',
    email: application.email || '',
    phone: application.phone || ''
  };

  const submit = values => {
    // get fields changed with standardized dates
    const fieldsChanged = getFieldsChanged(values, initialValues, { isDate: 'true' });

    // Only send a query if at least one field has changed
    if (Object.keys(fieldsChanged).length > 0) {
      updateClient({ fieldsChanged, appId: application._id });
    } else {
      toast.info('No changes have been made', {
        closeButton: false,
        duration: 2000
      });
    }
  };

  return (
    <Modal onClose={onClose}>
      <Formik validate={validateClientEditInfo} initialValues={initialValues} onSubmit={submit}>
        {({ handleSubmit, errors, touched, values, submitCount }) => {
          const getError = name => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <>
              <ModalHeader>Edit Client Information</ModalHeader>

              <ScrollingModalContent height={430} heightOffset={250}>
                <h3>Personal Information</h3>
                <MultiFieldLine columns="48% 48%">
                  <TextField
                    label={`${friendlyNames['firstName']}*`}
                    name="firstName"
                    error={getError('firstName')}
                  />
                  <TextField label={`${friendlyNames['middleName']}`} name="middleName" />
                  <TextField
                    label={`${friendlyNames['lastName']}*`}
                    name="lastName"
                    error={getError('lastName')}
                  />
                  <SelectField label={`${friendlyNames['suffix']}`} name="suffix">
                    <OptionsList
                      options={[
                        { label: 'Jr', value: 'Jr' },
                        { label: 'Sr', value: 'Sr' },
                        { label: 'II', value: 'II' },
                        { label: 'III', value: 'III' },
                        { label: 'IV', value: 'IV' }
                      ]}
                    />
                  </SelectField>
                  <TextField label={`${friendlyNames['aliases']}`} name="aliases" />
                  <TextField
                    label={`${friendlyNames['spn']}*`}
                    name="spn"
                    error={getError('spn')}
                  />
                  <TextField
                    label={`${friendlyNames['ssnFour']}`}
                    name="ssnFour"
                    error={getError('ssnFour')}
                  />
                  <DatepickerField
                    placement="top-start"
                    width={100}
                    label={`${friendlyNames['dob']}*`}
                    name="dob"
                    error={getError('dob')}
                  />
                  <SelectField
                    label={`${friendlyNames['isVeteran']}`}
                    children="Yes"
                    name="isVeteran"
                    error={getError('isVeteran')}
                  >
                    <OptionsList
                      options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false }
                      ]}
                    />
                  </SelectField>
                  <TextField label={`${friendlyNames['idNumber']}`} name="idNumber" />
                </MultiFieldLine>

                <h3>Contact Information</h3>
                <MultiFieldLine columns="48% 48%">
                  <TextField label={`${friendlyNames['streetAddress']}`} name="streetAddress" />
                  <TextField label={`${friendlyNames['streetAddress2']}`} name="streetAddress2" />
                  <TextField label={`${friendlyNames['city']}`} name="city" />
                  <SelectField label={`${friendlyNames['state']}`} name="state">
                    <OptionsList options={states} />
                  </SelectField>
                  <TextField
                    label={`${friendlyNames['zipcode']}`}
                    name="zipcode"
                    error={getError('zipcode')}
                  />
                  <TextField
                    label={`${friendlyNames['email']}`}
                    name="email"
                    error={getError('email')}
                  />
                  <TextField
                    label={`${friendlyNames['phone']}`}
                    name="phone"
                    error={getError('phone')}
                  />
                </MultiFieldLine>
              </ScrollingModalContent>

              <ModalFooter>
                <InvertedButton onClick={onClose}>Cancel</InvertedButton>
                <Button isLoading={updatingClient} onClick={handleSubmit}>
                  Submit
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
