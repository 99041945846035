import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { mutateAsync } from 'redux-query';
import { uploadCSVQuery } from '../actions/queries';
import { statusIsGood, statusIsUnauthorized } from '../utils/general';
import { useHandleUnauthorized } from './useHandleUnauthorized';

// Allow user to upload CSV for bulk upload / SPN lookup
export const useUploadCSV = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const handleUnauthorized = useHandleUnauthorized();

  const submit = (docFile, exportOnly) => {
    setSubmitting(true);
    // convert values to FormData format
    let formData = new FormData();
    formData.append('uploaded_csv', docFile);
    dispatch(mutateAsync(uploadCSVQuery({ values: formData, exportOnly }))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          toast.success(body && body.msg ? body.msg : 'CSV successfully uploaded!');
          onSuccess && onSuccess();
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(body && body.msg ? body.msg : 'Could not upload CSV file');
        }
      }
    );
  };
  return [submit, submitting];
};
