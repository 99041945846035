import React from 'react';
import styled from 'styled-components';
import { HIGHLIGHT, SHADOW_LIGHT } from '../../../constants/cssVars';
import closeIcon from '../../../assets/icons/close.svg';

const BannerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 4px;
`;

const Banner = styled.div`
  padding: 4px 8px 4px 14px;
  background-color: ${HIGHLIGHT};
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${SHADOW_LIGHT};
`;

const CloseImg = styled.img`
  margin-left: 10px;
  height: 15px;
  width: 15px;
`;

const CancelButton = styled.button`
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  margin-left: 10px;
  &:focus {
    box-shadow: none !important;
  }
`;

export const TableBanner = ({ content, onCancel }) => {
  return (
    <BannerWrapper>
      <Banner>
        {content}
        <CancelButton ariaLabel="cancel" type="button" onClick={onCancel}>
          <CloseImg tabIndex="0" src={closeIcon} alt="" />
        </CancelButton>
      </Banner>
    </BannerWrapper>
  );
};
