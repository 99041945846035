import { UNKNOWN_ARRESTING_AGENCY } from './constants/cases';
import {
  EXPUNCTION_PETITION_ATTORNEY_REQUIRED_FIELDS,
  EXPUNCTION_PETITION_PRO_SE_REQUIRED_FIELDS
} from './components/applicationPage/docusignForms/ExpunctionFields';
import { LEGAL_SERVICES_REQUIRED_FIELDS } from './components/applicationPage/docusignForms/LegalServicesFields';

const isValidEmail = value => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

export const validateLogin = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors;
};

export const validateForgotPassword = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

export const validateResetPassword = values => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Invalid password, must be 8 characters or more';
  }
  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Required';
  } else if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = 'Passwords must match';
  }
  return errors;
};

export const validateClientEditInfo = values => {
  const errors = {};
  if (values.email && !isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.spn) {
    errors.spn = 'Required';
  }
  if (!values.dob) {
    errors.dob = 'Required';
  }
  if (values.ssnFour && !/^\d{4}$/.test(values.ssnFour)) {
    errors.ssnFour = 'Please enter the last 4 digits of your SSN';
  }
  if (values.zipcode && !/^\d{5}$/.test(values.zipcode)) {
    errors.zipcode = 'Please enter a 5-digit zipcode';
  }
  return errors;
};

export const validateNote = values => {
  const errors = {};
  if (!values.note) {
    errors.note = 'Required';
  }
  return errors;
};

export const validateNewUser = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.userType) {
    errors.userType = 'Required';
  }
  return errors;
};

export const validateNewApplication = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.spn) {
    errors.spn = 'Required';
  }
  if (!values.dob) {
    errors.dob = 'Required';
  }
};

export const validatePairedAttorney = values => {
  const errors = {};
  if (!values.pairedAttorney) {
    errors.pairedAttorney = 'Required';
  }
  return errors;
};

// helper function that errors object with { [key]: 'Required' } as error for every field with a null value
export const errorsByRequiredFieldsList = (requiredFieldsList, values, fieldsToSkip) => {
  const errors = {};
  requiredFieldsList.forEach(fieldName => {
    if (!values[fieldName] && !(fieldsToSkip || []).includes(fieldName)) {
      const isFalseBoolean = typeof values[fieldName] === 'boolean' && values[fieldName] === false;
      const isZero = values[fieldName] === 0;
      // don't make this 411.0728 field required if conditional is met
      const is0728Skippable =
        fieldName === 'previously_filed_date' &&
        (!values.ndo_0728_previously_filed || values.ndo_0728_previously_filed === 'false');
      if (!isFalseBoolean && !isZero && !is0728Skippable) {
        errors[fieldName] = 'Required';
      }
    }
  });
  return errors;
};

export const validateLegalServicesAgreement = values => {
  return errorsByRequiredFieldsList(LEGAL_SERVICES_REQUIRED_FIELDS, values);
};

export const validateExpunctionFields = values => {
  // If generating pro-se expunction form, allow SSN & ID number to be left blank
  const proSeSelected = values.expunctionUseProSeForm && values.expunctionUseProSeForm !== 'false';
  const requiredFields = proSeSelected
    ? EXPUNCTION_PETITION_PRO_SE_REQUIRED_FIELDS
    : EXPUNCTION_PETITION_ATTORNEY_REQUIRED_FIELDS;
  return errorsByRequiredFieldsList(requiredFields, values);
};

// All fields for expunction form are required, except some agency fields depending on selection
export const validateExpunctionCaseFields = initialValues => values => {
  const fieldsToSkip = [];
  // Don't require forensic agency fields if 'isDrugRelated' isn't set to true
  if (!(values.isDrugRelated === true || values.isDrugRelated === 'true')) {
    fieldsToSkip.push(
      'forensicAgencyName',
      'forensicAgencyOtherInput',
      'forensicAgencyStreetAddress',
      'forensicAgencyCityStateZip'
    );
  }
  // Don't require agency 'other' fields if agency name fields aren't set to 'other'
  if (values.arresting_agency !== 'other') {
    fieldsToSkip.push('arrestingAgencyOtherInput');
  }
  if (values.forensicAgencyName !== 'other') {
    fieldsToSkip.push('forensicAgencyOtherInput');
  }
  // Don't require address for unknown arresting agency
  if (values.arresting_agency === UNKNOWN_ARRESTING_AGENCY) {
    fieldsToSkip.push('arrestingAgencyStreetAddress', 'arrestingAgencyCityStateZip');
  }
  return errorsByRequiredFieldsList(Object.keys(initialValues), values, fieldsToSkip);
};

export const validateEditUserName = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  return errors;
};
