import { useSelector } from 'react-redux';
import { SESSION_EXP_STORAGE_KEY } from '../../constants/general';
import { useHandleUnauthorized } from '../../hooks/useHandleUnauthorized';
import { useInterval } from '../../hooks/useInterval';
import { sessionExpirationTimeSelector } from '../../selectors/entities';

// Frequency to check session expiration time against current time
const SESSION_CHECK_INTERVAL_MS = 1000;

/*  Check whether we've passed the current session's expiration time on a running interval.
    When the expiration time has passed, fully clear the state and redirect to the homepage.
*/
export default function SessionExpirationTimer() {
  const clearStore = useHandleUnauthorized('login');

  const sessionExpTimeMsLs = localStorage.getItem(SESSION_EXP_STORAGE_KEY);
  const sessionExpTimeMsRedux = useSelector(sessionExpirationTimeSelector);
  const sessionExpTimeMs = sessionExpTimeMsLs || sessionExpTimeMsRedux;

  // TODO: Uncomment this when we have an actual login situation!
  // useInterval(() => {
  //   if (sessionExpTimeMs != null) {
  //     const currTimeInMs = Date.now();
  //     if (currTimeInMs > sessionExpTimeMs) {
  //       clearStore();
  //     }
  //   }
  // }, SESSION_CHECK_INTERVAL_MS)

  return null;
}
