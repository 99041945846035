import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAddNoteModal } from '../../actions/modals';
import { addNoteModalSelector } from '../../selectors/modals';
import { Button, InvertedButton } from '../general/Button';
import { Formik } from 'formik';
import { ParagraphTextField } from '../FormFields';
import { Modal, ModalHeader, ModalFooter } from '../general/Modal';
import { useAddNote } from '../../hooks/simpleRequests';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { validateNote } from '../../validators';

// Modal to show when the user clicks "Add New Note"
// Allows user to create a new note in the application page
export const AddNoteModal = () => {
  const showModal = useSelector(addNoteModalSelector);
  const dispatch = useDispatch();
  const application = useCurrentApplication();

  const onClose = () => {
    dispatch(setAddNoteModal(false));
  };

  const [addNewNote, addingNewNote] = useAddNote(onClose);

  if (!showModal) {
    return null;
  }

  const submit = values => {
    addNewNote({ note: values.note, appId: application._id });
  };

  return (
    <Modal onClose={onClose}>
      <Formik validate={validateNote} initialValues={{ note: '' }} onSubmit={submit}>
        {({ handleSubmit, errors, touched, values, submitCount }) => {
          const getError = name => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <>
              <ModalHeader>Add New Note</ModalHeader>
              <ParagraphTextField
                rows="9"
                placeholder="Note"
                name="note"
                error={getError('note')}
              />
              <ModalFooter>
                <InvertedButton onClick={onClose}>Cancel</InvertedButton>
                <Button isLoading={addingNewNote} onClick={handleSubmit}>
                  Submit
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
