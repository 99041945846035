import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { mutateAsync } from 'redux-query';
import {
  addNewUserQuery,
  forgotPasswordQuery,
  resetPasswordQuery,
  updateAppProgressQuery,
  updateCaseInfoQuery,
  updateClientInfoQuery,
  updateExpunctionProgressQuery,
  updateNondisclosureProgressQuery,
  addNoteQuery,
  updateNoteQuery,
  deleteNoteQuery,
  removeDocumentQuery,
  deactivateUserQuery,
  updateAssigneesQuery,
  assignToOpenApplicationQuery,
  createSingleApplicationQuery,
  singleSPNLookupQuery,
  setPairedAttorneyQuery,
  applicationsQuery,
  addCaseQuery,
  deleteCaseQuery,
  createLegalServicesEnvelopeQuery,
  updateLegalServicesEnvelopeQuery,
  sendLegalServicesEnvelopeQuery,
  createNondisclosureEnvelopeQuery,
  updateNondisclosureEnvelopeQuery,
  sendNondisclosureEnvelopeQuery,
  createExpunctionEnvelopeQuery,
  updateExpunctionEnvelopeQuery,
  sendExpunctionEnvelopeQuery,
  envelopePreviewQuery,
  deleteApplicationQuery,
  getBulkSpnCSVQuery,
  applicationsExportQuery,
  singleApplicationQuery,
  closeAppQuery,
  reopenAppQuery,
  updateUserRoleQuery,
  updateUserNameQuery
} from '../actions/queries';
import { statusIsGood, statusIsUnauthorized } from '../utils/general';
import { useHandleUnauthorized } from './useHandleUnauthorized';

// General error message to use if a more detailed error is not provided
const ERROR_MESSAGE = 'An error occurred. Please try again later.';

// Hook to reuse for simple queries
const useSimpleQuery = (query, onSuccess, successMsg, errorMsg, showBackendError) => {
  const [submitting, setSubmitting] = useState(false);
  const handleUnauthorized = useHandleUnauthorized();
  const dispatch = useDispatch();

  const submit = (values, callback) => {
    setSubmitting(true);
    dispatch(mutateAsync(query(values))).then(resp => {
      const { status, body, text } = resp;
      if (statusIsGood(status)) {
        setSubmitting(false);
        successMsg && toast.success(successMsg);
        // If "appBecameClosed" is returned, show a long toast message
        if (body && body.appBecameClosed) {
          toast.info(
            'Application is now closed. It will no longer show up in default applications list.',
            { autoClose: 6000 }
          );
        }
        onSuccess && onSuccess({ text, ...(body || {}) });
        callback && callback();
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        // Show passed error message if provided, or default error message if not
        let errorMessageToShow = errorMsg || ERROR_MESSAGE;
        // Generally we don't show backend errors, but for some cases we might want to
        // give the user more details. If "showBackendError" is true, set as the message to show.
        if (showBackendError) {
          errorMessageToShow = body.msg || errorMessageToShow;
        }
        toast.error(errorMessageToShow);
      }
    });
  };
  return [submit, submitting];
};

// Update client information for application hook
export const useUpdateClientInfo = (onSuccess, hideSuccessMsg) =>
  useSimpleQuery(
    updateClientInfoQuery,
    onSuccess,
    hideSuccessMsg ? null : 'Client info successfully saved',
    null
  );
export const useAddNote = onSuccess =>
  useSimpleQuery(addNoteQuery, onSuccess, 'Note successfully added', null);
export const useEditNote = onSuccess =>
  useSimpleQuery(updateNoteQuery, onSuccess, 'Note successfully saved', null);
export const useDeleteNote = onSuccess =>
  useSimpleQuery(deleteNoteQuery, onSuccess, 'Note successfully deleted', null);

// Update a user's information (first name, last name and role)
export const useUpdateUserRole = onSuccess =>
  useSimpleQuery(updateUserRoleQuery, onSuccess, 'User role successfully updated', null);
export const useUpdateUserName = onSuccess =>
  useSimpleQuery(updateUserNameQuery, onSuccess, 'User name successfully updated', null);

// Update case details hook
export const useUpdateCaseInfo = (onSuccess, hideSuccessMsg) =>
  useSimpleQuery(
    updateCaseInfoQuery,
    onSuccess,
    hideSuccessMsg ? null : 'Case successfully saved',
    null
  );

// Add new case hook
export const useAddCase = (onSuccess, hideSuccessMsg) =>
  useSimpleQuery(addCaseQuery, onSuccess, hideSuccessMsg ? null : 'Case successfully added', null);

// Delete case hook
export const useDeleteCase = (onSuccess, hideSuccessMsg) =>
  useSimpleQuery(
    deleteCaseQuery,
    onSuccess,
    hideSuccessMsg ? null : 'Case successfully deleted',
    null
  );

// Create a single application hook
export const useCreateApplication = onSuccess =>
  useSimpleQuery(createSingleApplicationQuery, onSuccess, 'Successfully created application', null);

// Get cases for a single SPN lookup
export const useSingleSPNLookup = onSuccess =>
  useSimpleQuery(singleSPNLookupQuery, onSuccess, null, null);

// Remove a document from an application hook
export const useRemoveDocument = onSuccess =>
  useSimpleQuery(
    removeDocumentQuery,
    onSuccess,
    'Document successfully removed from application',
    null
  );

// Add new user hook (admin only)
export const useAddNewUser = onSuccess =>
  useSimpleQuery(addNewUserQuery, onSuccess, 'Invitation sent!', null);

// Deactivate user hook (admin only)
export const useDeactivateUser = onSuccess =>
  useSimpleQuery(deactivateUserQuery, onSuccess, 'User successfully deactivated', null);

// Set paired attorney for a paralegal user
export const useSetPairedAttorney = onSuccess =>
  useSimpleQuery(setPairedAttorneyQuery, onSuccess, 'Successfuly set paired attorney', null);

// Change status of a progress task for application
export const useUpdateAppProgress = onSuccess =>
  useSimpleQuery(updateAppProgressQuery, onSuccess, 'Task updated', null);

// Change status of a progress task for expunction
export const useUpdateExpunctionProgress = onSuccess =>
  useSimpleQuery(updateExpunctionProgressQuery, onSuccess, 'Task updated', null);

// Change status of a progress task for a nondisclosure case
export const useUpdateNondisclosureProgress = onSuccess =>
  useSimpleQuery(updateNondisclosureProgressQuery, onSuccess, 'Task updated', null);

// Update assignees for an application
export const useUpdateAssignees = onSuccess =>
  useSimpleQuery(updateAssigneesQuery, onSuccess, 'Assignees successfully updated', null);

// Self assign to next open application
export const useAssignToOpenApplication = onSuccess =>
  useSimpleQuery(assignToOpenApplicationQuery, onSuccess, null, null);

// Fetch filtered applications based on selected params
export const useApplicationsQuery = onSuccess =>
  useSimpleQuery(applicationsQuery, onSuccess, null, null);

// Fetch single application by appId
export const useSingleApplicationQuery = onSuccess =>
  useSimpleQuery(singleApplicationQuery, onSuccess, null, null);

// Fetch filtered applications based on selected params
export const useApplicationsExportQuery = onSuccess =>
  useSimpleQuery(applicationsExportQuery, onSuccess, null, null);

// Delete an application
export const useDeleteApplicationQuery = onSuccess =>
  useSimpleQuery(deleteApplicationQuery, onSuccess, 'Successfully deleted application', null);

// Fetch all applications
export const useBulkSpnCSVQuery = onSuccess =>
  useSimpleQuery(getBulkSpnCSVQuery, onSuccess, null, null);

// Manually close application
export const useCloseAppQuery = onSuccess => useSimpleQuery(closeAppQuery, onSuccess, null, null);

// Manually reopen application
export const useReopenAppQuery = onSuccess =>
  useSimpleQuery(reopenAppQuery, onSuccess, 'Successfully re-opened application', null);

// AUTH HOOKS

// Reset password hook
export const useResetPasswordQuery = (onSuccess, token) => {
  return useSimpleQuery(
    resetPasswordQuery(token),
    onSuccess,
    'Successfully updated password!',
    'Could not reset password.'
  );
};

// Forgot password
export const useForgotPasswordQuery = () => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const submit = values => {
    setSubmitting(true);
    dispatch(mutateAsync(forgotPasswordQuery(values.email))).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        toast.success('Success! Check your inbox for instructions.');
      } else {
        setSubmitting(false);
        toast.error('Could not reset password.');
      }
    });
  };
  return [submit, submitting];
};

// DOCUSIGN HOOKS

// Create legal services envelope
export const useCreateLegalServicesEnvelopeQuery = onSuccess =>
  useSimpleQuery(createLegalServicesEnvelopeQuery, onSuccess, null, null);

// Update legal services envelope
export const useUpdateLegalServicesEnvelopeQuery = onSuccess =>
  useSimpleQuery(updateLegalServicesEnvelopeQuery, onSuccess, null, null);

// Send legal services envelope
export const useSendLegalServicesEnvelopeQuery = onSuccess =>
  useSimpleQuery(
    sendLegalServicesEnvelopeQuery,
    onSuccess,
    'Legal services agreement sent to client and attorney',
    null
  );

// Create nondisclosure envelope
export const useCreateNondisclosureEnvelopeQuery = onSuccess =>
  useSimpleQuery(createNondisclosureEnvelopeQuery, onSuccess, null, null);

// Update nondisclosure envelope
export const useUpdateNondisclosureEnvelopeQuery = onSuccess =>
  useSimpleQuery(updateNondisclosureEnvelopeQuery, onSuccess, null, null);

// Send nondisclosure envelope
export const useSendNondisclosureEnvelopeQuery = onSuccess =>
  useSimpleQuery(sendNondisclosureEnvelopeQuery, onSuccess, 'Nondisclosure petition sent!', null);

// Create nondisclosure envelope
export const useCreateExpunctionEnvelopeQuery = onSuccess =>
  useSimpleQuery(createExpunctionEnvelopeQuery, onSuccess, null, null, true);

// Update nondisclosure envelope
export const useUpdateExpunctionEnvelopeQuery = onSuccess =>
  useSimpleQuery(updateExpunctionEnvelopeQuery, onSuccess, null, null);

// Send nondisclosure envelope
export const useSendExpunctionEnvelopeQuery = onSuccess =>
  useSimpleQuery(
    sendExpunctionEnvelopeQuery,
    onSuccess,
    'Expunction petition sent to attorney',
    null
  );

// Preview docusign document
export const useEnvelopePreviewQuery = onSuccess =>
  useSimpleQuery(envelopePreviewQuery, onSuccess, null, null);
