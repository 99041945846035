import {
  SET_CLIENT_MODAL,
  SET_CASE_MODAL,
  SET_ADD_NOTE_MODAL,
  SET_EDIT_NOTE_MODAL,
  SET_DELETE_NOTE_MODAL,
  SET_TASK_MODAL,
  SET_NEW_USER_MODAL,
  SET_EDIT_ASSIGNEES_MODAL,
  SET_SPN_LOOKUP_CASE_MODAL,
  SET_NEW_APPLICATION_MODAL,
  SET_OPEN_APPLICATION_ASSIGNMENT_MODAL,
  SET_CONFIRMATION_MODAL,
  SET_DOCUMENT_UPLOAD_MODAL,
  SET_CSV_UPLOAD_MODAL,
  SET_ADD_CASE_MODAL,
  SET_ACTIVITY_LOG_MODAL,
  SET_CLOSE_MODAL,
  SET_DOCUSIGN_FIELDS_MODAL,
  SET_EXPUNCTION_CASE_FIELDS_MODAL,
  SET_DELETE_APP_MODAL,
  SET_TABLE_FILTERS_MODAL,
  SET_EDIT_USER_MODAL,
  SET_REPORTS_FILTERS_MODAL,
  SET_REPORTS_FILTERS_INFO
} from '../actions/modals';

const defaultState = {
  showClientModal: false,
  showCaseModal: false,
  showSpnLookupCaseModal: false,
  showNewApplicationModal: false,
  showOpenApplicationAssignmentModal: false,
  showAddNoteModal: false,
  showEditNoteModal: false,
  showDeleteNoteModal: false,
  caseModalInfo: {
    caseId: null // identify the case to show when "showCaseModal" is true
  },
  showAddCaseModal: false,
  noteModalInfo: {
    currentNote: null
  },
  showTaskModal: false,
  taskModalInfo: {
    taskId: null,
    caseId: null
  },
  showNewUserModal: false,
  showEditAssigneesModal: false,
  showConfirmationModal: false,
  confirmationModalInfo: {
    modalBody: null,
    modalTitle: null,
    onConfirm: null
  },
  showDocumentUploadModal: false,
  showCSVUploadModal: false,
  showActivityLogModal: false,
  showCloseModal: false,
  closeModalInfo: {
    caseId: null,
    isExpunction: false
  },
  showDocusignFieldsModal: false,
  docusignFieldsModalInfo: {
    taskId: null,
    caseId: null
  },
  showExpunctionCaseFieldsModal: false,
  expunctionCaseFieldsModalInfo: {
    caseId: null
  },
  showDeleteAppModal: false,
  showTableFiltersModal: false,
  tableFiltersModalInfo: {
    tableId: null
  },
  showReportsFiltersModal: false,
  reportsFiltersModalInfo: {
    customStart: null,
    customEnd: null,
    allDates: true
  },
  showEditUserModal: false,
  editUserModalInfo: {
    selectedUser: null
  }
};

const modalsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CLIENT_MODAL:
      return {
        ...state,
        showClientModal: action.payload
      };
    case SET_CASE_MODAL:
      return {
        ...state,
        showCaseModal: action.payload.showModal,
        caseModalInfo: {
          caseId: action.payload.caseId
        }
      };
    case SET_ADD_CASE_MODAL:
      return {
        ...state,
        showAddCaseModal: action.payload.showModal
      };
    case SET_SPN_LOOKUP_CASE_MODAL:
      return {
        ...state,
        showSpnLookupCaseModal: action.payload.showModal,
        caseModalInfo: {
          caseId: action.payload.caseId
        }
      };
    case SET_NEW_APPLICATION_MODAL:
      return {
        ...state,
        showNewApplicationModal: action.payload
      };
    case SET_OPEN_APPLICATION_ASSIGNMENT_MODAL:
      return {
        ...state,
        showOpenApplicationAssignmentModal: action.payload
      };
    case SET_ADD_NOTE_MODAL:
      return {
        ...state,
        showAddNoteModal: action.payload
      };
    case SET_EDIT_NOTE_MODAL:
      return {
        ...state,
        showEditNoteModal: action.payload.showModal,
        noteModalInfo: {
          currentNote: action.payload.currentNote
        }
      };
    case SET_DELETE_NOTE_MODAL:
      return {
        ...state,
        showDeleteNoteModal: action.payload.showModal,
        noteModalInfo: {
          currentNote: action.payload.currentNote
        }
      };
    case SET_TASK_MODAL:
      return {
        ...state,
        showTaskModal: action.payload.showModal,
        taskModalInfo: {
          caseId: action.payload.caseId,
          taskId: action.payload.taskId
        }
      };
    case SET_NEW_USER_MODAL:
      return {
        ...state,
        showNewUserModal: action.payload
      };
    case SET_EDIT_ASSIGNEES_MODAL:
      return {
        ...state,
        showEditAssigneesModal: action.payload
      };
    case SET_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmationModal: action.payload.showModal,
        confirmationModalInfo: {
          modalTitle: action.payload.modalTitle,
          modalBody: action.payload.modalBody,
          onConfirm: action.payload.onConfirm
        }
      };
    case SET_DOCUMENT_UPLOAD_MODAL:
      return {
        ...state,
        showDocumentUploadModal: action.payload
      };
    case SET_CSV_UPLOAD_MODAL:
      return {
        ...state,
        showCSVUploadModal: action.payload
      };
    case SET_ACTIVITY_LOG_MODAL:
      return {
        ...state,
        showActivityLogModal: action.payload
      };
    case SET_CLOSE_MODAL:
      return {
        ...state,
        showCloseModal: action.payload.showModal,
        closeModalInfo: {
          caseId: action.payload.caseId,
          isExpunction: action.payload.isExpunction
        }
      };
    case SET_DOCUSIGN_FIELDS_MODAL:
      return {
        ...state,
        showDocusignFieldsModal: action.payload.showModal,
        docusignFieldsModalInfo: {
          taskId: action.payload.taskId,
          caseId: action.payload.caseId
        }
      };
    case SET_EXPUNCTION_CASE_FIELDS_MODAL:
      return {
        ...state,
        showExpunctionCaseFieldsModal: action.payload.showModal,
        expunctionCaseFieldsModalInfo: {
          caseId: action.payload.caseId
        }
      };
    case SET_DELETE_APP_MODAL:
      return {
        ...state,
        showDeleteAppModal: action.payload
      };
    case SET_TABLE_FILTERS_MODAL:
      return {
        ...state,
        showTableFiltersModal: action.payload.showModal,
        tableFiltersModalInfo: {
          tableId: action.payload.tableId
        }
      };
    case SET_EDIT_USER_MODAL:
      return {
        ...state,
        showEditUserModal: action.payload.showModal,
        editUserModalInfo: {
          selectedUser: action.payload.selectedUser
        }
      };
    case SET_REPORTS_FILTERS_MODAL:
      return {
        ...state,
        showReportsFiltersModal: action.payload.showModal
      };
    case SET_REPORTS_FILTERS_INFO:
      return {
        ...state,
        reportsFiltersModalInfo: action.payload.filterValues
      };
    default:
      return state;
  }
};

export default modalsReducer;
