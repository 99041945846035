import React from 'react';
import { useDispatch } from 'react-redux';
import { ELIGIBILITY_SORT_ORDER } from '../../constants/general';
import { Table } from './table/Table';
import moment from 'moment';

// Display a table of cases with calculated eligibility
export const CasesTable = ({
  cases,
  searchRight,
  setModal,
  printView, // for printing, disables interactive features on table and increases row cutoff
  currentSorting, // for printing, pass sorting if available (so printed table is displayed in same order as app table)
  setTableSorting // for app table, update table sorting so it can be passed to printed table (see above)
}) => {
  const dispatch = useDispatch();

  // Array to be to our table as the main data
  const data = React.useMemo(
    () =>
      (cases || []).map(caseObj => {
        // Just grab the pieces of the case we need for the table
        const {
          casID,
          crt_num,
          current_offense_name,
          current_charge_level,
          misdemeanor_felony,
          fda,
          final_eligibility,
          ndo_form,
          waiting_period_end,
          _id
        } = caseObj;
        return {
          caseId: _id, // mongodb object id
          casID,
          crt_num,
          current_offense_name,
          currentMisdFelonyLevel: `${misdemeanor_felony} ${current_charge_level}`,
          fda,
          final_eligibility,
          waiting_period_end,
          ndo_form,
          same_criminal_episode_has_felony:
            typeof caseObj.same_criminal_episode_has_felony === 'boolean'
              ? caseObj.same_criminal_episode_has_felony
                ? 'Yes'
                : 'No'
              : ''
        };
      }),
    [cases]
  );

  // Array to be passed to our table as the columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Case ID',
        accessor: 'casID',
        style: { minWidth: '80px' }
      },
      {
        Header: 'Court #',
        accessor: 'crt_num',
        centerData: true,
        style: { minWidth: '60px' }
      },
      {
        Header: 'Offense',
        accessor: 'current_offense_name'
      },
      {
        Header: 'Level',
        accessor: 'currentMisdFelonyLevel',
        style: { minWidth: '80px' }
      },
      {
        Header: 'Filing Date',
        accessor: 'fda',
        style: { minWidth: '90px' }
      },
      {
        Header: 'Eligibility',
        accessor: 'final_eligibility',
        style: { minWidth: '90px' },
        // Custom sort for eligibility, displaying eligible cases first (non-alphabetical)
        sortType: (a, b) =>{
          const indexA = ELIGIBILITY_SORT_ORDER.indexOf(a.original.final_eligibility)
          const indexB = ELIGIBILITY_SORT_ORDER.indexOf(b.original.final_eligibility)
          if (indexA - indexB === 0) {
            return +moment(b.original.fda).toDate() - +moment(a.original.fda).toDate()
          }
          return indexA - indexB
      }},
      {
        Header: 'NDO Form',
        accessor: 'ndo_form',
        centerData: true,
        style: { minWidth: '80px' }
      },
      {
        Header: 'SOL End',
        accessor: 'waiting_period_end',
        centerData: true,
        style: { minWidth: '85px' }
      },
      {
        Header: 'Same episode includes felony conv.',
        accessor: 'same_criminal_episode_has_felony',
        centerData: true,
        style: { minWidth: '110px' }
      }
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      searchRight={searchRight}
      searchPlaceholder="Search cases ..."
      initialSorting={[{ id: 'final_eligibility' }]}
      currentSorting={currentSorting}
      onSortChange={setTableSorting ? newSort => setTableSorting(newSort) : null}
      printView={printView}
      onRowClick={printView ? null : row => dispatch(setModal(true, row.original.caseId))}
    />
  );
};
