import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { mutateAsync } from 'redux-query';
import { uploadDocumentQuery } from '../actions/queries';
import { statusIsGood, statusIsUnauthorized } from '../utils/general';
import { useHandleUnauthorized } from './useHandleUnauthorized';

export const useUploadDocument = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const handleUnauthorized = useHandleUnauthorized();

  const submit = (docFile, appId) => {
    setSubmitting(true);
    // convert values to FormData format
    let formData = new FormData();
    formData.append('uploaded_file', docFile);
    dispatch(mutateAsync(uploadDocumentQuery(formData, appId))).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        toast.success(body && body.msg ? body.msg : 'File successfully uploaded!');
        onSuccess && onSuccess();
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        toast.error(body && body.msg ? body.msg : 'Could not upload file');
      }
    });
  };
  return [submit, submitting];
};
