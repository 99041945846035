import React, { useState } from 'react';
import styled from 'styled-components';
import { ApplicationClosedReasonTable } from './ApplicationClosedReasonTable';
import { ApplicationEligibilityTable } from './ApplicationEligibilityTable';
import { ApplicationStatusTable } from './ApplicationStatusTable';
import { CaseEligibilityTable } from './CaseEligibilityTable';
import { InactiveApplicationsSection } from './InactiveApplicationsSection';
import { CaseStatusTable } from './CaseStatusTable';
import { PRIMARY_DARK } from '../../constants/cssVars';
import { DateSelectorSection } from './DateSelectorSection';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  > button {
    min-width: 150px;
    text-align: center;
    font-weight: 400;
    font-size: 1.2rem;
    background-color: #fbfbfb;
    color: ${PRIMARY_DARK};
    border: 1px solid #ddd;
    border-bottom: none;
    padding: 10px 0px;
    border-radius: 6px 6px 0px 0px;
  }
  > button.selected {
    font-weight: 600;
    background-color: white;
  }
  position: relative;
  top: 1px;
  z-index: 1;
`;

const Content = styled.div`
  background-color: white;
  padding: 15px;
  padding-top: 5px;
  border: 1px solid #ddd;
  border-radius: 0px 6px 6px 6px;
`;

export const TableHeader = styled.h3`
  margin-top: 30px;
  margin-bottom: 15px;
  margin-left: 10px;
  font-size: 1.15rem;
  font-weight: 600;
  color: #444;
`;

export const SmallTableWrapper = styled.div`
  width: 600px;
  max-width: 100%;
`;

export const ReportsText = styled.p`
  margin-left: 10px;
  margin-bottom: 30px;
  color: #333;
`;

export const ReportsNote = styled.p`
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 0px;
  color: #333;
`;

// Reports page, with tab for application-level & case-level reports
const APPLICATIONS_TAB = 'applications';
const CASES_TAB = 'cases';
export const Reports = () => {
  const [activeTab, setActiveTab] = useState(APPLICATIONS_TAB);

  return (
    <>
      <Header>
        <h2 style={{ marginBottom: '10px' }}>Reports</h2>
      </Header>

      <Header>
        <TabsWrapper>
          <button
            className={activeTab === APPLICATIONS_TAB ? 'selected' : ''}
            onClick={() => setActiveTab(APPLICATIONS_TAB)}
          >
            Applications
          </button>
          <button
            className={activeTab === CASES_TAB ? 'selected' : ''}
            onClick={() => setActiveTab(CASES_TAB)}
          >
            Cases
          </button>
        </TabsWrapper>
        <DateSelectorSection />
      </Header>
      <Content>
        {activeTab === APPLICATIONS_TAB && <ApplicationReports />}
        {activeTab === CASES_TAB && <CaseReports />}
      </Content>
    </>
  );
};

const ApplicationReports = () => {
  return (
    <>
      <ApplicationEligibilityTable />
      <ApplicationStatusTable />
      <ApplicationClosedReasonTable />
      <InactiveApplicationsSection />
    </>
  );
};

const CaseReports = () => {
  return (
    <>
      <CaseEligibilityTable />
      <CaseStatusTable />
    </>
  );
};
