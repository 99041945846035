import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCaseModal } from '../../actions/modals';
import { caseModalInfoSelector, caseModalSelector } from '../../selectors/modals';
import { Modal } from '../general/Modal';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { CaseInformation } from './CaseInformation';
import { UpdateCaseForm } from './CaseForm';
import { DeleteCaseConfirmation } from './DeleteCaseConfirmation';

// Mode options
const INFO_MODE = 'INFO';
const EDIT_MODE = 'EDIT';
const DELETE_MODE = 'DELETE';

// Modal to show when a case is clicked on.
// Allows user to view, edit, or delete a case.
export const CaseModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(caseModalSelector);
  const { caseId } = useSelector(caseModalInfoSelector);
  const application = useCurrentApplication();
  const currentCaseObj = (application.hcdcCases || []).find(caseObj => caseObj._id === caseId);
  const [mode, setMode] = useState(INFO_MODE);

  const onClose = () => {
    dispatch(setCaseModal(false, null));
    setMode(INFO_MODE); // Makes sure modal will be in viewing mode when opened again
  };

  if (!showModal) {
    return null;
  }

  let content = null;

  if (mode === INFO_MODE) {
    content = (
      <CaseInformation
        onEditClick={() => setMode(EDIT_MODE)}
        onDeleteClick={() => setMode(DELETE_MODE)}
        caseObj={currentCaseObj}
      />
    );
  }

  if (mode === EDIT_MODE) {
    content = <UpdateCaseForm onCancel={() => setMode(INFO_MODE)} caseObj={currentCaseObj} />;
  }

  if (mode === DELETE_MODE) {
    content = (
      <DeleteCaseConfirmation onCancel={() => setMode(INFO_MODE)} onDeleteFinish={onClose} />
    );
  }

  return (
    <Modal width={650} onClose={onClose}>
      {content}
    </Modal>
  );
};
