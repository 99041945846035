import React from 'react';
import styled from 'styled-components';
import { SmallButton } from '../general/Button';
import { setReportsFiltersModal } from '../../actions/modals';
import moment from 'moment';
import { reportsFiltersModalInfoSelector } from '../../selectors/modals';
import { useDispatch, useSelector } from 'react-redux';

const DateSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  span {
    margin-right: 10px;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    text-align: left;
    align-items: flex-start;
  }
`;

export const DateSelectorSection = () => {
  const dispatch = useDispatch();
  const reportsFilters = useSelector(reportsFiltersModalInfoSelector);
  return (
    <DateSection>
      <span style={{ fontStyle: 'italic' }}>
        {!reportsFilters.allDates ? (
          <>
            Showing results for {moment(reportsFilters['customStart']).format('MM/DD/YYYY')} -{' '}
            {moment(reportsFilters['customEnd']).format('MM/DD/YYYY')}
          </>
        ) : (
          'Showing all results'
        )}
      </span>
      <SmallButton onClick={() => dispatch(setReportsFiltersModal(true))}>
        {reportsFilters.allDates ? 'Add' : 'Adjust'} date range
      </SmallButton>
    </DateSection>
  );
};
