import React from 'react';
import styled from 'styled-components';
import chevronUpIcon from '../../assets/icons/chevron_up.svg';
import chevronDownIcon from '../../assets/icons/chevron_down.svg';
import { GREY_MEDIUM_DARK } from '../../constants/cssVars';

const chevronWrapperStyle = `
  padding-top: 0.2em;
  opacity: 0.7;
  > img {
    width: 1.3em;
    height: 0.8em;
  }
`;

const ButtonWrapper = styled.button`
  background-color: transparent;
  border: 0px;
  display: flex;
  align-items: center;
  ${chevronWrapperStyle}
  &:hover {
    opacity: 1;
  }
  > div {
    color: ${GREY_MEDIUM_DARK};
    margin-right: 10px;
    font-size: 18px;
  }
`;

const DivWrapper = styled.div`
  background-color: transparent;
  border: 0px;
  ${chevronWrapperStyle}
`;

// Button that can be used to expand or collapse a UI element
// Contents are a chevron that flips when clicked
// Will be facing down when expanded, and up when collapsed, unless flipDirection is true
export const ChevronButton = ({ expanded, setExpanded, flipDirection, supportingText }) => {
  const expandedIcon = flipDirection ? chevronDownIcon : chevronUpIcon;
  const compressedIcon = flipDirection ? chevronUpIcon : chevronDownIcon;
  return (
    <ButtonWrapper onClick={() => setExpanded(!expanded)}>
      {supportingText && <div>{supportingText}</div>}
      <img
        src={expanded ? expandedIcon : compressedIcon}
        alt={`Show ${expanded ? 'less' : 'more'}`}
      />
    </ButtonWrapper>
  );
};

// Unclickable (not a button) chevron element for use when a parent element controls the toggle
// Will be facing down when expanded, and up when collapsed, unless flipDirection is true
export const ChevronIcon = ({ expanded, flipDirection }) => {
  const expandedIcon = flipDirection ? chevronDownIcon : chevronUpIcon;
  const compressedIcon = flipDirection ? chevronUpIcon : chevronDownIcon;
  return (
    <DivWrapper>
      <img src={expanded ? expandedIcon : compressedIcon} alt="" />
    </DivWrapper>
  );
};
