import React from 'react';
import { useRequest } from 'redux-query-react';
import { useDispatch, useSelector } from 'react-redux';
import { setEditAssigneesModal } from '../../actions/modals';
import { editAssigneesModalSelector } from '../../selectors/modals';
import { Modal, ModalFooter, ModalHeader, ModalContent } from '../general/Modal';
import { Formik } from 'formik';
import { MultiFieldLine, OptionsList, SelectField } from '../FormFields';
import { Button, InvertedButton } from '../general/Button';
import { useUpdateAssignees } from '../../hooks/simpleRequests';
import { useAssigneeOptions } from '../../hooks/useAssigneeOptions';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { allUsersQuery } from '../../actions/queries';
import { DotLoader } from '../Loaders';

// Modal to assign users to application
export const EditAssigneesModal = () => {
  const [{ isFinished }] = useRequest(allUsersQuery());
  const application = useCurrentApplication();
  const { attorneyOptions, paralegalOptions } = useAssigneeOptions();
  const showModal = useSelector(editAssigneesModalSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setEditAssigneesModal(false));
  };

  // Get the function to call on submit (updateAssignees), the "in-progress" boolean (updateAssigneesPending)
  // & pass "onClose" to be callled once the submit function is complete
  const [updateAssignees, updateAssigneesPending] = useUpdateAssignees(onClose);

  if (!showModal) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <Formik
        initialValues={{
          assigneeAttorneyId: application.assigneeAttorney ? application.assigneeAttorney._id : '',
          assigneeParalegalId: application.assigneeParalegal
            ? application.assigneeParalegal._id
            : '',
          appId: application._id
        }}
        onSubmit={updateAssignees}
      >
        {({ handleSubmit }) => {
          if (!isFinished) {
            return <DotLoader />;
          }
          return (
            <>
              <ModalHeader>Edit Assignees</ModalHeader>
              <ModalContent>
                <p>Update the users assigned to this application.</p>
                <MultiFieldLine columns="48% 48%">
                  <SelectField label="Attorney" name="assigneeAttorneyId">
                    <OptionsList options={attorneyOptions} />
                  </SelectField>
                </MultiFieldLine>
                <MultiFieldLine columns="48% 48%">
                  <SelectField label="Paralegal" name="assigneeParalegalId">
                    <OptionsList options={paralegalOptions} />
                  </SelectField>
                </MultiFieldLine>
              </ModalContent>
              <ModalFooter>
                <InvertedButton onClick={onClose}>Cancel</InvertedButton>
                <Button
                  style={{ minWidth: '200px' }}
                  isLoading={updateAssigneesPending}
                  type="button"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
