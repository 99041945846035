import React from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  ModalFooter,
  ModalHeader,
  ModalSubeader,
  ScrollingModalContent
} from '../../general/Modal';
import { Button, InvertedButton } from '../../general/Button';
import {
  DatepickerField,
  MultiFieldLine,
  OptionsList,
  SelectField,
  TextField
} from '../../FormFields';
import { useCurrentApplication } from '../../../hooks/useCurrentApplication';
import {
  useCreateNondisclosureEnvelopeQuery,
  useEnvelopePreviewQuery,
  useUpdateCaseInfo,
  useUpdateNondisclosureEnvelopeQuery,
  useUpdateClientInfo
} from '../../../hooks/simpleRequests';
import { getFieldsChanged } from '../../../utils/general';
import { ValidateOnMount } from '../../general/ValidateOnMount';
import { ErrorsBanner, MissingAttorneyBanner } from './ErrorsBanner';
import { SharedFieldsNote } from './SharedFieldsNote';
import { docusignFieldsModalInfoSelector } from '../../../selectors/modals';
import { CASE_FIELD_PROPERTIES } from '../../../constants/cases';
import {
  friendlyNamesSelector,
  docusignNondisclosureVersionSelector
} from '../../../selectors/entities';
import { createSelectOptions } from '../../../utils/cases';
import { INITIAL_VALUES_AND_VALIDATE_BY_NDO_FORM } from '../../../constants/ndoFormFields';

// Return a case by the given caseId (not casID) and list of cases
const getCaseByCaseId = (caseId, hcdcCases) => {
  return (hcdcCases || []).find(caseObj => caseObj._id === caseId);
};

// Get the nondisclosure petition id given an application and case id
export const getNondisclosurePetitionId = (caseId, application) => {
  const caseObj = getCaseByCaseId(caseId, application.hcdcCases);
  if (caseObj) {
    return caseObj.nondisclosurePetitionEnvelopeId;
  }
};

// Get the nondisclosure petition doc version given an application and case id
export const getNondisclosurePetitionDocVersion = (caseId, application) => {
  const caseObj = getCaseByCaseId(caseId, application.hcdcCases);
  if (caseObj) {
    return caseObj.nondisclosurePetitionDocVersion;
  }
};

// Form for nondisclosure petition fields, to be displayed in DocusignFieldsModal
export const NondisclosureFields = ({ onClose, onNext, reviewFormFields }) => {
  // Fetch docusign envelope information, field friendly names, and ndoForm type
  const { caseId } = useSelector(docusignFieldsModalInfoSelector);
  const application = useCurrentApplication();
  const envelopeId = getNondisclosurePetitionId(caseId, application);
  const docusignNondisclosureVersion = useSelector(docusignNondisclosureVersionSelector);
  const caseObj = getCaseByCaseId(caseId, application.hcdcCases);
  const friendlyNamesObj = useSelector(friendlyNamesSelector);
  const appFriendlyNames = friendlyNamesObj.editableAppFields;
  const caseFriendlyNames = friendlyNamesObj.editableCaseFields;
  const ndoForm = caseObj.ndo_form;

  // if there's an envelopeId, use the update hook, otherwise, use the create hook
  const [createEnvelope, creatingEnvelope] = useCreateNondisclosureEnvelopeQuery(body =>
    generatePreview({
      envelopeId: getNondisclosurePetitionId(caseId, body.app),
      appId: application._id
    })
  );
  const [updateEnvelope, updatingEnvelope] = useUpdateNondisclosureEnvelopeQuery(body =>
    generatePreview({
      envelopeId: getNondisclosurePetitionId(caseId, body.app),
      appId: application._id
    })
  );
  const [generatePreview, generatingPreview] = useEnvelopePreviewQuery(body => onNext(body.url));
  const [updateClient, updatingClient] = useUpdateClientInfo(null, true);

  // On save, create a new envelope if no "currentEnvelopeId" exists yet, otherwise update the existing envelope
  const createOrUpdateEnvelope = (currentEnvelopeId, currentEnvelopeDocVersion) => {
    if (currentEnvelopeId) {
      if (currentEnvelopeDocVersion === docusignNondisclosureVersion) {
        updateEnvelope({ envelopeId: currentEnvelopeId, appId: application._id, caseId });
      } else {
        createEnvelope({ appId: application._id, caseId });
      }
    } else {
      createEnvelope({ appId: application._id, caseId });
    }
  };

  // Update case handler, as we'll save the new case information to our database before updating the docusign form
  const [updateCase, updatingCase] = useUpdateCaseInfo(body => {
    const nondisclosurePetitionEnvelopeId = getNondisclosurePetitionId(caseId, body.app);
    const nondisclosurePetitionDocVersion = getNondisclosurePetitionDocVersion(caseId, body.app);
    createOrUpdateEnvelope(nondisclosurePetitionEnvelopeId, nondisclosurePetitionDocVersion);
  }, true);

  // Fetch initial values and validator based on the ndoForm number
  const { getUniqueCaseInitialValues, validate } = INITIAL_VALUES_AND_VALIDATE_BY_NDO_FORM[ndoForm];
  const caseInitialValues = {
    crt: caseObj.crt || '',
    casID: caseObj.casID || '',
    ...getUniqueCaseInitialValues(caseObj)
  };
  const appInitialValues = {
    firstName: application.firstName || '',
    middleName: application.middleName || '',
    lastName: application.lastName || '',
    suffix: application.suffix || ''
  };
  const initialValues = {
    ...appInitialValues,
    ...caseInitialValues
  };

  const isLoading =
    updatingCase || creatingEnvelope || updatingEnvelope || generatingPreview || updatingClient;

  // On submit, first save the client information / case information, then update (or create) the docusign envelope
  const submit = values => {
    // get fields changed with standardized dates
    const appFieldsChanged = getFieldsChanged(values, { ...appInitialValues }, {});
    const caseFieldsChanged = getFieldsChanged(
      values,
      { ...caseInitialValues },
      CASE_FIELD_PROPERTIES
    );
    // Only send update queries if at least one field changed
    if (Object.keys(appFieldsChanged).length > 0 || Object.keys(caseFieldsChanged).length > 0) {
      updateClient({ fieldsChanged: appFieldsChanged, appId: application._id }, () => {
        updateCase({ fieldsChanged: caseFieldsChanged, appId: application._id, caseId });
      });
    }
    // Otherwise, jump straight to creating / updating the envelope for preview
    else {
      const nondisclosurePetitionDocVersion = getNondisclosurePetitionDocVersion(
        caseId,
        application
      );
      createOrUpdateEnvelope(envelopeId, nondisclosurePetitionDocVersion);
    }
  };

  // both an assigned attorney and assigned paralegal must be present to sign & receive docusign forms
  const missingAssignees = !application.assigneeAttorney || !application.assigneeParalegal;

  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={submit}
    >
      {({ handleSubmit, errors, values }) => {
        const getError = name => errors[name];
        const fieldsChanged = getFieldsChanged(values, initialValues, { dob: { isDate: 'true' } });
        const numErrors = Object.keys(errors).length;
        return (
          <>
            <ModalHeader hideBorder={true}>
              {reviewFormFields ? 'Review' : 'Fill out'} form fields
            </ModalHeader>
            <ModalSubeader>
              Nondisclosure Petition {ndoForm} for Case {caseObj.casID}
            </ModalSubeader>

            <ErrorsBanner numErrors={numErrors} />
            <MissingAttorneyBanner />
            <ScrollingModalContent height={400}>
              <SharedFieldsNote />
              <MultiFieldLine style={{ margin: '15px 0px 20px 0px' }}>
                <TextField
                  short={1}
                  label={`${appFriendlyNames['firstName']}*`}
                  name="firstName"
                  error={getError('firstName')}
                />
                <TextField
                  short={1}
                  label={`${appFriendlyNames['middleName']}`}
                  name="middleName"
                />
                <TextField
                  short={1}
                  label={`${appFriendlyNames['lastName']}*`}
                  name="lastName"
                  error={getError('lastName')}
                />
                <SelectField short={1} label={`${appFriendlyNames['suffix']}`} name="suffix">
                  <OptionsList
                    options={[
                      { label: 'Jr', value: 'Jr' },
                      { label: 'Sr', value: 'Sr' },
                      { label: 'II', value: 'II' },
                      { label: 'III', value: 'III' },
                      { label: 'IV', value: 'IV' }
                    ]}
                  />
                </SelectField>
                {Object.keys(caseInitialValues).map(fieldName => {
                  const { options, optionsProps, isDate, isNumeric } =
                    CASE_FIELD_PROPERTIES[fieldName] || {};
                  const friendlyName = caseFriendlyNames[fieldName];
                  if (Array.isArray(options) && options.length > 0) {
                    return (
                      <SelectField
                        short={1}
                        key={`ndo-petition-form-${fieldName}`}
                        label={`${friendlyName}*`}
                        name={fieldName}
                        error={getError(fieldName)}
                      >
                        <OptionsList
                          {...(optionsProps || {})}
                          options={options[0].value ? options : createSelectOptions(options)}
                        />
                      </SelectField>
                    );
                  }
                  if (isDate) {
                    // this 411.0728 field should only be shown conditionally
                    if (
                      fieldName === 'previously_filed_date' &&
                      (!values.ndo_0728_previously_filed ||
                        values.ndo_0728_previously_filed === 'false')
                    ) {
                      return null;
                    }
                    return (
                      <DatepickerField
                        key={`ndo-petition-form-${fieldName}`}
                        short={1}
                        label={`${friendlyName}*`}
                        name={fieldName}
                        error={getError(fieldName)}
                      />
                    );
                  }
                  return (
                    <TextField
                      short={1}
                      type={isNumeric ? 'number' : 'text'}
                      key={`ndo-petition-form-${fieldName}`}
                      label={`${friendlyName}*`}
                      name={fieldName}
                      error={getError(fieldName)}
                    />
                  );
                })}
              </MultiFieldLine>
            </ScrollingModalContent>
            <ValidateOnMount />
            <ModalFooter>
              <InvertedButton style={{ minWidth: '100px' }} onClick={onClose}>
                Close
              </InvertedButton>
              <Button
                type="button"
                style={{ minWidth: '100px' }}
                disabled={numErrors > 0 || missingAssignees}
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                {Object.keys(fieldsChanged).length > 0 ? `Save & continue` : 'Continue'}
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
