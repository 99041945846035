import React from 'react';
import styled from 'styled-components';
import { ModalContent, ModalFooter, ModalHeader } from '../../general/Modal';
import { Button, InvertedButton } from '../../general/Button';
import { useSendNondisclosureEnvelopeQuery } from '../../../hooks/simpleRequests';
import { useCurrentApplication } from '../../../hooks/useCurrentApplication';
import { GREY_MEDIUM_DARK } from '../../../constants/cssVars';
import { getNondisclosurePetitionId } from './NondisclosureFields';
import { useSelector } from 'react-redux';
import { docusignFieldsModalInfoSelector } from '../../../selectors/modals';

const FooterNote = styled.p`
  text-align: right;
  margin: 0px;
  color: ${GREY_MEDIUM_DARK};
  font-size: 0.9em;
  font-style: italic;
`;

// todo - probably going to need to send caseId with envelope query .. entererestnggl
export const NondisclosurePetitionPreview = ({
  onClose,
  onEditFieldsClick,
  previewUrl,
  showSendEnvelope
}) => {
  const [sendEnvelope, sendingEnvelope] = useSendNondisclosureEnvelopeQuery(onClose);
  const { caseId } = useSelector(docusignFieldsModalInfoSelector);
  const application = useCurrentApplication();
  const envelopeId = getNondisclosurePetitionId(caseId, application);
  return (
    <>
      <ModalHeader>Preview nondisclosure petition</ModalHeader>
      <ModalContent>
        <p style={{ margin: '0px', fontSize: '0.95em' }}>
          This is what the nondisclosure petition and order currently look like with your populated
          fields.
        </p>
        <p style={{ marginTop: '0px', fontSize: '0.95em' }}>
          The white backgrounds behind the fields will not be shown in the final PDF.
        </p>
        <iframe width="100%" height="420" src={previewUrl} title="docusign preview" />
      </ModalContent>
      {showSendEnvelope && (
        <>
          <FooterNote>
            Form will be sent to <strong>{application.assigneeAttorney.email}</strong> for attorney
            signature.
          </FooterNote>
          <FooterNote>
            A copy of the completed document will be sent to{' '}
            <strong>{application.assigneeParalegal.email}</strong>.
          </FooterNote>
        </>
      )}
      <ModalFooter style={{ marginTop: '10px' }}>
        <InvertedButton style={{ minWidth: '100px' }} onClick={onClose}>
          Cancel
        </InvertedButton>
        <InvertedButton
          style={{ minWidth: '100px', marginRight: '10px' }}
          onClick={onEditFieldsClick}
        >
          Edit fields
        </InvertedButton>
        {showSendEnvelope ? (
          <Button
            style={{ minWidth: '100px' }}
            onClick={() => sendEnvelope({ envelopeId, appId: application._id, caseId })}
            isLoading={sendingEnvelope}
          >
            Send form
          </Button>
        ) : (
          <Button style={{ minWidth: '100px' }} onClick={onClose}>
            Done
          </Button>
        )}
      </ModalFooter>
    </>
  );
};
