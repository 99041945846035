import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApplicationPageSectionWrapper } from './ApplicationPage';
import {
  GREY_LIGHT,
  GREY_MEDIUM_DARK,
  GREY_MEDIUM_LIGHT,
  PRIMARY,
  SHADOW_CRISP,
  ERROR,
  GREY_DARK
} from '../../constants/cssVars';
import { ChevronIcon } from '../general/ChevronButton';
import checkmarkIcon from '../../assets/icons/checkmark.svg';
import { UserDisplay } from '../UserDisplay';
import { useProgressListObjs } from '../../hooks/useProgressListObjs';
import { useExpunctionCaseIds } from '../../hooks/useExpunctionCaseIds';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCaseModal,
  setCloseModal,
  setDocusignFieldsModal,
  setTaskModal
} from '../../actions/modals';
import { useLocation } from 'react-router-dom';
import { LinkLikeButton, SmallButton } from '../general/Button';
import { EXPUNCTION_LIST_NAME, NONDISCLOSURE_LIST_NAME } from '../../constants/progressTracking';
import { friendlyNamesSelector } from '../../selectors/entities';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';

const MAX_WIDTH_PROGRESS_LIST = 700;

const ProgressListWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  box-shadow: ${SHADOW_CRISP};
  background-color: white;
  max-width: ${MAX_WIDTH_PROGRESS_LIST}px;
  margin-bottom: 15px;
`;

// Header element that expands and contracts the progress list
const ProgressListClickableHeader = styled.button`
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: transparent;
  border: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1em;
  > div {
    display: flex;
    align-items: center;
    h4 {
      margin: 0px;
    }
    span {
      margin-left: 8px;
      font-style: italic;
      color: ${GREY_DARK};
    }
  }
  opacity: 1;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const ProgressListContent = styled.div`
  display: ${props => (props.expanded ? 'block' : 'none')};
  padding: 15px;
  padding-top: 0px;
  > div {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-right: 15px;
  }
`;

// Clickable name & circular checkbox to check off item
const CheckboxWrapper = styled.div`
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1em;
  // &:hover {
  //   > button:first-child {
  //     opacity: 0.9;
  //     background-color: ${props => (props.checked ? PRIMARY : GREY_LIGHT)};
  //   }
  //   > div:last-child {
  //     opacity: 0.8;
  //   }
  // }
`;

const NameAndDescription = styled.div`
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  p {
    text-align: left;
    margin: 0px;
    margin-top: 5px;
    color: ${GREY_MEDIUM_DARK};
    > strong {
      font-weight: 400;
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 6px;
`;

const CaseLinks = styled.div`
  position: relative;
  top: -5px;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 10px;
  > div {
    margin-top: 5px;
  }
  > div:first-child {
    margin-top: 0px;
  }
`;

const ExpunctionCaseLinks = styled(CaseLinks)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  > button {
    margin-left: 5px;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  > button:last-child {
    margin-top: -5px;
    padding: 0px;
    margin-left: 5px;
    margin-bottom: 15px;
    margin-right: 20px;
  }
`;

const CirclularCheckbox = styled.button`
  background-color: ${props => (props.checked ? PRIMARY : 'white')};
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 30px;
  margin-right: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  > img {
    width: 20px;
    height: 14px;
  }
  &:hover {
    opacity: 0.9;
    background-color: ${props => (props.checked ? PRIMARY : GREY_LIGHT)};
  }
`;

// Expandable list tile, displaying checkbox tasks for a given progress list object.
const ProgressList = ({ progressListObj }) => {
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const {
    displayName,
    listName,
    taskList,
    numCompleted,
    listIsComplete,
    casID,
    caseId
  } = progressListObj;
  const [expanded, setExpanded] = useState(!listIsComplete);
  const expunctionCaseIds = useExpunctionCaseIds();
  const friendlyNames = useSelector(friendlyNamesSelector);
  const application = useCurrentApplication();
  const isExpunctionList = listName === EXPUNCTION_LIST_NAME;
  const isNondisclosureList = listName === NONDISCLOSURE_LIST_NAME;
  const caseObj = caseId ? application.hcdcCases.find(hcdcCase => hcdcCase._id === caseId) : null;
  // if a closed reason is other than "completed", display as "closed" versus "completed"
  const notCompletedExpunction =
    isExpunctionList && application.expunctionClosedReason !== 'completed';
  const notCompletedNondisclosure =
    isNondisclosureList && caseObj.nondisclosureClosedReason !== 'completed';
  const closedForOtherReasonThanComplete =
    listIsComplete && (notCompletedExpunction || notCompletedNondisclosure);

  // If the hash (e.g. "agreement&feewaivertasks" in "www.oursite.com/application/appId123#agreement&feewaivertasks")
  // changes to refer to the current list, expand it.
  useEffect(() => {
    if (hash === `#${displayName}` && !expanded) {
      setExpanded(true);
    }
  }, [hash]);

  return (
    <ProgressListWrapper>
      <ProgressListClickableHeader onClick={() => setExpanded(!expanded)}>
        <div>
          <h4 id={displayName}>
            {displayName} ({numCompleted}/{taskList.length})
          </h4>
          {listIsComplete && (
            <span>{closedForOtherReasonThanComplete ? 'Closed' : 'Complete'}</span>
          )}
        </div>
        <ChevronIcon expanded={expanded} />
      </ProgressListClickableHeader>

      {(isExpunctionList || isNondisclosureList) && (
        <Links>
          {/* Show nondisclosure case if a casID is present */}
          {isNondisclosureList && (
            <CaseLinks>
              <LinkLikeButton onClick={() => dispatch(setCaseModal(true, caseId))}>
                View Case {casID}
              </LinkLikeButton>
            </CaseLinks>
          )}

          {/* Show expunction cases if expunction list */}
          {isExpunctionList && (
            <ExpunctionCaseLinks>
              {expunctionCaseIds.map(({ casID, caseId }) => {
                return (
                  <LinkLikeButton
                    key={`exp_${caseId}`}
                    onClick={() => dispatch(setCaseModal(true, caseId))}
                  >
                    View case {casID}
                  </LinkLikeButton>
                );
              })}
            </ExpunctionCaseLinks>
          )}

          {!listIsComplete && (
            <LinkLikeButton
              style={{ color: ERROR }}
              onClick={() => dispatch(setCloseModal(true, caseId, isExpunctionList))}
            >
              Close {isNondisclosureList ? 'case' : 'section'}
            </LinkLikeButton>
          )}
        </Links>
      )}

      <ProgressListContent expanded={expanded}>
        <div
          style={{
            color: GREY_MEDIUM_DARK,
            borderTop: `1px solid ${GREY_MEDIUM_LIGHT}`,
            paddingTop: '15px'
          }}
        >
          <div style={{ marginLeft: '5px' }}>
            <b>Task list</b>
          </div>
          <div>
            <b>Assignee</b>
          </div>
        </div>
        {taskList.map(task => {
          const showTaskModal = () => dispatch(setTaskModal(true, task.id, caseId));
          return (
            <div key={task.id}>
              <CheckboxWrapper checked={task.complete}>
                <CirclularCheckbox checked={task.complete} onClick={showTaskModal}>
                  {task.complete && <img src={checkmarkIcon} alt="checkmark" />}
                </CirclularCheckbox>
                <div>
                  <NameAndDescription>
                    <div style={{ fontWeight: 600 }}>{friendlyNames.progressFields[task.id]}</div>
                    <div>{task.description}</div>
                  </NameAndDescription>
                  <ButtonRow>
                    {task.id === 'sentLegalServicesAndFeeWaiver' && (
                      <SmallButton
                        disabled={application.sentLegalServicesAndFeeWaiver}
                        onClick={() => dispatch(setDocusignFieldsModal(true, task.id))}
                      >
                        Send form
                      </SmallButton>
                    )}
                    {task.id === 'expunctionGeneratedAndReviewedPetition' && (
                      <SmallButton
                        disabled={application.expunctionReviewedAndSignedOffPetition}
                        onClick={() => dispatch(setDocusignFieldsModal(true, task.id))}
                      >
                        Generate form
                      </SmallButton>
                    )}
                    {task.id === 'expunctionReviewedAndSignedOffPetition' && (
                      <SmallButton
                        disabled={application.expunctionReviewedAndSignedOffPetition}
                        onClick={() => dispatch(setDocusignFieldsModal(true, task.id))}
                      >
                        Review and send form for signing
                      </SmallButton>
                    )}
                    {task.id === 'nondisclosureGeneratedAndReviewedPetition' && (
                      <>
                        {caseObj.ndo_form === '411.0728' ? (
                          <SmallButton
                            onClick={() => dispatch(setTaskModal(true, task.id, caseId))}
                          >
                            Manually generate form
                          </SmallButton>
                        ) : (
                          <SmallButton
                            disabled={caseObj.nondisclosureReviewedAndSignedOffPetition}
                            onClick={() => dispatch(setDocusignFieldsModal(true, task.id, caseId))}
                          >
                            Generate form
                          </SmallButton>
                        )}
                      </>
                    )}
                    {task.id === 'nondisclosureReviewedAndSignedOffPetition' &&
                      caseObj.ndo_form !== '411.0728' && (
                        <SmallButton
                          disabled={caseObj.nondisclosureReviewedAndSignedOffPetition}
                          onClick={() => dispatch(setDocusignFieldsModal(true, task.id, caseId))}
                        >
                          Review and send form for signing
                        </SmallButton>
                      )}
                  </ButtonRow>
                </div>
              </CheckboxWrapper>
              <div style={{ paddingRight: '15px' }}>
                <UserDisplay userObj={task.assignee} />
              </div>
            </div>
          );
        })}
      </ProgressListContent>
    </ProgressListWrapper>
  );
};

// Display current progress & steps for application
export const ProgressSection = () => {
  const progressListObjs = useProgressListObjs();
  const dispatch = useDispatch();

  return (
    <>
      <ApplicationPageSectionWrapper>
        <h3>Tasks</h3>
        {progressListObjs.length > 0 ? (
          progressListObjs.map((progressListObj, index) => {
            return (
              <ProgressList progressListObj={progressListObj} key={`progress_list_${index}`} />
            );
          })
        ) : (
          <div style={{ marginBottom: '200px', maxWidth: '600px' }}>
            <p>
              This application currently has no cases marked as eligible to move forward with.
              Please <em>edit case eligibility</em> or <em>add missing cases</em> in the{' '}
              <strong>Cases</strong> section above.
            </p>
            <p>
              If there are no eligible cases for this client, you can{' '}
              <LinkLikeButton
                style={{ color: ERROR, display: 'inline-block' }}
                onClick={() => dispatch(setCloseModal(true))}
              >
                close this application
              </LinkLikeButton>
              .
            </p>
          </div>
        )}
      </ApplicationPageSectionWrapper>
    </>
  );
};
