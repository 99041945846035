import { useEffect } from 'react';

// Component to call a function whenever enter is pressed on the keyboard
export const EnterKeyPressListener = ({ onEnter }) => {
  const onKeyDown = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      onEnter();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onEnter]);

  return null;
};
