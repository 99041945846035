import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEditUserModal } from '../../actions/modals';
import { editUserModalInfoSelector, editUserModalSelector } from '../../selectors/modals';
import { Modal, ModalContent, ModalFooter, ModalHeader } from '../general/Modal';
import { Formik } from 'formik';
import { Button, InvertedButton } from '../general/Button';
import { validateEditUserName } from '../../validators';
import { OptionsList, SelectField, TextField } from '../FormFields';
import { getFieldsChanged, getUserTypeString } from '../../utils/general';
import { toast } from 'react-toastify';
import { currentUserSelector } from '../../selectors/entities';
import { useUpdateUserRole, useUpdateUserName } from '../../hooks/simpleRequests';

// Modal to edit a user first and last name (and if admin, edit role as well)
export const EditUserModal = () => {
  const showModal = useSelector(editUserModalSelector);
  const dispatch = useDispatch();
  const { selectedUser } = useSelector(editUserModalInfoSelector);
  const currentUser = useSelector(currentUserSelector);

  const onClose = () => {
    dispatch(setEditUserModal(false));
  };

  const [updateUserRole, updatingUserRole] = useUpdateUserRole(onClose);
  const [updateUserName, updatingUserName] = useUpdateUserName(onClose);

  if (!showModal) {
    return null;
  }

  const initialValues = {
    firstName: selectedUser.firstName,
    lastName: selectedUser.lastName,
    role: getUserTypeString(selectedUser).toLowerCase()
  };

  const submit = values => {
    // get fields changed with standardized dates
    const fieldsChanged = getFieldsChanged(values, initialValues);

    // Check if at least one field has changed
    if (Object.keys(fieldsChanged).length > 0) {
      const userId = selectedUser._id;
      if (fieldsChanged.role) {
        updateUserRole({ userId, role: values.role });
      }
      if (fieldsChanged.firstName || fieldsChanged.lastName) {
        updateUserName({ userId, firstName: values.firstName, lastName: values.lastName });
      }
    } else {
      toast.info('No changes have been made', {
        closeButton: false,
        duration: 2000
      });
    }
  };

  return (
    <Modal onClose={onClose}>
      <Formik validate={validateEditUserName} initialValues={initialValues} onSubmit={submit}>
        {({ handleSubmit, errors, touched, values, submitCount }) => {
          const getError = name => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <>
              <ModalHeader>Edit user information</ModalHeader>
              <ModalContent>
                <TextField
                  label="First name"
                  name="firstName"
                  short={1}
                  error={getError('firstName')}
                />
                <TextField
                  label="Last name"
                  name="lastName"
                  short={1}
                  error={getError('lastName')}
                />
                {currentUser.admin && (
                  <SelectField label="Role" name="role" short={1}>
                    <OptionsList
                      hideEmptyOption
                      emptyOptionLabel
                      options={[
                        { label: 'Attorney', value: 'attorney' },
                        { label: 'Paralegal', value: 'paralegal' },
                        { label: 'Admin', value: 'admin' }
                      ]}
                    />
                  </SelectField>
                )}
              </ModalContent>
              <ModalFooter style={{ marginTop: '30px' }}>
                <InvertedButton onClick={onClose}>Cancel</InvertedButton>
                <Button isLoading={updatingUserRole && updatingUserName} onClick={handleSubmit}>
                  Submit
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
