import React from 'react';
import styled from 'styled-components';
import { ModalFooter, ModalHeader, ScrollingModalContent } from '../../general/Modal';
import { Button, InvertedButton, SmallButton } from '../../general/Button';
import {
  ERROR,
  GREY_DARK,
  GREY_MEDIUM_DARK,
  GREY_MEDIUM_LIGHT,
  SHADOW_LIGHT
} from '../../../constants/cssVars';
import {
  DatepickerField,
  MultiFieldLine,
  OptionsList,
  SelectField,
  TextField
} from '../../FormFields';
import { validateExpunctionCaseFields, validateExpunctionFields } from '../../../validators';
import { Formik } from 'formik';
import { useCurrentApplication } from '../../../hooks/useCurrentApplication';
import {
  useCreateExpunctionEnvelopeQuery,
  useEnvelopePreviewQuery,
  useUpdateClientInfo,
  useUpdateExpunctionEnvelopeQuery
} from '../../../hooks/simpleRequests';
import { getFieldsChanged } from '../../../utils/general';
import { ValidateOnMount } from '../../general/ValidateOnMount';
import { useDispatch, useSelector } from 'react-redux';
import { setExpunctionCaseFieldsModal } from '../../../actions/modals';
import { ErrorsBanner, getErrorText, MissingAttorneyBanner } from './ErrorsBanner';
import { SharedFieldsNote } from './SharedFieldsNote';
import {
  friendlyNamesSelector,
  docusignExpunctionVersionSelector
} from '../../../selectors/entities';
import { useAgencyInitialValues } from '../AgencyFields';
import { getExpunctionCaseInitialValues } from './ExpunctionCaseFieldsModal';

const SectionHeader = styled.h3`
  margin: 0px;
`;

const CaseSection = styled.div`
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  background-color: white;
  box-shadow: ${SHADOW_LIGHT};
  border-radius 6px;
  padding: 10px 15px;
  padding-bottom: 15px;
  margin: 10px 0px;
  > p {
    margin: 0px;
    margin-bottom: 10px;
  }
  > p:first-child {
    font-weight: 600;
    color: ${GREY_MEDIUM_DARK};
    font-size: 0.95em;
  }
`;

export const EXPUNCTION_PETITION_PRO_SE_REQUIRED_FIELDS = [
  'firstName',
  'lastName',
  'dob',
  'streetAddress',
  'city',
  'state',
  'zipcode',
  'race',
  'sex'
];
export const EXPUNCTION_PETITION_ATTORNEY_REQUIRED_FIELDS = [
  'firstName',
  'lastName',
  'dob',
  'idNumber',
  'ssnFour',
  'streetAddress',
  'city',
  'state',
  'zipcode',
  'race',
  'sex'
];

// Form for expunction petition fields, to be displayed in DocusignFieldsModal
export const ExpunctionFields = ({ onClose, onNext, reviewFormFields }) => {
  const application = useCurrentApplication();
  const dispatch = useDispatch();
  const envelopeId = application.expunctionPetitionEnvelopeId;
  const friendlyNamesObj = useSelector(friendlyNamesSelector);
  const friendlyNames = friendlyNamesObj.editableAppFields;
  const docusignExpunctionVersion = useSelector(docusignExpunctionVersionSelector);

  // if there's an envelope id and the doc version hasn't changed, use the update hook, otherwise, use the create hook (have to keep separate for expunction, see "TODO" notes)
  const [createEnvelope, creatingEnvelope] = useCreateExpunctionEnvelopeQuery(body =>
    generatePreview({ envelopeId: body.app.expunctionPetitionEnvelopeId, appId: application._id })
  );
  const [updateEnvelope, updatingEnvelope] = useUpdateExpunctionEnvelopeQuery(body =>
    generatePreview({ envelopeId: body.app.expunctionPetitionEnvelopeId, appId: application._id })
  );
  const [generatePreview, generatingPreview] = useEnvelopePreviewQuery(body => onNext(body.url));
  const [updateClient, updatingClient] = useUpdateClientInfo(body => {
    if (body.app.expunctionPetitionEnvelopeId) {
      if (body.app.expunctionPetitionDocVersion === docusignExpunctionVersion) {
        updateEnvelope({
          envelopeId: body.app.expunctionPetitionEnvelopeId,
          appId: application._id
        });
      } else {
        createEnvelope({ appId: application._id });
      }
    } else {
      createEnvelope({ appId: application._id });
    }
  }, true);
  const isLoading = updatingClient || creatingEnvelope || updatingEnvelope || generatingPreview;
  const expunctionCases = (application.hcdcCases || []).filter(
    caseObj => caseObj.final_eligibility === 'expunction'
  );
  // both an assigned attorney and assigned paralegal must be present to sign & receive docusign forms
  const missingAssignees = !application.assigneeAttorney || !application.assigneeParalegal;

  const initialValues = {
    firstName: application.firstName || '',
    middleName: application.middleName || '',
    lastName: application.lastName || '',
    suffix: application.suffix || '',
    aliases: application.aliases || '',
    dob: application.dob || '',
    idNumber: application.idNumber || '',
    ssnFour: application.ssnFour || '',
    streetAddress: application.streetAddress || '',
    streetAddress2: application.streetAddress2 || '',
    city: application.city || '',
    state: application.state || '',
    zipcode: application.zipcode || '',
    race: application.race || '', // initialize with case data
    sex: application.sex || '', // initialize with case data
    email: application.email || '',
    phone: application.phone || '',
    expunctionUseProSeForm:
      typeof application.expunctionUseProSeForm === 'boolean'
        ? application.expunctionUseProSeForm
        : 'false'
  };

  const agencyInitialValuesArray = useAgencyInitialValues(expunctionCases);

  const submit = values => {
    const fieldsChanged = getFieldsChanged(values, initialValues, { dob: { isDate: 'true' } });
    if (Object.keys(fieldsChanged).length > 0) {
      updateClient({
        fieldsChanged,
        appId: application._id
      });
    } else {
      if (envelopeId) {
        if (application.expunctionPetitionDocVersion === docusignExpunctionVersion) {
          updateEnvelope({ envelopeId, appId: application._id });
        } else {
          createEnvelope({ appId: application._id });
        }
      } else {
        createEnvelope({ appId: application._id });
      }
    }
  };

  return (
    <Formik
      validate={validateExpunctionFields}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={submit}
    >
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        const getError = name => errors[name];
        const fieldsChanged = getFieldsChanged(values, initialValues, { dob: { isDate: 'true' } });
        const numAppErrors = Object.keys(errors).length;
        const numCaseErrors = expunctionCases.reduce((total, caseObj, index) => {
          const initialValues = getExpunctionCaseInitialValues(
            caseObj,
            agencyInitialValuesArray[index]
          );
          total = total + Object.keys(validateExpunctionCaseFields(initialValues)(caseObj)).length;
          return total;
        }, 0);
        const numErrors = numAppErrors + numCaseErrors;
        return (
          <>
            <ModalHeader>{reviewFormFields ? 'Review' : 'Fill out'} form fields</ModalHeader>
            <ErrorsBanner numErrors={numErrors} />
            <MissingAttorneyBanner />
            <ScrollingModalContent height={400}>
              <SharedFieldsNote />
              <MultiFieldLine style={{ margin: '15px 0px 20px 0px' }} columns="50% 50%">
                <SelectField
                  label={`${friendlyNames.expunctionUseProSeForm}*`}
                  name="expunctionUseProSeForm"
                  error={getError('expunctionUseProSeForm')}
                >
                  <OptionsList
                    hideEmptyOption={true}
                    options={[
                      { label: 'Attorney signing (default)', value: 'false' },
                      { label: 'Pro-se', value: 'true' }
                    ]}
                  />
                </SelectField>
              </MultiFieldLine>
              <MultiFieldLine style={{ margin: '15px 0px 20px 0px' }} columns="50% 50%">
                <TextField
                  label={`${friendlyNames['firstName']}*`}
                  name="firstName"
                  error={getError('firstName')}
                />
                <TextField label={`${friendlyNames['middleName']}`} name="middleName" />
                <TextField
                  label={`${friendlyNames['lastName']}*`}
                  name="lastName"
                  error={getError('lastName')}
                />
                <SelectField label={`${friendlyNames['suffix']}`} name="suffix">
                  <OptionsList
                    options={[
                      { label: 'Jr', value: 'Jr' },
                      { label: 'Sr', value: 'Sr' },
                      { label: 'II', value: 'II' },
                      { label: 'III', value: 'III' },
                      { label: 'IV', value: 'IV' }
                    ]}
                  />
                </SelectField>
                <TextField label={`${friendlyNames['aliases']}`} name="aliases" />
                <DatepickerField
                  placement="top-start"
                  label="Client Date of Birth*"
                  name="dob"
                  error={getError('dob')}
                />
                <TextField
                  label={`${friendlyNames['ssnFour']}${values.expunctionUseProSeForm ? '' : '*'}`}
                  name="ssnFour"
                  error={getError('ssnFour')}
                  note="Enter last 4 digits of SSN, 'N/A', or 'Unknown'"
                />
                <TextField
                  label={`${friendlyNames['idNumber']}${values.expunctionUseProSeForm ? '' : '*'}`}
                  name="idNumber"
                  error={getError('idNumber')}
                  note="Enter ID number, 'N/A', or 'Unknown'"
                />
                <TextField
                  label={`${friendlyNames['race']}*`}
                  name="race"
                  error={getError('race')}
                />
                <TextField label={`${friendlyNames['sex']}*`} name="sex" error={getError('sex')} />
                <TextField
                  label={`${friendlyNames['streetAddress']}*`}
                  name="streetAddress"
                  error={getError('streetAddress')}
                />
                <TextField
                  label={`${friendlyNames['streetAddress2']}*`}
                  name="streetAddress2"
                  error={getError('streetAddress2')}
                />
                <TextField
                  label={`${friendlyNames['city']}*`}
                  name="city"
                  error={getError('city')}
                />
                <TextField
                  label={`${friendlyNames['state']}*`}
                  name="state"
                  error={getError('state')}
                />
                <TextField
                  label={`${friendlyNames['zipcode']}*`}
                  name="zipcode"
                  error={getError('zipcode')}
                />
                {values.expunctionUseProSeForm && values.expunctionUseProSeForm !== 'false' && (
                  <TextField
                    label={`${friendlyNames['email']}*`}
                    name="email"
                    error={getError('email')}
                  />
                )}
                {values.expunctionUseProSeForm && values.expunctionUseProSeForm !== 'false' && (
                  <TextField
                    label={`${friendlyNames['phone']}*`}
                    name="phone"
                    error={getError('phone')}
                  />
                )}
              </MultiFieldLine>

              <SectionHeader>Case fields</SectionHeader>
              {expunctionCases.map((caseObj, index) => {
                const initialValues = getExpunctionCaseInitialValues(
                  caseObj,
                  agencyInitialValuesArray[index]
                );
                const caseErrors = validateExpunctionCaseFields(initialValues)(caseObj);
                const numErrors = Object.keys(caseErrors).length;
                return (
                  <CaseSection key={caseObj.casID}>
                    <p>
                      Case {caseObj.casID} ({caseObj.current_offense_name})
                    </p>
                    <p style={{ color: numErrors > 0 ? ERROR : GREY_DARK }}>
                      {numErrors > 0 && getErrorText(numErrors, 'for this case')}
                    </p>
                    <SmallButton
                      onClick={() => dispatch(setExpunctionCaseFieldsModal(true, caseObj._id))}
                    >
                      Edit case fields
                    </SmallButton>
                  </CaseSection>
                );
              })}
            </ScrollingModalContent>
            <ValidateOnMount />
            <ModalFooter>
              <InvertedButton style={{ minWidth: '100px' }} onClick={onClose}>
                Close
              </InvertedButton>
              <Button
                style={{ minWidth: '100px' }}
                disabled={numErrors > 0 || missingAssignees}
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                {Object.keys(fieldsChanged).length > 0 ? `Save & continue` : 'Continue'}
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
