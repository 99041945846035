import React from 'react';
import styled from 'styled-components';
import { Field, useFormikContext } from 'formik';
import closeIcon from '../assets/icons/close.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  ERROR,
  GREY_DARK,
  GREY_LIGHTEST,
  GREY_MEDIUM_LIGHT,
  MAX_DESKTOP_WIDTH
} from '../constants/cssVars';

const SHORT_WIDTH = 300;

const ErrorText = styled.label`
  margin-bottom: 2px;
  font-size: 0.9em;
  display: block;
  color: ${ERROR};
  font-style: italic;
`;

const LabelledInput = styled.div`
  & > label {
    color: ${GREY_DARK};
    margin-bottom: 2px;
    padding-bottom: ${({ smallerText }) => (smallerText ? '1' : '0')}px;
    font-size: ${({ smallerText }) => (smallerText ? '0.8' : '0.9')}em;
    display: block;
    ${({ error }) => (error ? `color: ${ERROR};` : '')}
  }
  width: ${({ short }) => (short ? `${SHORT_WIDTH}px` : '100%')};
  margin: 5px 0px;
  margin-bottom: 15px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0px;
  margin-bottom: 2px;
`;

const DeleteIcon = styled.img`
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
`;

export const DeleteButton = ({ onDelete }) => <DeleteIcon onClick={onDelete} src={closeIcon} />;

// Wrapper for field inputs, displaying labels and helper text
export const FieldWrapper = ({
  label,
  width,
  field,
  children,
  style,
  inputStyle,
  short,
  error,
  note,
  onDelete,
  hideLabel,
  id
}) => {
  const { required } = field || {};
  return (
    <LabelledInput style={style} short={short} error={error}>
      {!hideLabel && (
        <label htmlFor={id}>
          {label}
          {field && required ? '*' : ''}
        </label>
      )}
      <InputWrapper style={inputStyle}>
        {children}
        {onDelete && <DeleteButton onDelete={onDelete} />}
      </InputWrapper>
      {error && (
        <label id={`errorMsg_${id}`} style={{ fontStyle: 'italic' }}>
          {error}
        </label>
      )}
      {note && (
        <label id={`errorMsg_${id}`} style={{ fontStyle: 'italic', display: 'block' }}>
          {note}
        </label>
      )}
    </LabelledInput>
  );
};

export const TextField = ({ name, placeholder, ...props }) => (
  <FieldWrapper {...props} id={name}>
    <Field
      id={name}
      name={name}
      placeholder={placeholder}
      aria-describedby={`errorMsg_${name}`}
      type={props.type}
      aria-invalid={props.error ? 'true' : 'false'}
    />
  </FieldWrapper>
);

export const ParagraphTextField = ({ name, rows, placeholder, ...props }) => (
  <FieldWrapper {...props} id={name}>
    <Field
      id={name}
      as="textarea"
      rows={rows}
      name={name}
      placeholder={placeholder}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={props.error ? 'true' : 'false'}
    />
  </FieldWrapper>
);

export const SelectField = ({ name, children, placeholder, onChange, ...props }) => (
  <FieldWrapper {...props}>
    <Field
      id={name}
      as="select"
      name={name}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={props.error ? 'true' : 'false'}
    >
      {children}
    </Field>
  </FieldWrapper>
);

const RadioButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 10px 0px;
  margin-right: 20px;
`;

const radioInputStyle = {
  backgroundColor: GREY_LIGHTEST,
  padding: '3px 8px',
  borderRadius: '10px',
  marginTop: '8px',
  border: `1px solid ${GREY_MEDIUM_LIGHT}`
};

export const RadioButtonField = ({ name, options, ...props }) => (
  <FieldWrapper {...props} inputStyle={radioInputStyle}>
    {options.map(option => (
      <RadioButtonWrapper key={option.value}>
        <Field
          id={name}
          style={{ width: 'auto' }}
          type="radio"
          value={option.value}
          name={name}
          aria-describedby={`errorMsg_${name}`}
          aria-invalid={props.error ? 'true' : 'false'}
        />
        <div style={{ marginLeft: '5px' }}>{option.label}</div>
      </RadioButtonWrapper>
    ))}
  </FieldWrapper>
);

const CheckboxWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  ${({ wrapperStyle }) => wrapperStyle};
  margin: 10px 0px;
  margin-right: 15px;
`;

const Checkbox = ({ name, value, error, testId, label, children, wrapperStyle }) => (
  <CheckboxWrapper wrapperStyle={wrapperStyle}>
    <Field
      id={name}
      data-testid={testId}
      className="regular-checkbox"
      type="checkbox"
      name={name}
      value={value}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={error ? 'true' : 'false'}
    />
    {children}
    {label}
  </CheckboxWrapper>
);

export const CheckboxField = ({ error, ...props }) => (
  <div>
    <Checkbox {...props} />
    {error && <ErrorText>{error}</ErrorText>}
  </div>
);

const CheckboxGroupWrapper = styled.div`
  background-color: ${GREY_LIGHTEST};
  padding: 3px 8px;
  border-radius: 10px;
  margin-top: 8px;
  border: 1px solid ${GREY_MEDIUM_LIGHT};
`;

export const CheckboxGroup = ({
  checkboxOptions,
  name,
  error,
  labelId,
  label,
  style,
  checkboxStyle
}) => (
  <LabelledInput error={error}>
    <label id={labelId}>{label}</label>
    <CheckboxGroupWrapper
      role="group"
      aria-labelledby={labelId}
      style={style}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={error ? 'true' : 'false'}
    >
      {checkboxOptions.map(props => (
        <Checkbox {...props} name={name} wrapperStyle={checkboxStyle} key={props.value} />
      ))}
    </CheckboxGroupWrapper>
    {error && <ErrorText>{error}</ErrorText>}
  </LabelledInput>
);

export const MultiFieldLine = styled.div`
  width: 100%;
  width: calc(100% - 15px);
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: ${({ columns }) => columns || 'auto auto'};
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH + 100}px) {
    display: flex;
    flex-direction: column;
    > div {
      max-width: 400px;
    }
  }
`;

export const OptionsList = ({ hideEmptyOption, emptyOptionLabel, options, name }) => {
  const fullOptions = [
    ...(hideEmptyOption ? [] : [{ value: '', label: emptyOptionLabel || 'Select One' }]),
    ...options
  ];
  return fullOptions.map(option => (
    <option key={`${name}_${option.value}`} value={option.value}>
      {option.label}
    </option>
  ));
};

// Adjust the date for React Datepicker, since our dates are in UTC & React Datepicker uses the local timezone
const getModifiedDate = dateValue => {
  const date = new Date(dateValue);
  const timeDiff = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.valueOf() + timeDiff);
  return adjustedDate;
};

export const DatepickerField = props => {
  const { setFieldValue, values } = useFormikContext();
  const handleChange = newValue => {
    setFieldValue(props.name, newValue);
  };

  const dateValue = values[props.name] ? getModifiedDate(values[props.name]) : null;

  return (
    <FieldWrapper {...props}>
      <DatePicker
        popperProps={{ strategy: 'fixed' }}
        popperPlacement={props.placement || 'top-start'}
        selected={dateValue}
        onChange={handleChange}
      />
    </FieldWrapper>
  );
};

export const ManualSelectField = ({ name, children, placeholder, onChange, value, ...props }) => (
  <FieldWrapper {...props}>
    <select id={name} onChange={onChange} value={value}>
      {children}
    </select>
  </FieldWrapper>
);
