import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { GREY_MEDIUM, MAIN_CONTENT_WIDTH, PRIMARY, SECONDARY } from '../../constants/cssVars';

export const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ color }) => color || SECONDARY};
`;

export const StyledInternalLink = styled(HashLink)`
  cursor: pointer;
  color: ${({ color }) => color || PRIMARY};
  font-weight: 600;
`;

export const TextLink = ({ to, color = PRIMARY, style, children }) => {
  return (
    <StyledLink color={color} style={style} target="_blank" rel="noreferrer noopener" href={to}>
      {children}
    </StyledLink>
  );
};

export const StyledInput = styled.input`
  border: 1px solid ${GREY_MEDIUM};
  border-radius: 20px;
  padding: 5px 15px !important;
  &:focus {
    outline: none !important;
    border: 1px solid #1591bf;
    border-radius: 20px;
    box-shadow: 0 0 2px 2px rgb(93, 196, 255);
  }
`;

const FullWidthPageContent = styled.div`
  width: ${MAIN_CONTENT_WIDTH}px;
  padding: 20px;
  box-sizing: border-box;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
`;

const FullWidthPageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

// Styling for a full width page; constraining content to a max of MAIN_CONTENT_WIDTH
export const FullWidthPage = ({ children, style }) => {
  return (
    <FullWidthPageWrapper>
      <FullWidthPageContent style={style}>{children}</FullWidthPageContent>
    </FullWidthPageWrapper>
  );
};
