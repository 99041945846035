import React from 'react';
import styled from 'styled-components';
import { FORM_ID_411_072, FORM_ID_411_0735, FORM_ID_411_0736 } from '../../constants/cases';
import { BG_LIGHTER, BG_PRIMARY, ERROR, MAX_DESKTOP_WIDTH } from '../../constants/cssVars';
import pencilIcon from '../../assets/icons/edit_white.svg';
import { Button, LinkLikeButton } from '../general/Button';
import { ModalFooter, ModalHeader, ScrollingModalContent } from '../general/Modal';
import { SpecialConditionsMessage } from './SpecialConditionsMessage';
import { useSelector } from 'react-redux';
import { friendlyNamesSelector } from '../../selectors/entities';
import moment from 'moment';
import { SameCriminalEpisodeMessage } from './SameCriminalEpisodeMessage';

// fields that we don't want to show in general case edit modal since they are only used for form field purposes
export const caseFieldsToSkip = [
  'isDrugRelated',
  'ndo_0735_date_or_second_anniversary',
  'ndo_0725_is_072_ineligible_reason',
  'ndo_0728_unable_to_provide_assistance',
  'ndo_0728_previously_filed',
  'used_ignition_interlock',
  'treatment_court_completion_date',
  'bwi',
  'arrest_date',
  'offense_date',
  'pled_guilty_date',
  'court_deferral_date',
  'deferred_adjudication',
  'sentence_date',
  'offense_committed_date',
  'previously_filed_date'
];

const Wrapper = styled.div`
  > div:first-child {
    border-radius: 4px 4px 0px 0px;
  }
  > div:nth-child(odd) {
    background-color: ${BG_PRIMARY};
  }
  > div:nth-child(even) {
    background-color: ${BG_LIGHTER};
  }
  > div:last-child {
    border-radius: 0px 0px 4px 4px;
  }
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  > div:first-child {
    font-weight: 600;
  }
  padding: 8px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: block;
  }
`;

// The `waiting_period_start` field means different things depending on the case
export const getWaitingPeriodStartFieldName = caseObj => {
  const {
    waiting_period_start,
    ndo_form,
    final_eligibility,
    final_pot_elig_411_0736,
    final_pot_elig_411_0727
  } = caseObj;

  // Only show if waiting_period_start is nonnull & the case is eligible
  if (waiting_period_start == null || final_eligibility !== 'nondisclosure') {
    return null;
  }

  // Show as date of sentence completion for 411.0735 and 411.0736
  if (ndo_form === FORM_ID_411_0735 || ndo_form === FORM_ID_411_0736 || final_pot_elig_411_0736) {
    return 'Date of sentence completion';
  }

  // Show as discharge and dismissal for 411.072 and 411.0727
  if (ndo_form === FORM_ID_411_072 || final_pot_elig_411_0727) {
    return 'Date of discharge and dismissal';
  }

  return null;
};

// Displays all of the editable case fields with modal formatting & an edit button
export const CaseInformation = ({ caseObj, onEditClick, onDeleteClick }) => {
  const { waiting_period_start } = caseObj;
  const waitingPeriodFieldName = getWaitingPeriodStartFieldName(caseObj);
  const friendlyNames = useSelector(friendlyNamesSelector);
  const caseFieldFriendlyNames = friendlyNames.editableCaseFields;
  const caseFields = Object.keys(caseFieldFriendlyNames).filter(
    fieldName => !caseFieldsToSkip.includes(fieldName)
  );

  return (
    <>
      <ModalHeader>Case Information</ModalHeader>
      <ScrollingModalContent height={430} heightOffset={250}>
        <SpecialConditionsMessage caseObj={caseObj} />
        <SameCriminalEpisodeMessage caseObj={caseObj} />
        <Wrapper>
          {caseFields.map(fieldName => {
            const friendlyName = caseFieldFriendlyNames[fieldName];
            let value = caseObj[fieldName];
            // for def_dob, which is a date field (not string), format as YYYY-MM-DD
            if (fieldName === 'def_dob') {
              value = moment(caseObj[fieldName])
                .utc()
                .format('YYYY-MM-DD');
            }
            if (value === true || value === false) {
              value = value ? 'Yes' : 'No';
            }
            return (
              <LineItem key={`case-info-${fieldName}`}>
                <div>{friendlyName}</div>
                <div>{value}</div>
              </LineItem>
            );
          })}
          {/* TODO: Possibly remove this depending on conversation with Erin (https://app.asana.com/0/1201676779453585/1202130918205217/f) */}
          {waitingPeriodFieldName && (
            <LineItem>
              <div>{waitingPeriodFieldName}</div>
              <div>{waiting_period_start}</div>
            </LineItem>
          )}
        </Wrapper>
      </ScrollingModalContent>
      {onEditClick && (
        <ModalFooter style={{ justifyContent: 'space-between' }}>
          <LinkLikeButton style={{ color: ERROR }} onClick={onDeleteClick}>
            Delete case
          </LinkLikeButton>
          <Button onClick={onEditClick}>
            Edit information{' '}
            <img src={pencilIcon} style={{ position: 'relative', top: '2px' }} alt="pencil icon" />
          </Button>
        </ModalFooter>
      )}
    </>
  );
};
