import React from 'react';
import { ModalFooter, ModalHeader, ScrollingModalContent } from '../../general/Modal';
import { Button, InvertedButton } from '../../general/Button';
import { MultiFieldLine, OptionsList, SelectField, TextField } from '../../FormFields';
import { validateLegalServicesAgreement } from '../../../validators';
import { Formik } from 'formik';
import { useCurrentApplication } from '../../../hooks/useCurrentApplication';
import {
  useCreateLegalServicesEnvelopeQuery,
  useEnvelopePreviewQuery,
  useUpdateClientInfo,
  useUpdateLegalServicesEnvelopeQuery
} from '../../../hooks/simpleRequests';
import { getFieldsChanged } from '../../../utils/general';
import { ValidateOnMount } from '../../general/ValidateOnMount';
import { ErrorsBanner, MissingAttorneyBanner } from './ErrorsBanner';
import { SharedFieldsNote } from './SharedFieldsNote';
import { useSelector } from 'react-redux';
import {
  friendlyNamesSelector,
  docusignLegalServicesAgreementVersionSelector
} from '../../../selectors/entities';

export const LEGAL_SERVICES_REQUIRED_FIELDS = ['firstName', 'lastName', 'email'];

// Form for legal services fields, to be displayed in DocusignFieldsModal
export const LegalServicesFields = ({ onClose, onNext }) => {
  const application = useCurrentApplication();
  const envelopeId = application.legalAgreementEnvelopeId;
  const docusignLegalAgreementVersion = useSelector(docusignLegalServicesAgreementVersionSelector);
  // if there's an envelopeId and the doc version hasn't changed, use the update hook, otherwise, use the create hook
  let useSaveEnvelope;
  if (envelopeId) {
    if (application.legalAgreementDocVersion === docusignLegalAgreementVersion) {
      useSaveEnvelope = useUpdateLegalServicesEnvelopeQuery;
    } else {
      useSaveEnvelope = useCreateLegalServicesEnvelopeQuery;
    }
  } else {
    useSaveEnvelope = useCreateLegalServicesEnvelopeQuery;
  }
  const [generatePreview, generatingPreview] = useEnvelopePreviewQuery(body => onNext(body.url));
  const [saveEnvelope, savingEnvelope] = useSaveEnvelope(body =>
    generatePreview({
      envelopeId: body.app.legalAgreementEnvelopeId || envelopeId,
      appId: application._id
    })
  );
  const [updateClient, updatingClient] = useUpdateClientInfo(() => {
    saveEnvelope({ envelopeId, appId: application._id });
  }, true);
  const isLoading = updatingClient || savingEnvelope || generatingPreview;
  const friendlyNamesObj = useSelector(friendlyNamesSelector);
  const friendlyNames = friendlyNamesObj.editableAppFields;

  const initialValues = {
    firstName: application.firstName || '',
    middleName: application.middleName || '',
    lastName: application.lastName || '',
    suffix: application.suffix || '',
    email: application.email || ''
  };

  const submit = values => {
    const fieldsChanged = getFieldsChanged(values, initialValues);
    if (Object.keys(fieldsChanged).length > 0) {
      updateClient({
        fieldsChanged,
        appId: application._id
      });
    } else {
      saveEnvelope({ envelopeId, appId: application._id });
    }
  };

  // both an assigned attorney and assigned paralegal must be present to sign & receive docusign forms
  const missingAssignees = !application.assigneeAttorney || !application.assigneeParalegal;

  return (
    <Formik
      validate={validateLegalServicesAgreement}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={submit}
    >
      {({ handleSubmit, errors }) => {
        const getError = name => errors[name];
        const numErrors = Object.keys(errors).length;
        return (
          <>
            <ModalHeader>Fill out form fields</ModalHeader>
            <ErrorsBanner numErrors={numErrors} />
            <MissingAttorneyBanner />
            <ScrollingModalContent height={400}>
              <SharedFieldsNote />
              <MultiFieldLine style={{ margin: '15px 0px 20px 0px' }}>
                <TextField
                  label={`${friendlyNames['firstName']}*`}
                  name="firstName"
                  error={getError('firstName')}
                />
                <TextField label={`${friendlyNames['middleName']}`} name="middleName" />
                <TextField
                  label={`${friendlyNames['lastName']}*`}
                  name="lastName"
                  error={getError('lastName')}
                />
                <SelectField label={`${friendlyNames['suffix']}`} name="suffix">
                  <OptionsList
                    options={[
                      { label: 'Jr', value: 'Jr' },
                      { label: 'Sr', value: 'Sr' },
                      { label: 'II', value: 'II' },
                      { label: 'III', value: 'III' },
                      { label: 'IV', value: 'IV' }
                    ]}
                  />
                </SelectField>
                <TextField
                  label={`${friendlyNames['email']}*`}
                  name="email"
                  error={getError('email')}
                />
              </MultiFieldLine>
            </ScrollingModalContent>
            <ValidateOnMount />
            <ModalFooter>
              <InvertedButton style={{ minWidth: '100px' }} onClick={onClose}>
                Close
              </InvertedButton>
              <Button
                style={{ minWidth: '100px' }}
                disabled={numErrors > 0 || missingAssignees}
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                Save & continue
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
