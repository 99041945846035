import { getProgressListObjsForApplication } from '../utils/progressTracking';
import { toast } from 'react-toastify';
const API_ROOT = '/api';

const insertProgressListObjsIntoApp = appObj => ({
  ...appObj,
  progressListObjs: getProgressListObjsForApplication(appObj)
});

// For queries that should trigger an application table refresh (e.g. updated client info, deleting an app),
// mark the application tables as requiring a refresh to be acted on in their next render
const markAppTablesForRefresh = applicationTables => {
  const prevTables = applicationTables || {};
  return Object.keys(prevTables).reduce((newTables, tableId) => {
    newTables[tableId] = {
      ...prevTables[tableId],
      appsRefresh: true
    };
    return newTables;
  }, {});
};

// Authentication queries
export const logoutQuery = () => ({
  url: `${API_ROOT}/auth/logout`,
  options: { method: 'POST' }
});

export const loginQuery = values => ({
  url: `${API_ROOT}/auth/login`,
  options: { method: 'POST' },
  body: values,
  transform: ({ expirationTime, user }) => ({
    expirationTime,
    currentUser: user
  }),
  update: {
    expirationTime: (prev, updated) => updated,
    currentUser: (prev, updated) => updated
  }
});

export const forgotPasswordQuery = username => ({
  url: `${API_ROOT}/auth/forgot-password`,
  options: { method: 'POST' },
  body: { username }
});

export const resetPasswordQuery = token => values => ({
  url: `${API_ROOT}/auth/reset-password`,
  options: { method: 'POST' },
  body: { password: values.password, token }
});

// Get current logged-in user
export const currentUserQuery = () => ({
  url: `${API_ROOT}/auth/current-user`,
  options: { method: 'GET' },
  transform: ({ user }) => ({
    currentUser: user
  }),
  update: {
    currentUser: (prev, updated) => updated
  }
});

// DOCUSIGN QUERIES

export const createLegalServicesEnvelopeQuery = ({ appId }) => ({
  url: `${API_ROOT}/applications/create-legal-services-envelope`,
  options: {
    method: 'POST'
  },
  body: { appId },
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const updateLegalServicesEnvelopeQuery = ({ envelopeId, appId }) => ({
  url: `${API_ROOT}/applications/update-legal-services-envelope`,
  options: {
    method: 'POST'
  },
  body: { envelopeId, appId },
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const sendLegalServicesEnvelopeQuery = ({ envelopeId, appId }) => ({
  url: `${API_ROOT}/applications/send-legal-services-envelope`,
  options: {
    method: 'POST'
  },
  body: { envelopeId, appId },
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const createNondisclosureEnvelopeQuery = ({ appId, caseId }) => ({
  url: `${API_ROOT}/applications/create-nondisclosure-envelope`,
  options: {
    method: 'POST'
  },
  body: { appId, caseId },
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const updateNondisclosureEnvelopeQuery = ({ envelopeId, appId, caseId }) => ({
  url: `${API_ROOT}/applications/update-nondisclosure-envelope`,
  options: {
    method: 'POST'
  },
  body: { envelopeId, appId, caseId },
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const sendNondisclosureEnvelopeQuery = ({ envelopeId, appId, caseId }) => ({
  url: `${API_ROOT}/applications/send-nondisclosure-envelope`,
  options: {
    method: 'POST'
  },
  body: { envelopeId, appId, caseId },
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const createExpunctionEnvelopeQuery = ({ appId }) => ({
  url: `${API_ROOT}/applications/create-expunction-envelope`,
  options: {
    method: 'POST'
  },
  body: { appId },
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const updateExpunctionEnvelopeQuery = ({ envelopeId, appId }) => ({
  url: `${API_ROOT}/applications/update-expunction-envelope`,
  options: {
    method: 'POST'
  },
  body: { envelopeId, appId },
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const sendExpunctionEnvelopeQuery = ({ envelopeId, appId }) => ({
  url: `${API_ROOT}/applications/send-expunction-envelope`,
  options: {
    method: 'POST'
  },
  body: { envelopeId, appId },
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// Create envelope recipient preview
export const envelopePreviewQuery = values => ({
  url: `${API_ROOT}/applications/create-envelope-preview`,
  options: {
    method: 'POST'
  },
  body: values
});

// Get friendly names from backend for a variety of application/case fields
export const friendlyNamesQuery = () => ({
  url: `${API_ROOT}/constants/friendly-names`,
  options: { method: 'GET' },
  transform: resp => ({
    friendlyNames: {
      editableAppFields: resp.EDITABLE_APP_FIELD_FRIENDLY_NAMES,
      editableCaseFields: resp.EDITABLE_CASE_FIELD_FRIENDLY_NAMES,
      progressFields: {
        ...resp.APP_PROGRESS_FRIENDLY_NAMES,
        ...resp.EXPUNCTION_PROGRESS_FRIENDLY_NAMES,
        ...resp.NONDISCLOSURE_PROGRESS_FRIENDLY_NAMES
      }
    }
  }),
  update: {
    friendlyNames: (prev, updated) => updated
  }
});

// Get friendly names from backend for a variety of application/case fields
export const lookupEndDateQuery = () => ({
  url: `${API_ROOT}/constants/lookup-end-date`,
  options: { method: 'GET' },
  transform: resp => ({
    lookupEndDate: resp.lookupEndDate
  }),
  update: {
    lookupEndDate: (prev, updated) => updated
  }
});

// Get current docusign doc verions from backend
export const docusignActiveDocVersionsQuery = () => ({
  url: `${API_ROOT}/constants/docusign-doc-versions`,
  options: { method: 'GET' },
  transform: resp => ({
    docusignLegalServicesAgreementVersion: resp.DOCUSIGN_LEGAL_SERVICES_AGREEMENT_VERSION,
    docusignExpunctionVersion: resp.DOCUSIGN_EXPUNCTION_VERSION,
    docusignNondisclosureVersion: resp.DOCUSIGN_NONDISCLOSURE_VERSION
  }),
  update: {
    docusignLegalServicesAgreementVersion: (prev, updated) => updated,
    docusignExpunctionVersion: (prev, updated) => updated,
    docusignNondisclosureVersion: (prev, updated) => updated
  }
});

// Get arresting agency list with addresses and forensic agency options from backend
export const arrestingAgencyAddressesQuery = () => ({
  url: `${API_ROOT}/constants/arresting-agency-addresses`,
  options: { method: 'GET' },
  transform: resp => ({
    arrestingAgencyAddresses: resp.ARRESTING_AGENCEY_ADDRESSES,
    forensicAgencyAddresses: resp.FORENSIC_AGENCY_ADDRESSES
  }),
  update: {
    arrestingAgencyAddresses: (prev, updated) => updated,
    forensicAgencyAddresses: (prev, updated) => updated
  }
});

// Single SPN lookup query - case fetching
export const singleSPNLookupQuery = values => ({
  url: `${API_ROOT}/applications/single-spn-lookup`,
  options: { method: 'GET' },
  body: values,
  transform: ({ cases }) => ({
    spnLookupCases: cases,
    spnLookupString: values.spn
  }),
  update: {
    spnLookupCases: (prev, updated) => updated,
    spnLookupString: (prev, updated) => updated
  }
});

// Create an application for a single SPN
// expects client spn, firstName, lastName, dob
export const createSingleApplicationQuery = values => ({
  url: `${API_ROOT}/applications/new-application`,
  options: { method: 'POST' },
  body: values,
  transform: () => ({
    applicationTables: null,
    spnLookupCases: null,
    spnLookupString: null
  }),
  update: {
    applicationTables: markAppTablesForRefresh,
    // Clear out lookup values for successfully created application
    spnLookupCases: (prev, updated) => null,
    spnLookupString: (prev, updated) => null
  }
});

// Retrieve single application by id
export const singleApplicationQuery = (appId, forceRefresh) => ({
  url: `${API_ROOT}/applications/view-application`,
  body: { appId },
  options: { method: 'GET' },
  force: forceRefresh,
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

const getApplicationsRouteName = (assignedToUser, readyApps) => {
  if (assignedToUser) {
    if (readyApps) {
      return 'view-assigned-ready';
    }
    return 'view-assigned-not-ready';
  }
  return 'view';
};

// Retrieve filtered applications based on query string
export const applicationsQuery = ({ queryString, assignedToUser, readyApps, tableId }) => ({
  url: `${API_ROOT}/applications/${getApplicationsRouteName(
    assignedToUser,
    readyApps
  )}?${queryString}`,
  options: { method: 'GET' },
  transform: ({ applications, totalPages, totalDocuments }) => ({
    applicationTables: {
      applications: applications.map(app => insertProgressListObjsIntoApp(app)),
      totalPages,
      totalDocuments
    }
  }),
  update: {
    applicationTables: (prev, updated) => {
      return {
        ...(prev || {}),
        [tableId]: { ...updated, appsRefresh: false }
      };
    }
  }
});

// Retrieve filtered applications based on query string, without storing to react-query state
export const applicationsExportQuery = queryString => ({
  url: `${API_ROOT}/applications/view?${queryString}`,
  options: { method: 'GET' }
});

// Deletes an application with given appId in body
export const deleteApplicationQuery = values => ({
  url: `${API_ROOT}/applications/delete-application`,
  options: { method: 'POST' },
  body: values, // body format: { appId: "example_app_id" }
  transform: () => ({
    applicationTables: null
  }),
  update: {
    applicationTables: markAppTablesForRefresh
  }
});

// Update client information for an application
export const updateClientInfoQuery = values => ({
  url: `${API_ROOT}/applications/edit-application-info`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    applicationTables: null,
    currentApplication: app
  }),
  update: {
    applicationTables: markAppTablesForRefresh,
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const updateAppProgressQuery = values => ({
  url: `${API_ROOT}/applications/update-app-progress`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    applicationTables: null,
    currentApplication: app
  }),
  update: {
    applicationTables: markAppTablesForRefresh,
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const updateExpunctionProgressQuery = values => ({
  url: `${API_ROOT}/applications/update-expunction-progress`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    applicationTables: null,
    currentApplication: app
  }),
  update: {
    applicationTables: markAppTablesForRefresh,
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const updateNondisclosureProgressQuery = values => ({
  url: `${API_ROOT}/applications/update-nondisclosure-progress`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    applicationTables: null,
    currentApplication: app
  }),
  update: {
    applicationTables: markAppTablesForRefresh,
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// Update case information for an application
// Expects "caseId", "appId", & "fieldsChanged" to be passed
export const updateCaseInfoQuery = values => ({
  url: `${API_ROOT}/applications/edit-case-info`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    applicationTables: null,
    currentApplication: app
  }),
  update: {
    applicationTables: markAppTablesForRefresh,
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// Adds a new case to an application
// Expects "appId", "casID", & any other case fields to be passed in body
export const addCaseQuery = values => ({
  url: `${API_ROOT}/applications/add-case`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    applicationTables: null,
    currentApplication: app
  }),
  update: {
    applicationTables: markAppTablesForRefresh,
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// Deletes a case from an application
// Expects "caseId" & "appId" to be passed
export const deleteCaseQuery = values => ({
  url: `${API_ROOT}/applications/delete-case`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    applicationTables: null,
    currentApplication: app
  }),
  update: {
    applicationTables: markAppTablesForRefresh,
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// Manually close an application
export const closeAppQuery = values => ({
  url: `${API_ROOT}/applications/close-app`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    applicationTables: markAppTablesForRefresh,
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// Manually reopen an application
export const reopenAppQuery = values => ({
  url: `${API_ROOT}/applications/reopen-app`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    applicationTables: markAppTablesForRefresh,
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

export const updateAssigneesQuery = values => ({
  url: `${API_ROOT}/applications/update-assignees`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    applicationTables: null,
    currentApplication: app
  }),
  update: {
    applicationTables: markAppTablesForRefresh,
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// Assign current user (& optionally paired attorney) to next open application
export const assignToOpenApplicationQuery = values => ({
  url: `${API_ROOT}/applications/assign-to-open-application`,
  options: { method: 'POST' },
  body: values,
  transform: () => ({
    applicationTables: null
  }),
  update: {
    applicationTables: markAppTablesForRefresh
  }
});

// Add a new note for an application
export const addNoteQuery = values => ({
  url: `${API_ROOT}/applications/add-note`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// Edit a specific note in an application
export const updateNoteQuery = values => ({
  url: `${API_ROOT}/applications/edit-note`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    applicationTables: app,
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// Delete a note in the application
export const deleteNoteQuery = values => ({
  url: `${API_ROOT}/applications/delete-note`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// Upload document query
export const uploadDocumentQuery = (values, appId) => ({
  url: `${API_ROOT}/applications/upload-document?appId=${appId}`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// Remove document query
export const removeDocumentQuery = values => ({
  url: `${API_ROOT}/applications/remove-document`,
  options: { method: 'POST' },
  body: values,
  transform: ({ app }) => ({
    currentApplication: app
  }),
  update: {
    currentApplication: (prev, updated) => insertProgressListObjsIntoApp(updated)
  }
});

// BULK UPLOAD QUERIES

// helper update function for bulkApplicationBatches
const updateOrAddBatchObj = (prev, updated) => {
  let newBatchArr = null;
  if (!Array.isArray(prev)) {
    newBatchArr = [updated];
  }
  // if in the current array, return an updated array with the updated object
  else if (prev.findIndex(batchObj => batchObj._id === updated._id) > -1) {
    newBatchArr = prev.map(batchObj => (batchObj._id === updated._id ? updated : batchObj));
  }
  // Otherwise, append to current array
  else {
    newBatchArr = [...prev, updated];
  }
  return newBatchArr;
};

// Upload CSV query (for bulk SPN upload) & get application batch information
export const uploadCSVQuery = ({ values, exportOnly }) => ({
  url: `${API_ROOT}/applications/${exportOnly ? 'new-csv-results-bulk' : 'new-application-bulk'}`,
  options: { method: 'POST' },
  body: values,
  transform: ({ bulkApplicationBatch, queueId }) => ({
    bulkApplicationBatches: bulkApplicationBatch,
    queueId: queueId
  }),
  update: {
    bulkApplicationBatches: updateOrAddBatchObj,
    queueId: (prev, updated) => updated
  }
});

// Get bulk application batch by id, used for polling status checking
export const getBatchQuery = values => ({
  url: `${API_ROOT}/applications/get-batch`,
  options: { method: 'GET' },
  body: values,
  transform: ({ bulkApplicationBatch }) => ({
    bulkApplicationBatches: bulkApplicationBatch
  }),
  update: {
    bulkApplicationBatches: (prev, next) => {
      // If the status comes back as complete, show toast if requested by query values
      if (values.showToastOnCompletion && next.applicationCreationComplete) {
        if (Array.isArray(next.rowErrors) && next.rowErrors.length > 0) {
          const numErrors = next.rowErrors.length;
          const numSuccess = next.rowCount - numErrors;
          const getWarningText = (generatedOrCreated, unit) => {
            return `Successfully ${generatedOrCreated} ${numSuccess} ${unit}${
              numSuccess === 1 ? '' : 's'
            }; ${numErrors} row${numErrors === 1 ? '' : 's'} had errors`;
          };
          toast.warn(
            next.exportFilename
              ? getWarningText('generated', 'export row')
              : getWarningText('created', 'application')
          );
        } else {
          toast.success(
            next.exportFilename
              ? 'Successfully generated export'
              : 'Successfully created applications!'
          );
        }
      }
      return updateOrAddBatchObj(prev, next);
    }
  }
});

// Download CSV from bulk SPN upload
export const getBulkSpnCSVQuery = values => ({
  url: `${API_ROOT}/applications/download-bulk-spn-csv`,
  options: { method: 'GET' },
  body: values
});

// Get all application batch objects
export const getBatchHistoryQuery = () => ({
  url: `${API_ROOT}/applications/get-batch-history`,
  options: { method: 'GET' },
  transform: ({ bulkApplicationBatches }) => ({
    bulkApplicationBatches: bulkApplicationBatches
  }),
  update: {
    bulkApplicationBatches: (prev, updated) => updated
  }
});

// Update a user's role
export const updateUserRoleQuery = values => ({
  url: `${API_ROOT}/users/update-role`,
  options: { method: 'POST' },
  body: values,
  transform: ({ user }) => ({
    users: user,
    currentUser: user
  }),
  update: {
    users: (previousUsers, updatedUser) => {
      return previousUsers.map(userObj => {
        if (userObj._id === updatedUser._id) {
          userObj.admin = updatedUser.admin;
          userObj.attorney = updatedUser.attorney;
          userObj.paralegal = updatedUser.paralegal;
        }
        return userObj;
      });
    },
    currentUser: (prevCurrentUser, newUser) => {
      // if the current user is the same as the new user, update it
      if (prevCurrentUser._id === newUser._id) {
        prevCurrentUser.admin = newUser.admin;
        prevCurrentUser.attorney = newUser.attorney;
        prevCurrentUser.paralegal = newUser.paralegal;
      }
      return prevCurrentUser;
    }
  }
});

// Update a user's first and/or last name
export const updateUserNameQuery = values => ({
  url: `${API_ROOT}/users/update-name`,
  options: { method: 'POST' },
  body: values,
  transform: ({ user }) => ({
    users: user,
    currentUser: user
  }),
  update: {
    users: (previousUsers, updatedUser) => {
      return previousUsers.map(userObj => {
        if (userObj._id === updatedUser._id) {
          userObj.firstName = updatedUser.firstName;
          userObj.lastName = updatedUser.lastName;
        }
        return userObj;
      });
    },
    currentUser: (prevCurrentUser, newUser) => {
      // if the current user is the same as the new user, update it
      if (prevCurrentUser._id === newUser._id) {
        prevCurrentUser.firstName = newUser.firstName;
        prevCurrentUser.lastName = newUser.lastName;
      }
      return prevCurrentUser;
    }
  }
});

// ADMIN ONLY ROUTES

// Get all users (admin only)
export const allUsersQuery = () => ({
  url: `${API_ROOT}/users/view-users`,
  options: { method: 'GET' },
  transform: ({ users }) => ({
    users: users
  }),
  update: {
    users: (previousUsers, fetchedUsers) => fetchedUsers
  }
});

// Add new user (admin only)
export const addNewUserQuery = values => ({
  url: `${API_ROOT}/users/add-user`,
  options: { method: 'POST' },
  body: values,
  transform: ({ user }) => ({
    users: user // Pass the returned user object to become the second parameter in our "users" update function
  }),
  update: {
    users: (previousUsers, newUser) => {
      // Add the new user object to the start of our previous list of users
      return [newUser, ...previousUsers];
    }
  }
});

// Deactivate user (admin only)
export const deactivateUserQuery = values => ({
  url: `${API_ROOT}/users/deactivate-user`,
  options: { method: 'POST' },
  body: values,
  transform: ({ user }) => ({
    users: user // Pass the returned user object to become the second parameter in our "users" update function
  }),
  update: {
    users: (previousUsers, updatedUser) => {
      return previousUsers.filter(userObj => userObj._id !== values.userId);
    }
  }
});

// Set the paired attorney for a paralegal user
export const setPairedAttorneyQuery = values => ({
  url: `${API_ROOT}/users/paired-attorney`,
  options: { method: 'POST' },
  body: values,
  transform: ({ user }) => ({
    users: user, // Pass the returned user object to become the second parameter in our "users" update function
    currentUser: user
  }),
  update: {
    users: (previousUsers, updatedUser) => {
      return previousUsers.map(userObj =>
        userObj._id === updatedUser._id ? updatedUser : userObj
      );
    },
    currentUser: (prevCurrentUser, newUser) => {
      // if the current user is the same as the new user, update it
      if (prevCurrentUser._id === newUser._id) {
        return newUser;
      }
      return prevCurrentUser;
    }
  }
});

// REPORTS QUERIES

export const getAppEligibilityQuery = dateFilters => ({
  url: `${API_ROOT}/reports/app-eligibility`,
  options: { method: 'GET' },
  body: dateFilters,
  force: true,
  transform: ({ appEligibility }) => ({
    reports: appEligibility
  }),
  update: {
    reports: (prev, updated) => ({
      ...(prev || {}),
      appEligibility: updated
    })
  }
});

export const getAppClosedReasonQuery = dateFilters => ({
  url: `${API_ROOT}/reports/app-closed-reason`,
  options: { method: 'GET' },
  body: dateFilters,
  force: true,
  transform: ({ appClosedReason }) => ({
    reports: appClosedReason
  }),
  update: {
    reports: (prev, updated) => ({
      ...(prev || {}),
      appClosedReason: updated
    })
  }
});

export const getAppStatusQuery = dateFilters => ({
  url: `${API_ROOT}/reports/app-status`,
  options: { method: 'GET' },
  body: dateFilters,
  force: true,
  transform: ({ appStatus }) => ({
    reports: appStatus
  }),
  update: {
    reports: (prev, updated) => ({
      ...(prev || {}),
      appStatus: updated
    })
  }
});

export const getInactiveAppsQuery = dateFilters => ({
  url: `${API_ROOT}/reports/app-inactive`,
  options: { method: 'GET' },
  body: dateFilters,
  force: true,
  transform: ({ inactiveApplicationsCount }) => ({
    reports: inactiveApplicationsCount
  }),
  update: {
    reports: (prev, updated) => ({
      ...(prev || {}),
      inactiveApplicationsCount: updated
    })
  }
});

export const getCaseEligibilityQuery = dateFilters => ({
  url: `${API_ROOT}/reports/case-eligibility`,
  options: { method: 'GET' },
  body: dateFilters,
  force: true,
  transform: ({ caseEligibility }) => ({
    reports: caseEligibility
  }),
  update: {
    reports: (prev, updated) => ({
      ...(prev || {}),
      caseEligibility: updated
    })
  }
});

export const getCaseStatusQuery = dateFilters => ({
  url: `${API_ROOT}/reports/case-status`,
  options: { method: 'GET' },
  body: dateFilters,
  force: true,
  transform: ({ caseStatus }) => ({
    reports: caseStatus
  }),
  update: {
    reports: (prev, updated) => ({
      ...(prev || {}),
      caseStatus: updated
    })
  }
});

export const getUserOverviewQuery = body => ({
  url: `${API_ROOT}/reports/user-overview`,
  options: { method: 'GET' },
  body,
  force: true,
  transform: ({ userOverview }) => ({
    reports: userOverview
  }),
  update: {
    reports: (prev, updated) => ({
      ...(prev || {}),
      userOverview: updated
    })
  }
});
