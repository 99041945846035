import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoIcon from '../assets/icons/logo.svg';

const SiteTitle = styled.div`
  padding: 10px 15px 20px 15px;
  > a {
    color: black;
    font-size: 1.3em;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  img {
    width: 27px;
    height: 27px;
    margin-right: 6px;
  }
`;

// Site title with logo, links to root
export const Title = ({ onClick }) => {
  return (
    <SiteTitle>
      <Link to="/" onClick={onClick}>
        <img src={logoIcon} alt="" />
        Seal It TX
      </Link>
    </SiteTitle>
  );
};
