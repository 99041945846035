import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewApplicationModal } from '../../actions/modals';
import { newApplicationModalSelector } from '../../selectors/modals';
import { Modal, ModalFooter, ModalHeader, ScrollingModalContent } from '../general/Modal';
import {
  friendlyNamesSelector,
  spnLookupCasesSelector,
  spnLookupStringSelector
} from '../../selectors/entities';
import { Formik } from 'formik';
import {
  DatepickerField,
  MultiFieldLine,
  OptionsList,
  SelectField,
  TextField
} from '../FormFields';
import { Button, InvertedButton } from '../general/Button';
import { useCreateApplication } from '../../hooks/simpleRequests';
import { useHistory } from 'react-router-dom';
import { normalizeAndCapitalizeWords } from '../../utils/general';
import { validateNewApplication } from '../../validators';

// TODO: merge this into shared variable with Gerard's logic once it's in
const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];

// Allow user to create a new application, requiring some basic information about the applicant
export const NewApplicationModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const spnLookupCases = useSelector(spnLookupCasesSelector);
  const showModal = useSelector(newApplicationModalSelector);
  const spnLookupString = useSelector(spnLookupStringSelector);
  const friendlyNames = useSelector(friendlyNamesSelector);
  const appFieldFriendlyNames = friendlyNames.editableAppFields;

  const onClose = () => {
    dispatch(setNewApplicationModal(false));
  };

  const [createNewApp, createNewAppPending] = useCreateApplication(body => {
    // on successful creation, close modal & navigate to the newly created application page
    onClose();
    if (body && body.app && body.app._id) {
      history.push(`/application/${body.app._id}`);
    }
  });

  if (!showModal) {
    return null;
  }

  let initialValues = {
    spn: spnLookupString
  };

  const submit = values => {
    createNewApp(values);
  };

  // If there are cases for the searched client, pre-populate the form with client info (just name & DOB, not address)
  if (Array.isArray(spnLookupCases) && spnLookupCases.length > 0) {
    const initCase = spnLookupCases[0];
    initialValues = {
      firstName: normalizeAndCapitalizeWords(initCase.first_name),
      lastName: normalizeAndCapitalizeWords(initCase.last_name),
      dob: initCase.def_dob,
      spn: spnLookupString,
      middleName: '',
      suffix: '',
      ssnFour: '',
      isVeteran: '',
      idNumber: '',
      streetAddress: '',
      streetAddress2: '',
      city: '',
      state: '',
      zipcode: '',
      email: '',
      phone: ''
    };
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader>Create new application</ModalHeader>
      <Formik initialValues={initialValues} onSubmit={submit} validate={validateNewApplication}>
        {({ handleSubmit, values, errors, touched, submitCount }) => {
          const getError = name => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <>
              {/* TODO: Potentially make this into a shared component w/ Gerard's work once in */}
              <ScrollingModalContent height={430} heightOffset={250}>
                <h3>Client Personal Information</h3>
                <MultiFieldLine columns="48% 48%">
                  <TextField
                    label={`${appFieldFriendlyNames['firstName']}*`}
                    name="firstName"
                    error={getError('firstName')}
                  />
                  <TextField label={appFieldFriendlyNames['middleName']} name="middleName" />
                  <TextField
                    label={`${appFieldFriendlyNames['lastName']}*`}
                    name="lastName"
                    error={getError('lastName')}
                  />
                  <SelectField label={appFieldFriendlyNames['suffix']} name="suffix">
                    <OptionsList
                      options={[
                        { label: 'Jr', value: 'Jr' },
                        { label: 'Sr', value: 'Sr' },
                        { label: 'II', value: 'II' },
                        { label: 'III', value: 'III' },
                        { label: 'IV', value: 'IV' }
                      ]}
                    />
                  </SelectField>
                  <TextField
                    label={`${appFieldFriendlyNames['spn']}*`}
                    name="spn"
                    error={getError('spn')}
                  />
                  <TextField
                    label={appFieldFriendlyNames['ssnFour']}
                    name="ssnFour"
                    error={getError('ssnFour')}
                  />
                  <DatepickerField
                    label={`${appFieldFriendlyNames['dob']}*`}
                    placement="top-start"
                    width={100}
                    name="dob"
                    error={getError('dob')}
                  />
                  <SelectField
                    label={`${appFieldFriendlyNames['isVeteran']}`}
                    children="Yes"
                    name="isVeteran"
                    error={getError('isVeteran')}
                  >
                    <OptionsList
                      options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false }
                      ]}
                    />
                  </SelectField>
                  <TextField label={appFieldFriendlyNames['idNumber']} name="idNumber" />
                </MultiFieldLine>

                <h3>Client Contact Information</h3>
                <MultiFieldLine columns="48% 48%">
                  <TextField label={appFieldFriendlyNames['streetAddress']} name="streetAddress" />
                  <TextField
                    label={appFieldFriendlyNames['streetAddress2']}
                    name="streetAddress2"
                  />
                  <TextField label={appFieldFriendlyNames['city']} name="city" />
                  <SelectField label={appFieldFriendlyNames['state']} name="state">
                    <OptionsList options={states} />
                  </SelectField>
                  <TextField
                    label={appFieldFriendlyNames['zipcode']}
                    name="zipcode"
                    error={getError('zipcode')}
                  />
                  <TextField
                    label={appFieldFriendlyNames['email']}
                    name="email"
                    error={getError('email')}
                  />
                  <TextField
                    label={appFieldFriendlyNames['phone']}
                    name="phone"
                    error={getError('phone')}
                  />
                </MultiFieldLine>
              </ScrollingModalContent>
              <ModalFooter>
                <InvertedButton onClick={onClose}>Cancel</InvertedButton>
                <Button isLoading={createNewAppPending} onClick={handleSubmit} type="button">
                  Submit
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
