import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDeleteNoteModal } from '../../actions/modals';
import { noteModalInfoSelector, deleteNoteModalSelector } from '../../selectors/modals';
import { Button, InvertedButton } from '../general/Button';
import { Modal, ModalFooter, ModalHeader } from '../general/Modal';
import { useDeleteNote } from '../../hooks/simpleRequests';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';

export const DeleteNoteModal = () => {
  const showModal = useSelector(deleteNoteModalSelector);
  const dispatch = useDispatch();
  const currentNote = useSelector(noteModalInfoSelector);
  const application = useCurrentApplication();

  const onClose = () => {
    dispatch(setDeleteNoteModal(false));
  };

  const [deleteNote, delettingNote] = useDeleteNote(onClose);

  if (!showModal) {
    return null;
  }

  const onDelete = () => {
    deleteNote({ noteId: currentNote._id, appId: application._id });
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader>Delete Note Confirmation</ModalHeader>
      <p>Are you sure you want to delete this note?</p>
      <ModalFooter>
        <InvertedButton onClick={onClose}>Cancel</InvertedButton>
        <Button className="delete-color" isLoading={delettingNote} onClick={onDelete}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
