import styled from 'styled-components';
import { LinkLikeButton } from '../Button';
import infoIcon from '../../../assets/icons/info.svg';
import { Tooltip } from '../Tooltip';

const InfoIcon = styled.img`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  position: relative;
  top: 2px;
`;

// Button that displays export text with a nice tooltip
export const ExportButton = ({ getExport, isLoading, text, tooltip }) => {
  return (
    <div style={{ display: 'flex' }}>
      <LinkLikeButton onClick={getExport} isLoading={isLoading} loadingText="Generating export ...">
        {text}
      </LinkLikeButton>
      <Tooltip content={tooltip} displayBottom={true}>
        <InfoIcon src={infoIcon} alt="more information" />
      </Tooltip>
    </div>
  );
};
