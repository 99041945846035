import axios from 'axios';
import { toast } from 'react-toastify';
import { statusIsGood, statusIsUnauthorized } from '../utils/general';
import { useCurrentApplication } from './useCurrentApplication';
import { useHandleUnauthorized } from './useHandleUnauthorized';

const SITE_URL = process.env.REACT_APP_SITE_URL;

export const useDocumentDownload = () => {
  const application = useCurrentApplication();
  const handleUnauthorized = useHandleUnauthorized();

  const submit = async filename => {
    const response = await axios({
      method: 'get',
      url: `${SITE_URL}/api/applications/download-document?filename=${filename}&appId=${application._id}`,
      responseType: 'arraybuffer',
      withCredentials: true
    });
    const { status, data } = response;
    if (statusIsGood(status)) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else if (statusIsUnauthorized(status)) {
      handleUnauthorized();
    } else {
      toast.error('Could not download file');
    }
  };
  return submit;
};
