import React from 'react';
import '../style/loaders.css';
import styled from 'styled-components';
import { PRIMARY } from '../constants/cssVars';

const PageLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgb(100, 100, 100, 0.4);
`;

// Full-page loader, with circulating dots
export const PageLoader = () => (
  <PageLoaderWrapper>
    <div className="lds-roller" data-testid="page-loader">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </PageLoaderWrapper>
);

const DotLoaderWrapper = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .lds-roller div:after {
    background: ${PRIMARY};
  }
`;

const DotLoaderContent = () => (
  <div className="lds-roller" data-testid="page-loader">
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

// Circulating dots loader, I want to change this but can be used temporarily
export const DotLoader = () => (
  <DotLoaderWrapper>
    <DotLoaderContent />
  </DotLoaderWrapper>
);

// Button loader, with animated dots in a line
export const ButtonLoader = () => (
  <div className="lds-ellipsis" data-testid="button-loader">
    <div />
    <div />
    <div />
    <div />
  </div>
);

const TableLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.12);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
`;

// Part-opacity loader that can be placed inside of a table
export const TableLoader = ({ showLoader }) => {
  if (showLoader) {
    return (
      <TableLoaderWrapper>
        <DotLoaderContent />
      </TableLoaderWrapper>
    );
  }
  return null;
};
