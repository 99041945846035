import React, { useEffect } from 'react';
import styled from 'styled-components';
import closeIcon from '../../assets/icons/close.svg';
import FocusTrap from 'focus-trap-react';
import {
  BG_PRIMARY,
  GREY_DARK,
  GREY_LIGHTER,
  GREY_MEDIUM_DARK,
  MAX_DESKTOP_WIDTH,
  PRIMARY,
  PRIMARY_DARK
} from '../../constants/cssVars';

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgb(100, 100, 100, 0.45);
`;

const ModalWrapper = styled.div`
  position: relative;
  margin: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 30px;
  width: ${props => props.width || 550}px;
  width: min(${props => props.width || 550}px, 95%);
  max-height: 85%;
  overflow: auto;
  @media only screen and (max-width: 600px) {
    width: 95%;
  }
`;

const CloseButton = styled.img`
  margin-left: 10px;
  height: 18px;
  width: 18px;
`;

const CloseWrapper = styled.button`
  color: ${PRIMARY};
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.95em;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    top: 12px;
    right: 12px;
  }
  &:focus {
    box-shadow: none !important;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: ${({ isRTL }) => (isRTL ? 'flex-start' : 'flex-end')};
  margin-top: 20px;
  > a {
    margin-left: 10px;
  }
`;

export const ModalHeader = styled.h2`
  font-weight: 600;
  color: ${PRIMARY_DARK};
  margin: 15px 0px ${({ hideBorder }) => (hideBorder ? 5 : 10)}px 0px;
  border-bottom: ${({ hideBorder }) => (hideBorder ? '' : `2px solid ${BG_PRIMARY}`)};
  padding-bottom: ${({ hideBorder }) => (hideBorder ? '' : `5px`)};
  > span {
    color: ${PRIMARY};
  }
`;

export const ModalSubeader = styled.h3`
  font-weight: 600;
  font-size: 0.92em;
  color: ${GREY_DARK};
  margin: 5px 0px 10px 0px;
  border-bottom: 2px solid ${BG_PRIMARY};
  padding-bottom: 5px;
`;

// Modal content that does not require scrolling styling
export const ModalContent = styled.div`
  margin: 15px 0px;
`;

// Scrolling container that can be used for long modal content.
// Defaults (500 for "height" & 200 for "heightOffset") should work
// for modals with a header and no footer.
export const ScrollingModalContent = styled.div`
  max-height: ${props => props.height || 500}px;
  max-height: min(
    ${props => props.height || 500}px,
    calc(100vh - ${props => props.heightOffset || 200}px)
  );
  overflow: auto;
  margin: 0px;
  padding: 15px;
  border-radius: 4px;
  background-color: ${GREY_LIGHTER};
`;

export const Modal = ({ children, onClose, style, wrapperStyle, closeOnOuterClick, width }) => {
  useEffect(() => {
    const closeOnEscapePress = event => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', closeOnEscapePress);
    return () => {
      window.removeEventListener('keydown', closeOnEscapePress);
    };
  }, [onClose]);

  return (
    <OuterWrapper style={wrapperStyle} onClick={() => (closeOnOuterClick ? onClose() : null)}>
      <FocusTrap>
        <ModalWrapper style={style} width={width} onClick={e => e.stopPropagation()}>
          <CloseWrapper ariaLabel="close" type="button" onClick={onClose}>
            close
            <CloseButton tabIndex="0" src={closeIcon} alt="" />
          </CloseWrapper>
          {children}
        </ModalWrapper>
      </FocusTrap>
    </OuterWrapper>
  );
};
