import { PageSpacer, PaginationButton } from './StyledTable';

/**
 * @param {{
 *   pagination: {
 *     pageNum: number,
 *     setPageNum: (page: number) => any,
 *     totalPages: number
 *   }
 * }} props
 */
const PageNavigation = ({ pagination }) => {
  const { pageNum, setPageNum, totalPages } = pagination;

  if (!totalPages) {
    return null;
  }
  // Buttons should start either 2 pages behing the current page or at the first page
  let pageButtonStart = Math.max(pageNum - 2, 1);
  // Buttons end 4 pages after the start (total of 5 page buttons)
  let pageButtonEnd = pageButtonStart + 4;

  // If there are not enough pages to show 5 pages, the last page shown should be the
  // the total number of pages
  if (pageButtonEnd > totalPages) {
    pageButtonEnd = totalPages;
    // And back-fill previous pages so that we still show 5 page buttons
    pageButtonStart = Math.max(1, pageButtonEnd - 4);
  }

  // Create an array of the pages we will show
  const pageButtons = Array(pageButtonEnd - pageButtonStart + 1)
    .fill()
    .map((_, i) => pageButtonStart + i);

  // If we can't see the first page, show it
  const showFirstPageButton = pageButtons[0] !== 1;
  // If we are a distance from the first page, add a spacer
  const showFirstPageSpacer = pageButtons[0] > 2;

  // If we can't see the last page, show it
  const showLastPageButton = pageButtons[pageButtons.length - 1] !== totalPages;
  // If we are a distance from the last page, add a spacer
  const showLastPageSpacer = pageButtons[pageButtons.length - 1] < totalPages - 1;

  return (
    <div>
      <PaginationButton disabled={pageNum === 1} onClick={() => setPageNum(pageNum - 1)}>
        {'<'}
      </PaginationButton>
      {showFirstPageButton && (
        <PaginationButton onClick={() => setPageNum(1)}>{1}</PaginationButton>
      )}
      {showFirstPageSpacer && <PageSpacer>...</PageSpacer>}
      {pageButtons.map(pageButtonNumber => (
        <PaginationButton
          className={pageButtonNumber === pageNum ? 'active' : ''}
          key={pageButtonNumber}
          onClick={() => setPageNum(pageButtonNumber)}
        >
          {pageButtonNumber}
        </PaginationButton>
      ))}
      {showLastPageSpacer && <PageSpacer>...</PageSpacer>}
      {showLastPageButton && (
        <PaginationButton onClick={() => setPageNum(totalPages)}>{totalPages}</PaginationButton>
      )}
      <PaginationButton disabled={pageNum === totalPages} onClick={() => setPageNum(pageNum + 1)}>
        {'>'}
      </PaginationButton>
    </div>
  );
};

export default PageNavigation;
