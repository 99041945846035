import React from 'react';
import styled from 'styled-components';
import { HIGHLIGHT, SHADOW_CRISP } from '../../constants/cssVars';

const MessageWrapper = styled.div`
  border-radius: 5px;
  padding: 8px;
  background-color: ${HIGHLIGHT};
  color: #4a3d0a;
  box-shadow: ${SHADOW_CRISP};
  > p {
    font-weight: 600;
    margin: 0px;
  }
  margin-bottom: 15px;
`;

// If there's a felony conviction in same criminal episode, display here
export const SameCriminalEpisodeMessage = ({ caseObj }) => {
  const { same_criminal_episode_has_felony } = caseObj;

  if (!same_criminal_episode_has_felony) {
    return null;
  }

  return (
    <MessageWrapper>
      <p>This case has a felony conviction in the same criminal episode</p>
    </MessageWrapper>
  );
};
