import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  SHADOW_MEDIUM,
  SIDEBAR_WIDTH,
  GREY_MEDIUM,
  MAX_DESKTOP_WIDTH,
  PRIMARY_DARK,
  PRIMARY,
  BG_PRIMARY,
  GREY_MEDIUM_DARK,
  MOBILE_SIDEBAR_WIDTH,
  SHADOW_CRISP
} from '../constants/cssVars';
import { useMatchPath } from '../hooks/useMatchPath';
import closeIcon from '../assets/icons/close.svg';
import menuIcon from '../assets/icons/menu.svg';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../selectors/entities';
import { Title } from './Title';

const DesktopSidebarWrapper = styled.section`
  width: ${({ width }) => width || SIDEBAR_WIDTH}px;
  background-color: #fff;
  z-index: 500;
  box-shadow: ${SHADOW_MEDIUM};
  border-right: 1px solid ${GREY_MEDIUM};
  box-sizing: border-box;
  display: block;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: none;
    width: 100%;
  }
`;

const MobileSidebarWrapper = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: block;
    top: 0px;
    left: 0px;
  }
`;

const SidebarContent = styled.nav`
  display: block;
  height: calc(100vh - 20px);
  padding: 10px 0px;
  a {
    text-decoration: none;
  }
`;

const SidebarItem = styled.div`
  background-color: ${props => (props.active ? BG_PRIMARY : 'white')};
  padding: 8px 15px;
  font-size: 1.1em;
  color: ${PRIMARY_DARK};
  &:hover {
    color: ${PRIMARY};
  }
`;

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  z-index: 1000;
`;

const MenuButton = styled.button`
  background-color: #fff;
  box-shadow: ${SHADOW_CRISP};
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0px;
  border-radius: 0px 0px 6px 0px;
  color: ${GREY_MEDIUM_DARK};
  font-weight: 600;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    font-size: 18px;
  }
  > img {
    width: 0.8em;
    height: 0.8em;
    padding-left: 10px;
  }
`;

const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 20px;
`;

const CloseButton = styled.button`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0px;
  background-color: transparent;
  font-size: 1em;
  font-weight: 600;
  color: ${GREY_MEDIUM_DARK};
  > img {
    width: 1em;
    height: 1em;
    margin-left: 10px;
  }
`;

const SlideOutWrapper = styled.div`
  background-color: white;
  color: ${BG_PRIMARY};
  margin: 0px;
  width: ${MOBILE_SIDEBAR_WIDTH}px;
  position: fixed;
  z-index: 1;
  top: 0;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  left: ${({ active }) => (active ? 0 : -MOBILE_SIDEBAR_WIDTH)}px;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: ${({ active }) =>
    active ? '3px 0px 8px rgba(0,0,0,.2)' : '0px 0px 0px rgba(0,0,0,0)'};
`;

// Our main navigation sidebar; shows a block sidebar on desktop & a menu-button with a slide-out sidebar on mobile
export const Sidebar = ({ left, width }) => {
  return (
    <>
      {/* The desktop version only renders on large screen widths (see DesktopSidebarWrapper CSS) */}
      <DesktopSidebarWrapper width={width} left={left}>
        <SidebarContents />
      </DesktopSidebarWrapper>
      {/* The mobile version only renders on small screen widths (see MobileSidebarWrapper CSS) */}
      <MobileMenuButton />
    </>
  );
};

// Navigation contents used for both the desktop & mobile sidebars
const SidebarContents = ({ close }) => {
  const pathIsActiveCheck = useMatchPath();
  const { admin, paralegal, attorney } = useSelector(currentUserSelector);
  return (
    <SidebarContent>
      {/* Title link */}
      <Title onClick={close} />

      {/* Only show "Your Apps" for Attorney & Paralegal */}
      {(attorney || paralegal) && (
        <>
          <Link to="/your-apps" onClick={close}>
            <SidebarItem active={pathIsActiveCheck('/your-apps')}>Your applications</SidebarItem>
          </Link>
        </>
      )}

      <Link to="/all-apps" onClick={close}>
        <SidebarItem active={pathIsActiveCheck('/all-apps', true)}>All applications</SidebarItem>
      </Link>

      <Link to="/spn-lookup" onClick={close}>
        <SidebarItem active={pathIsActiveCheck('/spn-lookup')}>SPN lookup</SidebarItem>
      </Link>

      {/* Only show "Import SPNs" & "Users" pages for Admins */}
      {admin && (
        <>
          <Link to="/import-spns" onClick={close}>
            <SidebarItem active={pathIsActiveCheck('/import-spns')}>Bulk Import SPNs</SidebarItem>
          </Link>
          <Link to="/users" onClick={close}>
            <SidebarItem active={pathIsActiveCheck('/users')}>Users</SidebarItem>
          </Link>
        </>
      )}

      <Link to="/reports" onClick={close}>
        <SidebarItem active={pathIsActiveCheck('/reports')}>Reports</SidebarItem>
      </Link>
    </SidebarContent>
  );
};

// Button to trigger slide-out (mobile views only)
const MobileMenuButton = props => {
  const [menuActive, setMenuActive] = useState(false);
  return (
    <MobileSidebarWrapper>
      <MenuButton onClick={() => setMenuActive(true)}>
        Menu
        <img src={menuIcon} alt="" />{' '}
        {/* Setting empty alt-text since "Menu" already describes the button */}
      </MenuButton>
      <MobileSlideOutSidebar active={menuActive} setActive={setMenuActive} {...props} />
    </MobileSidebarWrapper>
  );
};

// Actual slide-out that's shown when mobile menu button is clicked
const MobileSlideOutSidebar = ({ active, setActive }) => {
  const close = () => setActive(false);
  return (
    <OuterWrapper active={active} onClick={close}>
      <SlideOutWrapper role="navigation" active={active} onClick={e => e.stopPropagation()}>
        <CloseWrapper>
          <CloseButton onClick={close}>
            close
            <img src={closeIcon} alt="" />{' '}
            {/* Setting empty alt-text since "close" already describes the button */}
          </CloseButton>
        </CloseWrapper>
        <SidebarContents close={close} />
      </SlideOutWrapper>
    </OuterWrapper>
  );
};
