import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmationModal } from '../../actions/modals';
import { confirmationModalInfoSelector, confirmationModalSelector } from '../../selectors/modals';
import { Modal, ModalContent, ModalFooter, ModalHeader } from './Modal';
import { Button, InvertedButton } from './Button';

// Simple Continue / Cancel confirmation modal, with title, body, & onConfirm function
// set in redux state
export const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(confirmationModalSelector);
  const { modalTitle, modalBody, onConfirm } = useSelector(confirmationModalInfoSelector);

  const onClose = () => {
    dispatch(setConfirmationModal(null, null, null, null));
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader>{modalTitle}</ModalHeader>
      <ModalContent>{modalBody}</ModalContent>
      <ModalFooter>
        <InvertedButton onClick={onClose}>Cancel</InvertedButton>
        <Button
          onClick={() => {
            onClose();
            onConfirm();
          }}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};
