import React, { useEffect } from 'react';
import { useTable, usePagination, useGlobalFilter, useFilters, useSortBy } from 'react-table';
import { GlobalFilter } from './GlobalFilter';
import { Link } from 'react-router-dom';
import { LinkLikeButton } from '../Button';
import chevronUp from '../../../assets/icons/small_chevron_up.svg';
import chevronDown from '../../../assets/icons/small_chevron_down.svg';
import { ExportButton } from './ExportButton';
import {
  TitleWrapper,
  SearchAndInteractivesWrapper,
  TableWrapper,
  StyledTable,
  TableHeader,
  TableRow,
  TableData,
  TableFooter,
  SortingChevron,
  NotFoundMessage
} from './StyledTable';
import PageNavigation from './PageNavigation';

const DEFAULT_ROWS_PER_PAGE = 10;
const MAX_PRINT_ROWS_PER_PAGE = 100;

// Table component with pagination & search (global filter)
// Uses react-table, which handles the table state - we handle the styling and rendering! :)
export const Table = ({
  // Required props
  columns, // (object array) should be memoized in parent component, see https://react-table.tanstack.com/docs/quick-start
  data, // (object array) should be memoized in parent component, see https://react-table.tanstack.com/docs/quick-start
  // Optional props
  tableTitle, // (string) table's title, if not providing outside table, that will insert the row count into it
  tableBanner, // (element) lives underneath a table's title / above the search bar, elements should be a TableBanner component for consistency
  exportInfo, // (object) information to allow user to export current filtered contents, includes { text: button_text, tooltip: tooltip_text, getExport: function that is passed filtered rows }
  searchPlaceholder, // (string) placeholder text for search bar
  searchRight, // (element) element to place on the far right side of the search row
  hideSearch, // (boolean) hide search input
  searchNeighbor, // (element) element to place directly to the right side of the search bar
  searchResetCount, // (integer) tracks reset counts, triggering a reset when searchResetCount > 0
  emptyMessage, // (string) message to display when there are no results
  onRowClick, // (function) function to call when a row's first entry is clicked (will take precedence over "getLink")
  getLink, // (function) function that returns a link to visit when a row's first entry is clicked
  pageSize, // (integer) number of rows to show per page
  initialSorting, // (object array) optional sorting array object for react table initial state
  currentSorting, // (object array) optional sorting array object for externally controlled sort
  onSortChange, // (function) optional function to call when sort changes
  globalFilter, // (string) if you're handling the global filter externally, pass this
  filters, // array of current filters to apply [{ id, value }] - logic for applying filters should be passed with the columns
  hiddenColumns, // array of column names (strings) to hide
  customGlobalFilterFunction, // custom global filtering function to override searching all fields
  onSearch, // function to call on search, takes in new search value
  printView // (boolean) hides search & shows all rows (up to 100)
}) => {
  // Using react-table's useTable hook, pass in our columns & data to gather
  // gather all of the properties about the table that we can use to render it! :)
  const {
    // Basic table props from react-table
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    canPreviousPage,
    setSortBy,

    // Table property for global filter (search across all columns with one input)
    setGlobalFilter,

    // Table properties used for pagination
    canNextPage,
    pageOptions,
    pageCount,
    page,
    gotoPage,
    nextPage,
    previousPage,
    rows,
    state // current table state, used to get current page index
  } = useTable(
    {
      columns, // should be memoized in parent component
      data, // should be memoized in parent component
      initialState: {
        pageSize: pageSize || (printView ? MAX_PRINT_ROWS_PER_PAGE : DEFAULT_ROWS_PER_PAGE),
        ...(initialSorting ? { sortBy: initialSorting } : {}),
        ...(globalFilter ? { globalFilter: globalFilter } : {}),
        ...(filters ? { filters: filters } : {}),
        hiddenColumns: hiddenColumns || []
      },
      ...(customGlobalFilterFunction ? { globalFilter: customGlobalFilterFunction } : {})
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (Array.isArray(filters)) {
      filters.forEach(({ id, value }) => {
        setFilter(id, value);
      });
    }
  }, [filters]);

  // SORT LISTENING (for keeping table sorting aligned for printed table versus non-printed table)
  // Call onSortChange (if provided) on state.sortBy changes
  useEffect(() => {
    if (onSortChange) {
      onSortChange(state.sortBy);
    }
  }, [state.sortBy, onSortChange]);

  // If manual sorting (currentSorting) is provided, update on change
  useEffect(() => {
    if (currentSorting) {
      setSortBy(currentSorting);
    }
  }, [currentSorting, setSortBy]);

  // If there's only one page, no need to show the pagination
  const noPagination = pageCount === 1;

  return (
    <>
      {tableTitle && (
        <TitleWrapper>
          <h2>
            {tableTitle} {rows ? `(${rows.length})` : ''}
          </h2>
          {exportInfo && (
            <ExportButton
              getExport={() => exportInfo.getExport(rows)}
              text={exportInfo.text}
              tooltip={exportInfo.tooltip}
            />
          )}
        </TitleWrapper>
      )}
      {tableBanner}
      {!hideSearch && (
        <SearchAndInteractivesWrapper>
          <div>
            {!printView && (
              <GlobalFilter
                searchPlaceholder={searchPlaceholder}
                globalFilter={globalFilter || state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                onSearch={onSearch}
                searchResetCount={searchResetCount}
              />
            )}
            {searchNeighbor}
          </div>
          {searchRight}
        </SearchAndInteractivesWrapper>
      )}
      <TableWrapper noPagination={noPagination}>
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <TableHeader
                      {...column.getHeaderProps()}
                      style={{
                        textAlign: column.rightAlignData
                          ? 'right'
                          : column.centerData
                          ? 'center'
                          : 'left',
                        ...(column.style || {})
                      }}
                    >
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {!printView && (
                          <span style={{ fontSize: '0.8em', marginLeft: '5px' }}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SortingChevron src={chevronDown} />
                              ) : (
                                <SortingChevron src={chevronUp} />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        )}
                      </span>
                    </TableHeader>
                  );
                })}
              </TableRow>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, idx) => {
                    return (
                      <TableData
                        {...cell.getCellProps()}
                        style={{
                          textAlign: cell.column.rightAlignData
                            ? 'right'
                            : cell.column.centerData
                            ? 'center'
                            : 'left',
                          overflow: cell.column.allowOverflow ? 'visible' : 'auto'
                        }}
                      >
                        {// if a "getLink" or "onRowClick" function is provided, use its result as a link or button onClick respectively
                        // for the first item in row
                        onRowClick && idx === 0 ? (
                          <LinkLikeButton onClick={() => onRowClick(row)}>
                            {cell.render('Cell')}
                          </LinkLikeButton>
                        ) : getLink && idx === 0 ? (
                          <Link to={getLink(row)}>{cell.render('Cell')}</Link>
                        ) : (
                          <div>{cell.render('Cell')}</div>
                        )}
                      </TableData>
                    );
                  })}
                </TableRow>
              );
            })}
          </tbody>
        </StyledTable>
        {/* If there are no pages, return a no-results message */}
        {page.length < 1 && <NotFoundMessage>{emptyMessage || 'No results found'}</NotFoundMessage>}
        <TableFooter>
          {!noPagination && (
            <PageNavigation
              pagination={{
                pageNum: state.pageIndex + 1,
                setPageNum: page => gotoPage(page - 1),
                totalPages: pageOptions.length
              }}
            />
          )}
        </TableFooter>
      </TableWrapper>
    </>
  );
};
