import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import store from './reducers/store';
import 'react-toastify/dist/ReactToastify.css';
import { MainRoutes } from './components/MainRoutes';
import ScrollToTop from './components/general/ScrollToTop';
import SessionExpirationTimer from './components/general/SessionExpirationTimer';
import { CloseActiveDropdown } from './components/general/CloseActiveDropdown';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} component={MainRoutes} />
      </Switch>
      <ScrollToTop />
      <CloseActiveDropdown />
      {/* Move "SessionExpirationTimer" to just the routes that need auth if using auth */}
      <SessionExpirationTimer />
    </BrowserRouter>
  );
};

const App = () => (
  <Provider store={store}>
    <ReduxQueryProvider queriesSelector={state => state.queries}>
      <Router />
    </ReduxQueryProvider>
  </Provider>
);

export default App;
