import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { anyModalIsActiveSelector } from '../../selectors/modals';

// Checks if any modal is currently open, & makes the body un-scrollable if so
export const PauseScrollOnOpenModal = () => {
  const anyModalIsActive = useSelector(anyModalIsActiveSelector);
  const body = document.querySelector('body');

  useEffect(() => {
    if (anyModalIsActive && body.style.overflow !== 'hidden') {
      body.style.overflow = 'hidden';
    } else if (!anyModalIsActive && body.style.overflow !== 'auto') {
      body.style.overflow = 'auto';
    }
  }, [anyModalIsActive]);

  return null;
};
