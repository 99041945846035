import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRequest } from 'redux-query-react';
import { singleApplicationQuery } from '../actions/queries';
import { applicationTablesSelector, currentApplicationSelector } from '../selectors/entities';

export const useCurrentApplication = () => {
  const currentApplication = useSelector(currentApplicationSelector);
  return currentApplication || {};
  // const applicationTables= useSelector(applicationsSelector);
  // // const application = applications.find(app => app._id === applicationId);
  // // check each application table for the application
  // return application;
};
