import { useSelector } from 'react-redux';
import { usersSelector, currentUserSelector } from '../selectors/entities';

// Convert a user object to a select form option
export const convertUserObjToOption = userObj => ({
  label: `${userObj.firstName} ${userObj.lastName}`,
  value: userObj._id
});

// Returns options lists (for use in a form selection, "{ label, value }" objects)
// for attorney and paralegal assignee types
export const useAssigneeOptions = () => {
  const currentUser = useSelector(currentUserSelector);
  const users = useSelector(usersSelector);

  let attorneyOptions = users.filter(userObj => userObj.attorney).map(convertUserObjToOption);
  let paralegalOptions = users.filter(userObj => userObj.paralegal).map(convertUserObjToOption);
  const adminOptions = users.filter(userObj => userObj.admin).map(convertUserObjToOption);

  // if the current user is an admin, allow admins to be assigned as attorneys & paralegals
  // in other words, admin users can add themselves to any role - other users may not
  if (currentUser.admin) {
    attorneyOptions = [...attorneyOptions, ...adminOptions];
    paralegalOptions = [...paralegalOptions, ...adminOptions];
  }

  return {
    attorneyOptions,
    paralegalOptions
  };
};
