import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { mutateAsync } from 'redux-query';
import { loginQuery } from '../actions/queries';
import { SESSION_EXP_STORAGE_KEY } from '../constants/general';
import { statusIsGood } from '../utils/general';

// Example login query
export const useLoginQuery = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const submit = values => {
    values.username = values.email;
    setSubmitting(true);
    dispatch(mutateAsync(loginQuery(values))).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        const sessionExpDate = new Date(body.expirationTime);
        const sessionExpTimeMs = sessionExpDate.getTime();
        localStorage.setItem(SESSION_EXP_STORAGE_KEY, sessionExpTimeMs);
        onSuccess();
      } else {
        setSubmitting(false);
        toast.error('Could not log in with the provided credentials');
      }
    });
  };
  return [submit, submitting];
};
