import styled from 'styled-components';
import { GREY_LIGHTEST, GREY_MEDIUM } from '../../constants/cssVars';

// Dashed-border box to display a no-content message
export const NoContentBox = styled.div`
  border: 1px dashed ${GREY_MEDIUM};
  border-radius: 8px;
  background-color: ${GREY_LIGHTEST};
  margin-bottom: 10px;
  min-height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  text-align: center;
  flex-direction: column;
`;
