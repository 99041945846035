import { matchPath, useLocation } from 'react-router-dom';

// Returns a function that given a string path (e.g. '/app' or '/user/:userId'),
// returns whether or not the current location pathname matches it using react-router's function
export const useMatchPath = () => {
  const location = useLocation();
  const pathIsActiveCheck = (path, exactMatch) => {
    const matchObj = matchPath(location.pathname, path); // returns a non-null object if a match is found
    if (exactMatch) {
      return matchObj && matchObj.isExact;
    }
    return matchObj != null;
  };
  return pathIsActiveCheck;
};
