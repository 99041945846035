import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAddCaseModal } from '../../actions/modals';
import { CASE_FIELD_PROPERTIES } from '../../constants/cases';
import { useAddCase } from '../../hooks/simpleRequests';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { friendlyNamesSelector } from '../../selectors/entities';
import { addCaseModalSelector } from '../../selectors/modals';
import { getMomentUTC } from '../../utils/general';
import { Modal, ModalHeader } from '../general/Modal';
import { CaseForm } from './CaseForm';

// Allows user to add a new case
export const AddCaseModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(addCaseModalSelector);
  const application = useCurrentApplication();
  const friendlyNames = useSelector(friendlyNamesSelector);
  const caseFieldFriendlyNames = friendlyNames.editableCaseFields;
  const onClose = () => dispatch(setAddCaseModal(false));
  const [addCase, addingCase] = useAddCase(onClose);

  if (!showModal) {
    return null;
  }

  const submit = values => {
    // convert date values to YYYY-MM-DD format
    const dateConvertedValues = Object.keys(values).reduce((formData, fieldName) => {
      if ((CASE_FIELD_PROPERTIES[fieldName] || {}).isDate && values[fieldName]) {
        formData[fieldName] = getMomentUTC(values[fieldName]).format('YYYY-MM-DD');
      } else {
        formData[fieldName] = values[fieldName];
      }
      return formData;
    }, {});
    addCase({ ...dateConvertedValues, appId: application._id });
  };

  // Only show the fields that are in the caseFieldFriendlyNames object, initializing to null
  const initialFields = Object.keys(caseFieldFriendlyNames).reduce((formData, fieldName) => {
    formData[fieldName] = '';
    return formData;
  }, {});

  return (
    <Modal onClose={onClose}>
      <ModalHeader>Add New Case</ModalHeader>
      <CaseForm
        submit={submit}
        isLoading={addingCase}
        initialFields={initialFields}
        onCancel={onClose}
      />
    </Modal>
  );
};
