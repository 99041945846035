import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setDocumentUploadModal } from '../../actions/modals';
import { documentUploadModalSelector } from '../../selectors/modals';
import { Modal, ModalContent, ModalFooter, ModalHeader } from '../general/Modal';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { Button } from '../general/Button';
import { useUploadDocument } from '../../hooks/useDocumentUpload';

const CustomFileInput = styled.label`
  display: inline-block;
  padding: 0px;
  margin: 10px 0px;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  width: 100%;
`;

// Modal to show when "Upload Document" is clicked on.
// Allows user to select a file and upload it
export const DocumentUploadModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(documentUploadModalSelector);
  const application = useCurrentApplication();
  const uploads = application.uploads || [];
  const [docFile, setDocFile] = useState(null);
  const [fileError, setFileError] = useState(null);

  const onClose = () => {
    dispatch(setDocumentUploadModal(false, null));
  };

  const [submit, submitting] = useUploadDocument(() => {
    setDocFile(null);
    onClose();
  });

  const onFileChange = e => {
    const targetFile = e.target.files[0];

    if (targetFile && targetFile.size > 1000000000) {
      setFileError('Please choose a smaller file (under 1GB)');
    } else if (uploads.find(uploadObj => uploadObj.filename === targetFile.name)) {
      // Prevent submission if a file of the same name already exists
      setFileError('A file with this name already exists for this application');
    } else if (targetFile) {
      // Remove errors and add targetFile to state
      setFileError(null);
      setDocFile(targetFile);
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader>Upload Document</ModalHeader>
      <ModalContent>
        <p>Please select the file you'd like to upload.</p>
        <CustomFileInput>
          <input type="file" onChange={onFileChange} />
        </CustomFileInput>
        {fileError && <div style={{ color: '#AD2424', marginBottom: '5px' }}>{fileError}</div>}
      </ModalContent>
      <ModalFooter>
        <Button
          disabled={fileError != null}
          style={{ minWidth: '100px' }}
          onClick={() => submit(docFile, application._id)}
          isLoading={submitting}
        >
          Upload Document
        </Button>
      </ModalFooter>
    </Modal>
  );
};
