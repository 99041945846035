import React from 'react';
import { SimpleListTable } from '../general/SimpleListTable';
import { ApplicationPageSectionWrapper } from './ApplicationPage';
import { useCurrentApplication } from '../../hooks/useCurrentApplication';
import { UserDisplay } from '../UserDisplay';
import { HeaderWithEdit } from '../general/HeaderWithEdit';
import { useDispatch } from 'react-redux';
import { setEditAssigneesModal } from '../../actions/modals';

// Show a UserDisplay component (user circle with name) if the assignee obj exists;
// if not, show 'Unassigned'
const getAssigneeDisplay = assigneeObj => {
  if (!assigneeObj) {
    return 'Unassigned';
  }
  return <UserDisplay userObj={assigneeObj} displayAsText={true} />;
};

// Display names of assigned paralegal and attorney;
export const AssigneeSection = () => {
  const dispatch = useDispatch();
  const application = useCurrentApplication();
  const { assigneeAttorney, assigneeParalegal } = application;

  return (
    <ApplicationPageSectionWrapper>
      <HeaderWithEdit onEditClick={() => dispatch(setEditAssigneesModal(true))}>
        Assignees
      </HeaderWithEdit>
      <SimpleListTable
        headers={['Attorney', 'Paralegal']}
        values={[getAssigneeDisplay(assigneeAttorney), getAssigneeDisplay(assigneeParalegal)]}
      />
    </ApplicationPageSectionWrapper>
  );
};
